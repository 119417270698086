let initialState = {
    loading: true,
    data: {},
    type: 'GET_STATUSES',
    error: {},
    statuses: []
  };
  
  let initialCreateState = {
    loading: true,
    data: {},
    type: 'CREATE_STATUSES',
    error: {},
  };
  
  let initialUpdateState = {
    loading: true,
    data: {},
    type: 'UPDATE_STATUSES',
    error: {},
  };
  
  let initialDeleteState = {
    loading: true,
    data: {},
    type: 'DELETE_STATUSES',
    error: {},
  };
  
  let viewStatusReducerState = {
    type: 'VIEW_STATUS',
    status: null
  };
  
  let saveStatusReducerState = {
    type: 'SAVE_STATUS',
    status: null
  };
  
  export const saveStatusReducer = (state = saveStatusReducerState, action) => {
    switch (action.type) {
      case 'SAVE_STATUS':
        return {
          type: action.type,
          status: action.status
        }
      default:
        return state;
    }
  }
  
  export const viewStatusReducer = (state = viewStatusReducerState, action) => {
    switch (action.type) {
      case 'VIEW_STATUS':
        let status = action.status;
        return {
          type: action.type,
          status,
        }
      default:
        return state;
    }
  }
  
  export const updateStatusReducer = (state = initialUpdateState, action) => {
    switch (action.type) {
      case 'UPDATE_STATUSES':
        return {
          ...state,
          type: action.type,
          loading: true
        }
      case 'UPDATE_STATUSES_SUCCESS':
        return {
          ...state,
          type: action.type,
          loading: false,
          data: action.data
        }
      case 'UPDATE_STATUSES_ERROR':
        return {
          ...state,
          type: action.type,
          loading: false,
          error: action.error,
          data: action.data,
        }
      default:
        return state;
    }
  }
  
  export const deleteStatusReducer = (state = initialDeleteState, action) => {
    switch (action.type) {
      case 'DELETE_STATUSES':
        return {
          ...state,
          type: action.type,
          loading: true
        }
      case 'DELETE_STATUSES_SUCCESS':
        return {
          ...state,
          type: action.type,
          loading: false,
          data: action.data
        }
      case 'DELETE_STATUSES_ERROR':
        return {
          ...state,
          type: action.type,
          loading: false,
          error: action.error,
          data: action.data,
        }
      default:
        return state;
    }
  }
  
  export const createStatusReducer = (state = initialCreateState, action) => {
    switch (action.type) {
      case 'CREATE_STATUSES':
        return {
          ...state,
          type: action.type,
          loading: true
        }
      case 'CREATE_STATUSES_SUCCESS':
        return {
          ...state,
          type: action.type,
          loading: false,
          data: action.data
        }
      case 'CREATE_STATUSES_ERROR':
        return {
          ...state,
          type: action.type,
          loading: false,
          error: action.error,
          data: action.data,
        }
      default:
        return state;
    }
  }
  
  export const statusReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_STATUSES':
        return {
          type: action.type,
          loading: true,
        };
      case 'GET_STATUSES_SUCCESS':
        return {
          type: action.type,
          loading: false, 
          data: action.data,
          statuses: action.statuses
        };
      case 'GET_STATUSES_ERROR':
        return {
          type: action.type,
          loading: false, 
          error: action.error
        };
      default:
        return state;
    }
  };