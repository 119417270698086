import { create } from "apisauce";

export default class Api {
  constructor() {
    this.baseURL =
      process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:5001"
        : "https://api.suc.co.id";

    this.api = create({
      baseURL: this.baseURL,
    });

    let token = localStorage.getItem("token");
    this.apiAuthorized = create({
      baseURL: this.baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    this.apiAuthorizedUploadFile = create({
      baseURL: this.baseURL,
      responseType: "json",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    this.apiNewUploadFile = create({
      baseURL: "https://storage.suc.co.id/api/v1",
      responseType: "json",
      headers: {
        "Content-Type": "multipart/form-data",
        "storage-api-key": "9dc55d19bd9655d58cab2af57b4d392b",
      },
    });
  }

  doLogin = (username, password) =>
    new Promise((resolve, reject) => {
      this.api
        .post("/oauth/access_token/login", {
          username: username,
          password: password,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getMe = () =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .get("/oauth/me")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getProvinces = (provinceId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .get("/provinces", {
          provinceId: provinceId,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createProvinces = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/provinces", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteProvinces = (provinceId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/provinces/${provinceId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateProvinces = (forms, provinceId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/provinces/${provinceId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getCities = (cityId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .get("/cities", {
          cityId: cityId,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createCities = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/cities", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteCities = (cityId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/cities/${cityId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateCities = (forms, cityId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/cities/${cityId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getCitiesAll = () =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .get("/cities-all")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createClients = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/clients", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteClients = (clientId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/clients/${clientId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateClients = (forms, clientId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/clients/${clientId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getClients = (page, perPage) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;
      this.apiAuthorized
        .get("/clients", {
          page: page,
          perPage: perPage,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  checkRates = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/rates/checkRate", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createRates = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/rates", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteRates = (rateId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/rates/${rateId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateRates = (forms, rateId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/rates/${rateId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getRates = (page, perPage, filters) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;

      let where = {
        page: page,
        perPage: perPage,
      };

      // filtering
      filters.hasOwnProperty("originCityId") &&
        Object.assign(where, { originCityId: filters.originCityId });
      filters.hasOwnProperty("destinationCityId") &&
        Object.assign(where, { destinationCityId: filters.destinationCityId });

      this.apiAuthorized
        .get("/rates", where)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createUsers = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/users", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteUsers = (userId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/users/${userId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateUsers = (forms, userId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/users/${userId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getUsers = (page, perPage) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;
      this.apiAuthorized
        .get("/users", {
          page: page,
          perPage: perPage,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createRoles = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/roles", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteRoles = (roleId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/roles/${roleId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateRoles = (forms, roleId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/roles/${roleId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getRoles = (page, perPage) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;
      this.apiAuthorized
        .get("/roles", {
          page: page,
          perPage: perPage,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createTransactions = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/transactions", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteTransactions = (transactionId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/transactions/${transactionId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateTransactions = (forms, transactionId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/transactions/${transactionId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getTransactions = (page, perPage, filters) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;

      let where = {
        page: page,
        perPage: perPage,
      };

      // filtering
      if (filters != null) {
        filters.hasOwnProperty("airWayBillNumber") &&
          Object.assign(where, { airWayBillNumber: filters.airWayBillNumber });
        filters.hasOwnProperty("clientId") &&
          Object.assign(where, { clientId: filters.clientId.value });
        filters.hasOwnProperty("originProvinceId") &&
          Object.assign(where, { originProvinceId: filters.originProvinceId });
        filters.hasOwnProperty("originCityId") &&
          Object.assign(where, { originCityId: filters.originCityId });
        filters.hasOwnProperty("originAddress") &&
          Object.assign(where, { originAddress: filters.originAddress });
        filters.hasOwnProperty("destinationProvinceId") &&
          Object.assign(where, {
            destinationProvinceId: filters.destinationProvinceId,
          });
        filters.hasOwnProperty("destinationCityId") &&
          Object.assign(where, {
            destinationCityId: filters.destinationCityId,
          });
        filters.hasOwnProperty("destinationAddress") &&
          Object.assign(where, {
            destinationAddress: filters.destinationAddress,
          });
        filters.hasOwnProperty("consignee") &&
          Object.assign(where, { consignee: filters.consignee });
        filters.hasOwnProperty("consigneePic") &&
          Object.assign(where, { consigneePic: filters.consigneePic });
        filters.hasOwnProperty("consigneeEmail") &&
          Object.assign(where, { consigneeEmail: filters.consigneeEmail });
        filters.hasOwnProperty("consigneePhoneNumber") &&
          Object.assign(where, {
            consigneePhoneNumber: filters.consigneePhoneNumber,
          });
        filters.hasOwnProperty("rateId") &&
          Object.assign(where, { rateId: filters.rateId });
        filters.hasOwnProperty("weight") &&
          Object.assign(where, { weight: filters.weight });
        filters.hasOwnProperty("totalPrice") &&
          Object.assign(where, { totalPrice: filters.totalPrice });
        filters.hasOwnProperty("packageDescription") &&
          Object.assign(where, {
            packageDescription: filters.packageDescription,
          });
        filters.hasOwnProperty("originPhoneNumber") &&
          Object.assign(where, {
            originPhoneNumber: filters.originPhoneNumber,
          });
        filters.hasOwnProperty("originEmail") &&
          Object.assign(where, { originEmail: filters.originEmail });
        filters.hasOwnProperty("originPic") &&
          Object.assign(where, { originPic: filters.originPic });
        filters.hasOwnProperty("startDate") &&
          Object.assign(where, { startDate: filters.startDate });
        filters.hasOwnProperty("endDate") &&
          Object.assign(where, { endDate: filters.endDate });
        filters.hasOwnProperty("createdAt") &&
          Object.assign(where, { createdAt: filters.createdAt });
      }

      console.log("asdasd_where", where);

      this.apiAuthorized
        .get("/transactions", where)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getTransactionsReport = (page, perPage, filters) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;

      // filtering
      let airWayBillNumber = filters.airWayBillNumber || "";
      let clientId = filters.clientId || "";
      let originProvinceId = filters.originProvinceId || "";
      let originCityId = filters.originCityId || "";
      let originAddress = filters.originAddress || "";
      let destinationProvinceId = filters.destinationProvinceId || "";
      let destinationCityId = filters.destinationCityId || "";
      let destinationAddress = filters.destinationAddress || "";
      let consignee = filters.consignee || "";
      let consigneePic = filters.consigneePic || "";
      let consigneeEmail = filters.consigneeEmail || "";
      let consigneePhoneNumber = filters.consigneePhoneNumber || "";
      let rateId = filters.rateId || "";
      let weight = filters.weight || "";
      let totalPrice = filters.totalPrice || "";
      let packageDescription = filters.packageDescription || "";
      let originPhoneNumber = filters.originPhoneNumber || "";
      let originEmail = filters.originEmail || "";
      let originPic = filters.originPic || "";
      let startDate = filters.startDate || "";
      let endDate = filters.endDate || "";

      this.apiAuthorized
        .get("/transactions-report", {
          page: page,
          perPage: perPage,
          airWayBillNumber: airWayBillNumber,
          clientId: clientId,
          originProvinceId: originProvinceId,
          originCityId: originCityId,
          originAddress: originAddress,
          destinationProvinceId: destinationProvinceId,
          destinationCityId: destinationCityId,
          destinationAddress: destinationAddress,
          consignee: consignee,
          consigneePic: consigneePic,
          consigneeEmail: consigneeEmail,
          consigneePhoneNumber: consigneePhoneNumber,
          rateId: rateId,
          weight: weight,
          totalPrice: totalPrice,
          packageDescription: packageDescription,
          originPhoneNumber: originPhoneNumber,
          originPic: originPic,
          originEmail: originEmail,
          startDate: startDate,
          endDate: endDate,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createTransactionDetails = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/transactionDetails", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteTransactionDetails = (transactionDetailId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/transactionDetails/${transactionDetailId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateTransactionDetails = (forms, transactionDetailId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/transactionDetails/${transactionDetailId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getTransactionDetails = (page, perPage, filters) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;
      let transactionId = filters.transactionId || "";

      this.apiAuthorized
        .get("/transactionDetails", {
          page: page,
          perPage: perPage,
          transactionId: transactionId,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createStatuses = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/statuses", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteStatuses = (statusId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/statuses/${statusId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateStatuses = (forms, statusId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/statuses/${statusId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getStatuses = (page, perPage) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;
      this.apiAuthorized
        .get("/statuses", {
          page: page,
          perPage: perPage,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getDashboard = () =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .get("/dashboard")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  createConsignees = (forms) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .post("/consignees", forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  deleteConsignees = (consigneeId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .delete(`/consignees/${consigneeId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  updateConsignees = (forms, consigneeId) =>
    new Promise((resolve, reject) => {
      this.apiAuthorized
        .patch(`/consignees/${consigneeId}`, forms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  getConsignees = (page, perPage, filters) =>
    new Promise((resolve, reject) => {
      page = page || 1;
      perPage = perPage || 15;

      let where = {
        page: page,
        perPage: perPage,
      };

      if (filters !== null) {
        filters.hasOwnProperty("companyName") &&
          Object.assign(where, { companyName: filters.companyName });
      }

      this.apiAuthorized
        .get("/consignees", where)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  uploadFiles = (files) =>
    new Promise((resolve, reject) => {
      console.log("baseUrl", this.baseURL);

      var formData = new FormData();
      formData.append("files", files[0]);

      this.apiAuthorizedUploadFile
        .post("/files", formData)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

  uploadFilesV2 = (files) =>
    new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("file", files[0]);

      this.apiNewUploadFile
        .post("/storage/single", formData)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
}
