import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { createProvinces } from "../../redux/actions/province_action";
import ContentHeader from '../../components/content_header';

function CreateProvince () {

  const createProvinceState = useSelector(state => state.createProvinceReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [provinceName, setProvinceName] = useState("");

  useEffect(() => {
    try {
      if (createProvinceState.type === 'CREATE_PROVINCES_SUCCESS') {
        history.goBack();
      }
    } catch (e) {
      console.log(e);
    }
  }, [createProvinceState]);

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      provinceName: provinceName
    };

    console.log(forms);

    dispatch(createProvinces(forms));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Create Provinsi'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Nama Provinsi</label>
                      <input 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        placeholder="Enter Company Name" 
                        onChange={(e) => setProvinceName(e.target.value)}
                        />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(CreateProvince);