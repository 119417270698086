let initialState = {
  loading: true,
  data: {},
  type: 'GET_USERS',
  error: {},
  users: []
};

let initialCreateState = {
  loading: true,
  data: {},
  type: 'CREATE_USERS',
  error: {},
};

let initialUpdateState = {
  loading: true,
  data: {},
  type: 'UPDATE_USERS',
  error: {},
};

let initialDeleteState = {
  loading: true,
  data: {},
  type: 'DELETE_USERS',
  error: {},
};

let viewUserReducerState = {
  type: 'VIEW_USER',
  user: null
};

let saveUserReducerState = {
  type: 'SAVE_USER',
  user: null
};

export const saveUserReducer = (state = saveUserReducerState, action) => {
  switch (action.type) {
    case 'SAVE_USER':
      return {
        type: action.type,
        user: action.user
      }
    default:
      return state;
  }
}

export const viewUserReducer = (state = viewUserReducerState, action) => {
  switch (action.type) {
    case 'VIEW_USER':
      let user = action.user;
      return {
        type: action.type,
        user,
      }
    default:
      return state;
  }
}

export const updateUserReducer = (state = initialUpdateState, action) => {
  switch (action.type) {
    case 'UPDATE_USERS':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'UPDATE_USERS_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'UPDATE_USERS_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const deleteUserReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case 'DELETE_USERS':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'DELETE_USERS_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'DELETE_USERS_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const createUserReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case 'CREATE_USERS':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'CREATE_USERS_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'CREATE_USERS_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USERS':
      return {
        type: action.type,
        loading: true,
      };
    case 'GET_USERS_SUCCESS':
      return {
        type: action.type,
        loading: false, 
        data: action.data,
        users: action.users
      };
    case 'GET_USERS_ERROR':
      return {
        type: action.type,
        loading: false, 
        error: action.error
      };
    default:
      return state;
  }
};