let initialState = {
  loading: true,
  data: [],
  type: 'GET_PROVINCES',
  error: {},
};

let initialCreateState = {
  loading: true,
  data: {},
  type: 'CREATE_PROVINCES',
  error: {},
};

let initialUpdateState = {
  loading: true,
  data: {},
  type: 'UPDATE_PROVINCES',
  error: {},
};

let initialDeleteState = {
  loading: true,
  data: {},
  type: 'DELETE_PROVINCES',
  error: {},
};

let viewProvinceReducerState = {
  type: 'VIEW_PROVINCE',
  province: null
};

let saveProvinceReducerState = {
  type: 'SAVE_PROVINCE',
  province: null
};

export const saveProvinceReducer = (state = saveProvinceReducerState, action) => {
  switch (action.type) {
    case 'SAVE_PROVINCE':
      return {
        type: action.type,
        province: action.province
      }
    default:
      return state;
  }
}

export const viewProvinceReducer = (state = viewProvinceReducerState, action) => {
  switch (action.type) {
    case 'VIEW_PROVINCE':
      let province = action.province;
      return {
        type: action.type,
        province,
      }
    default:
      return state;
  }
}

export const updateProvinceReducer = (state = initialUpdateState, action) => {
  switch (action.type) {
    case 'UPDATE_PROVINCES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'UPDATE_PROVINCES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'UPDATE_PROVINCES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const deleteProvinceReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case 'DELETE_PROVINCES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'DELETE_PROVINCES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'DELETE_PROVINCES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const createProvinceReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case 'CREATE_PROVINCES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'CREATE_PROVINCES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'CREATE_PROVINCES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const provinceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROVINCES':
      return {
        ...state,
        loading: false,
        type: action.type
      };
    case 'GET_PROVINCES_SUCCESS':
      return {
        loading: false, 
        data: action.data,
        provinces: action.provinces,
        type: action.type
      };
    case 'GET_PROVINCES_ERROR':
      return {
        loading: false, 
        error: action.error,
        type: action.type
      };
    default:
      return state;
  }
};