import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import ContentHeader from "../../components/content_header";
import { thousandSeparator } from "../../helper/thousand_separator";
import {
  viewTransactions,
  initializeCreateTransactions,
  initializeUpdateTransactions,
} from "../../redux/actions/transaction_action";
import ReactToPrint, {
  PrintContextConsumer,
  useReactToPrint,
} from "react-to-print";
import { initializeCreateTransactionDetails } from "../../redux/actions/transaction_detail_action";
import "../../style/receipt.css";
import "../../style/print.css";

export default function PrintReceiptTransaction() {
  const viewTransactionState = useSelector(
    (state) => state.viewTransactionReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    pageStyle: "@page { size: 21cm 29.7cm;}",
  });

  // Form
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedOriginProvince, setSelectedOriginProvince] = useState("");
  const [selectedOriginCity, setSelectedOriginCity] = useState("");
  const [selectedDestinationProvince, setSelectedDestinationProvince] =
    useState("");
  const [selectedDestinationCity, setSelectedDestinationCity] = useState("");

  const [destinationPostalCode, setDestinationPostalCode] = useState("");
  const [originPostalCode, setOriginPostalCode] = useState("");
  const [airWayBill, setAirWayBill] = useState("");
  const [originAddress, setOriginAddress] = useState("");
  const [originPic, setOriginPic] = useState("");
  const [originEmail, setOriginEmail] = useState("");
  const [originPhoneNumber, setOriginPhoneNumber] = useState("");
  const [consignee, setConsignee] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [destinationPic, setDestinationPic] = useState("");
  const [destinationEmail, setDestinationEmail] = useState("");
  const [destinationPhoneNumber, setDestinationPhoneNumber] = useState("");
  const [weight, setWeight] = useState(1);
  const [rate, setRate] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [packageDescription, setPackageDescription] = useState("");
  const [transactionDetail, setTransactionDetail] = useState([]);
  const [language, setLanguage] = useState("id_Id");
  const [checked, setChecked] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const [createdAtTime, setCreatedAtTime] = useState("");
  const [copies, setCopies] = useState([1, 2, 3]);
  const [services, setServices] = useState("");

  // package description
  const [isVolumetrik, setIsVolumetrik] = useState(false);
  const [isPackingPrice, setIsPackingPrice] = useState(false);
  const [isInsurance, setIsInsurance] = useState(false);
  const [volumetrik, setVolumetrik] = useState(0);
  const [length, setLength] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [packingPrice, setPackingPrice] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [insurancePrice, setInsurancePrice] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [colly, setColly] = useState(0);
  const [pcs, setPcs] = useState(0);
  const [layanan, setLayanan] = useState("");
  const [officer, setOfficer] = useState("");
  const [receiverOfficer, setReceiverOfficer] = useState("");

  useEffect(() => {
    dispatch(viewTransactions());
  }, [viewTransactions]);

  useEffect(() => {
    dispatch(initializeCreateTransactions());
    dispatch(initializeUpdateTransactions());
    dispatch(initializeCreateTransactionDetails());
  }, []);

  useEffect(() => {
    try {
      setSelectedClient(viewTransactionState.transaction.client.companyName);
      setSelectedOriginProvince(
        viewTransactionState.transaction.originProvince.provinceName
      );
      setSelectedOriginCity(
        viewTransactionState.transaction.originCity.cityName
      );
      setSelectedDestinationProvince(
        viewTransactionState.transaction.destinationProvince.provinceName
      );
      setSelectedDestinationCity(
        viewTransactionState.transaction.destinationCity.cityName
      );
      setAirWayBill(viewTransactionState.transaction.airWayBillNumber);
      setOriginAddress(viewTransactionState.transaction.originAddress);
      setOriginPic(viewTransactionState.transaction.originPic);
      setOriginEmail(viewTransactionState.transaction.originEmail);
      setOriginPhoneNumber(viewTransactionState.transaction.originPhoneNumber);
      setConsignee(viewTransactionState.transaction.consignee);
      setDestinationAddress(
        viewTransactionState.transaction.destinationAddress
      );
      setDestinationPic(viewTransactionState.transaction.consigneePic);
      setDestinationEmail(viewTransactionState.transaction.consigneeEmail);
      setDestinationPhoneNumber(
        viewTransactionState.transaction.consigneePhoneNumber
      );
      setWeight(viewTransactionState.transaction.weight);
      setRate(viewTransactionState.transaction.rate.rates);
      setTotalPrice(viewTransactionState.transaction.totalPrice);
      setPackageDescription(
        viewTransactionState.transaction.packageDescription
      );
      setTransactionDetail(viewTransactionState.transaction.transactionDetails);
      setDestinationPostalCode(
        viewTransactionState.transaction.destinationCity.postalCode
      );
      setOriginPostalCode(
        viewTransactionState.transaction.originCity.postalCode
      );
      setServices(viewTransactionState.transaction.rate.services);

      let cAt = new Date(parseInt(viewTransactionState.transaction.createdAt));
      let month = cAt.toLocaleString("default", { month: "long" });
      let time = cAt.toLocaleTimeString("default", { hour12: false });

      setCreatedAt(`${cAt.getDate()} ${month} ${cAt.getFullYear()}`);
      setCreatedAtTime(time);

      let attributes = JSON.parse(viewTransactionState.transaction.attributes);
      setVolumetrik(attributes.volumetrik);
      setPackingPrice(attributes.packingPrice);
      setInsurancePrice(attributes.insurancePrice);
      setPaymentMethod(attributes.paymentMethod);
      setColly(attributes.colly);
      setPcs(attributes.pcs);
      setLayanan(attributes.layanan);
      setOfficer(attributes.officer);
      setReceiverOfficer(attributes.receiverOfficer);

      // shipping price
      // set total price
      let volumeOrWeight;
      if (attributes.volumetrik < viewTransactionState.transaction.weight) {
        volumeOrWeight = viewTransactionState.transaction.weight;
      } else if (
        attributes.volumetrik > viewTransactionState.transaction.weight
      ) {
        volumeOrWeight = attributes.volumetrik;
      } else {
        volumeOrWeight = viewTransactionState.transaction.weight;
      }
      let tp = volumeOrWeight * viewTransactionState.transaction.rate.rates;
      setShippingPrice(tp);
    } catch (e) {}
  }, [viewTransactionState]);

  return (
    <div className="content-wrapper">
      <ContentHeader title={"Receipt"} />
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handlePrint}
              >
                Print Receipt
              </button>
              {/* <div style={{ margin: 10}}></div> */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="col-md-12"
              ref={printArea}
            >
              {/* <div style={{margin: 5}}></div> */}
              <page size="A4" layout="portrait">
                {copies.map((item, index) => {
                  // let asd = 'PT. Bringin Giganatara Semarang - SEMARANG';
                  let asd = `${consignee} - ${selectedDestinationCity}`;
                  let totalDes = asd.length;
                  let endString = totalDes >= 40 ? 40 : totalDes;
                  let endString2 = totalDes >= 40 ? asd.length : 0;

                  return (
                    <table
                      style={{
                        fontSize: 12,
                        marginLeft: 20,
                        marginRight: 20,
                        height: 374,
                      }}
                    >
                      <tr style={{ height: 25 }}>
                        <td colSpan={17}></td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: 32,
                            fontWeight: "bold",
                          }}
                          className="brand-name"
                          colSpan={3}
                          rowSpan={2}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="/img/logo_suc.png"
                              style={{
                                height: 50,
                                width: 50,
                              }}
                            />
                            SUC
                          </div>
                        </td>
                        <td className="brand-name" rowSpan={2}></td>
                        <td
                          className="brand-name-2"
                          colSpan={2}
                          rowSpan={1}
                          style={{
                            fontSize: 16,
                          }}
                        >
                          <b>PT. SINAR USAHA CEMERLANG</b>
                        </td>
                        <td className="brand-name" rowSpan={2}></td>
                        <td
                          className="attributes-6 center-text"
                          colSpan={8}
                          rowSpan={1}
                        >
                          <b>AIRWAYBILL NO:</b>
                        </td>
                        <td className="brand-name" rowSpan={2}></td>
                        <td className="brand-name" rowSpan={2}>
                          DEST
                        </td>
                      </tr>
                      <tr>
                        {/* <td className="attributes-1"></td> */}
                        <td
                          className="brand-name-3"
                          colSpan={2}
                          rowSpan={1}
                          style={{
                            fontSize: 16,
                          }}
                        >
                          <b>LOGISTICS</b>
                        </td>
                        <td
                          className="attributes-5 center-text"
                          colSpan={8}
                          rowSpan={1}
                        >
                          <b>{airWayBill}</b>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="attributes-1 attributes-2-width"
                        >
                          SHIPPER:
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td
                          colSpan={2}
                          className="attributes-1 attributes-4-width"
                        >
                          CONSIGNEE:
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td
                          colSpan={2}
                          className="brand-name center-text attributes-1-width "
                        >
                          Colly
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td
                          colSpan={2}
                          className="brand-name center-text attributes-1-width "
                        >
                          Kg
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td
                          colSpan={2}
                          className="brand-name center-text attributes-1-width "
                        >
                          Pcs
                        </td>
                        <td className="attributes-1 center-text"></td>
                        <td className="brand-name center-text attributes-3-width">
                          Volumetrik
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="attributes-1 attributes-2-width"
                        >
                          {selectedClient}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td
                          colSpan={2}
                          style={{ height: 36 }}
                          className="attributes-1"
                        >
                          {asd}
                          {/* ghazi fadil ramadhan ghazi fadil ghazi fadil ghazi fadil ghaz fadil ramadhan ghazi fadil ramadhan {consignee} - {selectedDestinationCity} */}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={2} className="attributes-1 center-text">
                          {colly}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={2} className="attributes-1 center-text">
                          {weight}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={2} className="attributes-1 center-text">
                          {pcs}
                        </td>
                        <td className="attributes-1"></td>
                        <td className="attributes-1 center-text">
                          {volumetrik}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="attributes-1">
                          LOGISTIK
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td
                          colSpan={2}
                          rowSpan={2}
                          className="attributes-1"
                          style={{
                            width: 281,
                          }}
                        >
                          {destinationAddress}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td
                          colSpan={8}
                          className="attributes-1 center-text brand-name"
                        >
                          Sistem Services
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td
                          colSpan={1}
                          className="attributes-1 center-text brand-name"
                        >
                          Layanan
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="attributes-1">
                          {selectedOriginCity}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={8} className="brand-name">
                          {services}
                        </td>
                        <td colSpan={1}></td>
                        <td colSpan={1} className="brand-name">
                          {layanan}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1}>{selectedDestinationCity}</td>
                        <td colSpan={1}>{destinationPhoneNumber}</td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={8} className="brand-name"></td>
                        <td colSpan={1} className="brand-name"></td>
                        <td colSpan={1} className="brand-name"></td>
                      </tr>
                      <tr>
                        <td colSpan={17} className="brand-name"></td>
                      </tr>
                      <tr>
                        <td colSpan={17} className="brand-name"></td>
                      </tr>
                      <tr>
                        <td colSpan={17} className="brand-name">
                          PT. SINAR USAHA CEMERLANG Kantor Pusat: Jl. Kwini No.
                          1, SENEN Jakarta Pusat Telp: (021) 3484 1154 / 3484
                          1155
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={17} className="brand-name"></td>
                      </tr>
                      <tr>
                        <td colSpan={17} className="brand-name"></td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="attributes-1"
                          style={{
                            width: 265,
                            borderBottom: "1px solid black",
                          }}
                        >
                          DESCRIPTION:
                        </td>
                        <td></td>
                        <td
                          colSpan={1}
                          className="brand-name center-text"
                          style={{
                            width: 150,
                          }}
                        >
                          PETUGAS SUC
                        </td>
                        <td
                          colSpan={1}
                          className="brand-name center-text"
                          style={{
                            width: 150,
                          }}
                        >
                          PENERIMA
                        </td>
                        <td></td>
                        <td
                          colSpan={5}
                          rowSpan={2}
                          className="attributes-1 center-text"
                        >
                          Cara Pembayaran
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={2} className="brand-name">
                          {paymentMethod == "TUNAI" && "V"}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1">
                          Tunai
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          rowSpan={4}
                          className="attributes-1"
                          style={{
                            verticalAlign: "text-top",
                            fontSize: 14,
                            width: 231,
                          }}
                        >
                          <b>{packageDescription}</b>
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={2} className="brand-name">
                          {paymentMethod === "KREDIT" && "V"}
                        </td>
                        <td className="attributes-1"></td>
                        <td className="attributes-1">Kredit</td>
                      </tr>
                      <tr>
                        {/* <td colSpan={3} className="attributes-1"></td> */}
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={10} className="brand-name">
                          RINCIAN BIAYA
                        </td>
                      </tr>
                      <tr>
                        {/* <td colSpan={3} className="attributes-1"></td> */}
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={5} className="brand-name">
                          Tarif/KG
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1}>Rp</td>
                        <td colSpan={3} className="attributes-4 right-text">
                          {thousandSeparator(rate)}
                        </td>
                      </tr>
                      <tr>
                        {/* <td colSpan={3} className="attributes-1"></td> */}
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={5} className="brand-name">
                          Biaya Kirim
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1}>Rp</td>
                        <td colSpan={3} className="attributes-4 right-text">
                          {thousandSeparator(shippingPrice)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1 center-text">
                          {officer}
                        </td>
                        <td colSpan={1} className="attributes-1 center-text">
                          {receiverOfficer}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={5} className="brand-name">
                          Asuransi
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1}>Rp</td>
                        <td colSpan={3} className="attributes-4 right-text">
                          {insurancePrice == 0
                            ? "-"
                            : thousandSeparator(insurancePrice)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="attributes-1"></td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1} className="brand-name-4 date">
                          Tgl: {createdAt}
                        </td>
                        <td colSpan={1} className="brand-name-4 date">
                          Tgl:{" "}
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={5} className="brand-name">
                          Packing
                        </td>
                        <td colSpan={1} className="attributes-1"></td>
                        <td colSpan={1}>Rp</td>
                        <td colSpan={3} className="attributes-4 right-text">
                          {packingPrice == 0
                            ? "-"
                            : thousandSeparator(packingPrice)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          className="attributes-1 attributes-5"
                        ></td>
                        <td
                          colSpan={1}
                          className="attributes-1 attributes-5"
                        ></td>
                        <td
                          colSpan={1}
                          className="attributes-1 attributes-5 date"
                        >
                          Jam: {createdAtTime}
                        </td>
                        <td
                          colSpan={1}
                          className="attributes-1 attributes-5 date"
                        >
                          Jam:
                        </td>
                        <td
                          colSpan={1}
                          className="attributes-1 attributes-5"
                        ></td>
                        <td colSpan={5} className="brand-name attributes-5">
                          Total Biaya
                        </td>
                        <td
                          colSpan={1}
                          className="attributes-1 attributes-5"
                        ></td>
                        <td colSpan={1} className="attributes-5 attributes-6">
                          <b>Rp</b>
                        </td>
                        <td
                          colSpan={3}
                          className="attributes-4 attributes-6 attributes-5 right-text"
                        >
                          <b>{thousandSeparator(totalPrice)}</b>
                        </td>
                      </tr>
                      <tr style={{ height: 40 }}>
                        <td
                          colSpan={17}
                          style={{
                            textAlign: "center",
                            color: "red",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <i>
                            Note: Bila sudah di tandatangani & di stempel, mohon
                            kiranya segera di Fax ke : 021-3840056 (Up: Wawan)
                          </i>
                        </td>
                      </tr>
                    </table>
                  );
                })}
                {/* <div style={{ margin: 10}}></div> */}
              </page>
            </div>

            <div style={{ margin: 20 }}></div>

            {/* <div className="col-md-12">
                            <div className="invoice p-3 mb-3">
                                <div className="row">
                                    <div className="col-12">
                                    <h4>
                                        <i className="fas fa-globe" /> PT. Sinar Usaha Cemerlang
                                        <small className="float-right">Date: {createdAt}</small>
                                    </h4>
                                    </div>
                                </div>
                                <div className="row invoice-info">
                                    <div className="col-sm-4 invoice-col">
                                    From
                                    <address>
                                        <strong>{originPic}</strong><br />
                                        <strong>{selectedClient}</strong><br />
                                        {originAddress}<br />
                                        {selectedOriginProvince}<br />
                                        {selectedOriginCity} - {originPostalCode}<br />
                                        Phone: {originPhoneNumber}<br />
                                        Email: {originEmail}
                                    </address>
                                    </div>
                                    <div className="col-sm-4 invoice-col">
                                    To
                                    <address>
                                        <strong>{destinationPic}</strong><br />
                                        <strong>{consignee}</strong><br />
                                        {destinationAddress}<br />
                                        {selectedDestinationProvince}<br />
                                        {selectedDestinationCity} - {destinationPostalCode}<br />
                                        Phone: {destinationPhoneNumber}<br />
                                        Email: {destinationEmail}
                                    </address>
                                    </div>
                                    <div className="col-sm-4 invoice-col">
                                    <b>Airway Bill</b><br />
                                    <b>{airWayBill}</b>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Product Description</th>
                                            <th>Weight (KG)</th>
                                            <th>Volumetrik (KG)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>{packageDescription}</td>
                                            <td>{thousandSeparator(weight)}</td>
                                            <td>{thousandSeparator(volumetrik)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                    </div>
                                    <div className="col-6">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th style={{width: '50%'}}>
                                                        Price/KG:
                                                    </th>
                                                    <td>Rp {thousandSeparator(rate)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{width: '50%'}}>
                                                        Packing Price:
                                                    </th>
                                                    <td>Rp {thousandSeparator(packingPrice)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{width: '50%'}}>
                                                        Insurance Price:
                                                    </th>
                                                    <td>Rp {thousandSeparator(insurancePrice)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total:</th>
                                                    <td>Rp {thousandSeparator(totalPrice)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
