import { consigneeTypes } from '../actions/consignee_action'

let initialState = {
  loading: true,
  data: {},
  type: consigneeTypes.GET_CONSIGNEES,
  error: {},
  consignees: [],
  consigneeTransform: []
};

let initialCreateState = {
  loading: true,
  data: {},
  type: consigneeTypes.CREATE_CONSIGNEES,
  error: {},
};

let initialUpdateState = {
  loading: true,
  data: {},
  type: consigneeTypes.UPDATE_CONSIGNEES,
  error: {},
};

let initialDeleteState = {
  loading: true,
  data: {},
  type: consigneeTypes.DELETE_CONSIGNEES,
  error: {},
};

let viewConsigneeReducerState = {
  type: consigneeTypes.VIEW_CONSIGNEE,
  consignee: null
};

let saveConsigneeReducerState = {
  type: consigneeTypes.SAVE_CONSIGNEE,
  consignee: null
};

export const saveConsigneeReducer = (state = saveConsigneeReducerState, action) => {
  switch (action.type) {
    case consigneeTypes.SAVE_CONSIGNEE:
      return {
        type: action.type,
        consignee: action.consignee
      }
    default:
      return state;
  }
}

export const viewConsigneeReducer = (state = viewConsigneeReducerState, action) => {
  switch (action.type) {
    case consigneeTypes.VIEW_CONSIGNEE:
      let consignee = action.consignee;
      return {
        type: action.type,
        consignee,
      }
    default:
      return state;
  }
}

export const updateConsigneeReducer = (state = initialUpdateState, action) => {
  switch (action.type) {
    case consigneeTypes.UPDATE_CONSIGNEES:
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case consigneeTypes.UPDATE_CONSIGNEES_SUCCESS:
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case consigneeTypes.UPDATE_CONSIGNEES_ERROR:
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const deleteConsigneeReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case consigneeTypes.DELETE_CONSIGNEES:
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case consigneeTypes.DELETE_CONSIGNEES_SUCCESS:
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case consigneeTypes.DELETE_CONSIGNEES_ERROR:
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const createConsigneeReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case consigneeTypes.CREATE_CONSIGNEES:
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case consigneeTypes.CREATE_CONSIGNEES_SUCCESS:
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case consigneeTypes.CREATE_CONSIGNEES_ERROR:
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const consigneeReducer = (state = initialState, action) => {
  switch (action.type) {
    case consigneeTypes.GET_CONSIGNEES:
      return {
        type: action.type,
        loading: true,
      };
    case consigneeTypes.GET_CONSIGNEES_SUCCESS:
      return {
        type: action.type,
        loading: false, 
        data: action.data,
        consignees: action.consignees,
        consigneeTransform: action.consigneeTransform
      };
    case consigneeTypes.GET_CONSIGNEES_ERROR:
      return {
        type: action.type,
        loading: false, 
        error: action.error
      };
    default:
      return state;
  }
};