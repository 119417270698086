import { loginTypes } from "../actions/auth_action";

let initialState = {
    isLoading: false,
    error: null,
    data: {},
    status: false,
    type: "AUTH_LOGIN"
};

let logoutInitialState = {
    loading: false,
    type: "AUTH_LOGOUT"
}

let checkLoggedInInitialState = {
    authorized: false,
    loading: false,
    type: "AUTH_CHECK"
}

let initialGetMeState = {
    loading: false,
    error: null,
    data: null,
    type: loginTypes.GET_ME
}

let initialLoadGetMeState = {
  type: loginTypes.GET_LOAD_GET_ME,
  data: null
}

export const authCheckLoggedInReducer = (state = checkLoggedInInitialState, action) => {
    switch (action.type) {
        case "AUTH_CHECK":
            return {
                authorized: action.authorized,
                loading: action.loading,
                type: action.type
            };
        case "AUTHORIZED":
            return {
                authorized: action.authorized,
                loading: action.loading,
                type: action.type
            };
        case "UNAUTHORIZED":
            return {
                authorized: action.authorized,
                loading: action.loading,
                payload: "mantap",
                type: action.type
            };
        default:
            return state;
    }
}

export const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case loginTypes.AUTH_LOGIN:
            return {
                isLoading: true,
                type: action.type
            };
        case loginTypes.AUTH_LOGIN_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                status: action.status,
                type: action.type
            };
        case loginTypes.AUTH_LOGIN_ERROR:
            return {
                isLoading: true,
                error: action.error,
                status: action.status,
                type: action.type
            };
        default:
            return state;
    }
};

export const authLogoutReducer = (state = logoutInitialState, action) => {
    switch (action.type) {
        case "AUTH_LOGOUT":
            return {
                loading: true,
                type: action.type,
            };
        case "AUTH_LOGOUT_SUCCESS":
            return {
                loading: false,
                type: action.type,
            };
        default:
            return state;
    }
}

export const getMeReducer = (state = initialGetMeState, action) => {
    switch (action.type) {
        case loginTypes.GET_ME:
            return {
                loading: action.loading,
                type: action.type,
            };
        case loginTypes.GET_ME_SUCCESS:
            return {
                loading: action.loadng,
                type: action.type,
                data: action.data
            };
        case loginTypes.GET_ME_ERROR:
            return {
                loading: action.loading,
                type: action.type,
                error: action.error
            };
        default:
            return state;
    }
}

export const loadGetMeReducer = (state = initialLoadGetMeState, action) => {
  switch (action.type) {
    case loginTypes.GET_LOAD_GET_ME:
      return {
        type: action.type
      }
    case loginTypes.LOAD_GET_ME:
      return {
        type: action.type,
        data: action.data,
      }
    default:
      return state;
  }
}