let initialState = {
  loading: true,
  data: {},
  type: 'GET_ROLES',
  error: {},
  roles: []
};

let initialCreateState = {
  loading: true,
  data: {},
  type: 'CREATE_ROLES',
  error: {},
};

let initialUpdateState = {
  loading: true,
  data: {},
  type: 'UPDATE_ROLES',
  error: {},
};

let initialDeleteState = {
  loading: true,
  data: {},
  type: 'DELETE_ROLES',
  error: {},
};

let viewRoleReducerState = {
  type: 'VIEW_ROLE',
  role: null
};

let saveRoleReducerState = {
  type: 'SAVE_ROLE',
  role: null
};

export const saveRoleReducer = (state = saveRoleReducerState, action) => {
  switch (action.type) {
    case 'SAVE_ROLE':
      return {
        type: action.type,
        role: action.role
      }
    default:
      return state;
  }
}

export const viewRoleReducer = (state = viewRoleReducerState, action) => {
  switch (action.type) {
    case 'VIEW_ROLE':
      let role = action.role;
      return {
        type: action.type,
        role,
      }
    default:
      return state;
  }
}

export const updateRoleReducer = (state = initialUpdateState, action) => {
  switch (action.type) {
    case 'UPDATE_ROLES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'UPDATE_ROLES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'UPDATE_ROLES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const deleteRoleReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case 'DELETE_ROLES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'DELETE_ROLES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'DELETE_ROLES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const createRoleReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case 'CREATE_ROLES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'CREATE_ROLES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'CREATE_ROLES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ROLES':   
      return {
        type: action.type,
        loading: true,
      };
    case 'GET_ROLES_SUCCESS':
      return {
        type: action.type,
        loading: false, 
        data: action.data,
        roles: action.roles
      };
    case 'GET_ROLES_ERROR':
      return {
        type: action.type,
        loading: false, 
        error: action.error
      };
    default:
      return state;
  }
};