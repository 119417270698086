import Api from '../../api/api';
import { toast } from 'react-toastify';

export function saveStatuses(status) {
  return dispatch => {
    // delete status in localStorage
    localStorage.removeItem('status');

    // add status to localStorage
    localStorage.setItem('status', JSON.stringify(status));
    dispatch({
      type: "SAVE_STATUS",
      status: status
    });
    return;
  }
}

export function viewStatuses() {
  return dispatch => {
    // get status in localStorage
    var status = JSON.parse(localStorage.getItem('status'));
    dispatch({
      type: "VIEW_STATUS",
      status
    }); 
    return;
  }
}

export function initializeCreateStatuses() {
  return dispatch => {
    dispatch({
      type: "CREATE_STATUSES"
    });
  }
}

export function initializeUpdateStatuses() {
  return dispatch => {
    dispatch({
      type: "UPDATE_STATUSES"
    });
  }
}

export function createStatuses(forms) {
  return dispatch => {
    dispatch({
      type: "CREATE_STATUSES"
    });

    var api = new Api();
    api.createStatuses(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: "CREATE_STATUSES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "CREATE_STATUSES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "CREATE_STATUSES_ERROR",
            error
        });
        return;
      });
  }
}

export function updateStatuses(forms, statusId) {
  return dispatch => {
    dispatch({
      type: "UPDATE_STATUSES"
    });

    var api = new Api();
    api.updateStatuses(forms, statusId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: "UPDATE_STATUSES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "UPDATE_STATUSES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "UPDATE_STATUSES_ERROR",
            error
        });
        return;
      });
  }
}

export function deleteStatus(statusId) {
  return dispatch => {
    dispatch({
      type: "DELETE_STATUSES"
    });

    var api = new Api();
    api.deleteStatuses(statusId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: "DELETE_STATUSES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "DELETE_STATUSES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "DELETE_STATUSES_ERROR",
            error
        });
        return;
      });
  }
}

export function getStatuses(page, perPage, filters) {
  return dispatch => {
    dispatch({
      type: "GET_STATUSES",
      data: {},
      statuses: []
    });

    var api = new Api();
    api.getStatuses(page, perPage, filters)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "GET_STATUSES_SUCCESS",
            data: response.data,
            statuses: response.data.data.data
          });
          return;
        } else {
          dispatch({
            type: "GET_STATUSES_ERROR",
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "GET_STATUSES_ERROR",
            error
        });
        return;
      })
  }
}