import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { viewRoles } from '../../redux/actions/role_action';
import ContentHeader from '../../components/content_header';

function ViewRole() {

  const role = useSelector(state => state.viewRoleReducer.role);
  const viewRoleState = useSelector(state => state.viewRoleReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewRoles());
  }, [viewRoles])

  useEffect(() => {
    console.log(viewRoleState);
  }, [role, viewRoleState])

  return (
    <div className="content-wrapper">
      <ContentHeader title={'View Role'} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Full Name</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={role && role.name}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Rolename</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={role && role.slug}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(ViewRole)
