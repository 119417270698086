import React, { Component, useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory, withRouter} from 'react-router-dom';
import ContentHeader from '../../components/content_header';
import { getCities } from '../../redux/actions/city_action';
import Select from 'react-select';
import { createRates } from '../../redux/actions/rate_action';
import { ToastContainer } from 'react-toastify';


function CreateRate(props) {

  const cityState = useSelector(state => state.cityReducer);
  const createRateState = useSelector(state => state.createRateReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedOriginCityOption, setSelectedOriginCityOption] = useState(0);
  const [selectedDestinationCityOption, setSelectedDestinationCityOption] = useState(0);
  const [originCityOptions, setOriginCityOptions] = useState([]);
  const [destinationCityOptions, setDestinationCityOptions] = useState([]);

  // State form
  const [rates, setRates] = useState("");
  const [leadTime, setLeadTime] = useState("");
  const [selectedServices, setSelectedServices] = useState("");
  const [unit, setUnit] = useState("");
  const [servicesOptions, setServicesOptions] = useState([]);

  useEffect(() => {
    dispatch(getCities(""));
  }, [getCities]);

  useEffect(() => {
    let sOps = [
      {
        value: "REGULER",
        label: "REGULER",
      },
      {
        value: "EKSPRES",
        label: "EKSPRES",
      },
      {
        value: "EKONOMI",
        label: "EKONOMI",
      },
    ];
    setServicesOptions(sOps);
  }, []);

  useEffect(() => {
    if (createRateState.type === "CREATE_RATES_SUCCESS") {
      history.goBack();
    }
  }, [createRateState]);

  useEffect(() => {
    try {
      if (cityState.type === 'GET_CITIES_SUCCESS') {
        let options = [];
        cityState.data && cityState.data.map((item, index) => {
          options.push({
            value: item.id,
            label: item.cityName
          })
        });
        setOriginCityOptions(options);
        setDestinationCityOptions(options);

        console.log(originCityOptions);
      }
    } catch (e) {
      console.log(e);
    }
  }, [cityState]);

  useEffect(() => {}, [originCityOptions, destinationCityOptions])

  const handleChangeOrigin = selectedOption => {
    setSelectedOriginCityOption(selectedOption);
  };

  const handleChangeDestination = selectedOption => {
    setSelectedDestinationCityOption(selectedOption);
  };

  const handleChangeService = selectedOption => {
    setSelectedServices(selectedOption);
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      rates: rates,
      services: selectedServices.value,
      leadTime: leadTime,
      unit: unit,
      originCityId: selectedOriginCityOption.value,
      destinationCityId: selectedDestinationCityOption.value
    }

    console.log(forms)

    dispatch(createRates(forms));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Create Rate'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Origin City</label>
                      <Select
                        value={selectedOriginCityOption}
                        onChange={handleChangeOrigin}
                        options={originCityOptions}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Destination City</label>
                      <Select
                        value={selectedDestinationCityOption}
                        onChange={handleChangeDestination}
                        options={destinationCityOptions}
                      />  
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Services</label>
                      <Select
                        value={selectedServices}
                        onChange={handleChangeService}
                        options={servicesOptions}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Rates</label>
                      <input 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        placeholder="Enter Rates" 
                        onChange={(e) => setRates(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Lead Time</label>
                      <input 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        placeholder="Enter Lead Time" 
                        onChange={(e) => setLeadTime(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Unit</label>
                      <input 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        placeholder="Enter Unit" 
                        onChange={(e) => setUnit(e.target.value)}/>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(CreateRate);