const { filesTypes } = require("../actions/files_action");

let initialState = {
  loading: false,
  data: {},
  type: filesTypes.CREATE_FILES,
  error: {},
}

let initializedUploadFilesState = {
  loading: false,
  type: filesTypes.CREATE_FILES,
}

export const initializedUploadFilesReducer = (state = initializedUploadFilesState, action) => {
  switch (action.type) {
    case filesTypes.CREATE_FILES_INITIALIZED:
      return {
        type: action.type,
        loading: action.loading
      }
    default:
      return state;
  }
}

export const uploadFilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case filesTypes.CREATE_FILES:
      return {
        type: action.type,
        loading: action.loading
      };
    case filesTypes.CREATE_FILES_UPLOADING:
      return {
        type: action.type,
        loading: action.loading
      };
    case filesTypes.CREATE_FILES_SUCCESS:
      return {
        type: action.type,
        loading: action.loading,
        data: action.data
      }
    case filesTypes.CREATE_FILES_ERROR:
      return {
        type: action.type,
        loading: action.loading,
        data: action.data,
        error: action.error,
      }
    default:
      return state;
  }
}