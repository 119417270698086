let initialState = {
  loading: true,
  data: {},
  type: 'GET_RATES',
  error: {},
  rates: []
};

let initialCreateState = {
  loading: true,
  data: {},
  type: 'CREATE_RATES',
  error: {},
};

let initialUpdateState = {
  loading: true,
  data: {},
  type: 'UPDATE_RATES',
  error: {},
};

let initialDeleteState = {
  loading: true,
  data: {},
  type: 'DELETE_RATES',
  error: {},
};

let viewRateReducerState = {
  type: 'VIEW_RATE',
  rate: null
};

let saveRateReducerState = {
  type: 'SAVE_RATE',
  rate: null
};

let initialCheckRateState = {
  type: 'CHECK_RATES',
  data: {},
  error: {},
  loading: false
}

export const checkRateReducer = (state = initialCheckRateState, action) => {
  switch (action.type) {
    case 'CHECK_RATES':
      return {
        type: action.type,
        loading: action.loading
      }
    case 'CHECK_RATES_SUCCESS':
      return {
        type: action.type,
        loading: action.loading,
        data: action.data
      }
    case 'CHECK_RATES_ERROR':
      return {
        type: action.type,
        loading: action.loading,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const saveRateReducer = (state = saveRateReducerState, action) => {
  switch (action.type) {
    case 'SAVE_RATE':
      return {
        type: action.type,
        rate: action.rate
      }
    default:
      return state;
  }
}

export const viewRateReducer = (state = viewRateReducerState, action) => {
  switch (action.type) {
    case 'VIEW_RATE':
      let rate = action.rate;
      return {
        type: action.type,
        rate,
      }
    default:
      return state;
  }
}

export const updateRateReducer = (state = initialUpdateState, action) => {
  switch (action.type) {
    case 'UPDATE_RATES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'UPDATE_RATES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'UPDATE_RATES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const deleteRateReducer = (state = initialDeleteState, action) => {
  console.log("Delete", action);
  switch (action.type) {
    case 'DELETE_RATES':
      return {
        type: action.type,
        loading: true
      }
    case 'DELETE_RATES_SUCCESS':
      return {
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'DELETE_RATES_ERROR':
      return {
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const createRateReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case 'CREATE_RATES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'CREATE_RATES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'CREATE_RATES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const rateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_RATES':
      return {
        type: action.type,
        loading: true,
      };
    case 'GET_RATES_SUCCESS':
      return {
        type: action.type,
        loading: false, 
        data: action.data,
        rates: action.rates
      };
    case 'GET_RATES_ERROR':
      return {
        type: action.type,
        loading: false, 
        error: action.error
      };
    default:
      return state;
  }
};