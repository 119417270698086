import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, withRouter} from 'react-router-dom';
import ContentHeader from '../../components/content_header';
import { viewRoles, updateRoles } from '../../redux/actions/role_action';

function EditRole(props) {

  const updateRoleState = useSelector(state => state.updateRoleReducer);
  const viewRoleState = useSelector(state => state.viewRoleReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  // State form
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");

  useEffect(() => {
    dispatch(viewRoles());
  }, [viewRoles])

  useEffect(() => {
    try {
      setName(viewRoleState.role.name);
      setSlug(viewRoleState.role.slug);
    } catch (e) {
      console.log(e)
    }
  }, [viewRoleState])

  useEffect(() => {
    if (updateRoleState.type === "UPDATE_ROLES_SUCCESS") {
      history.goBack();
    }
  }, [updateRoleState]);

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      name: name,
      slug: slug,
    }

    console.log(forms)

    dispatch(updateRoles(forms, viewRoleState.role.id));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Edit Role'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input 
                        className="form-control"
                        placeholder="Enter Name" 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Slug</label>
                      <input 
                        className="form-control" 
                        placeholder="Enter Slug" 
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}/>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(EditRole);