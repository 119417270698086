export const filterTypes = {
  UNINITIALIZED_FILTERS: "UNINITIALIZED_FILTERS",
  INITIALIZED_FILTERS: "INITIALIZED_FILTERS"
}

export function initializedFilters(filters) {
  return dispatch => {
    dispatch({
      type: filterTypes.INITIALIZED_FILTERS,
      filters: filters
    });
    return;
  }
}

export function uninitializedFilters() {
  let fil = {};
  return dispatch => {
    dispatch({
      type: filterTypes.UNINITIALIZED_FILTERS,
      filters: fil
    });
    return;
  }
}