import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, withRouter} from 'react-router-dom';
import ContentHeader from '../../components/content_header';
import { getUsers } from '../../redux/actions/user_action';
import Select from 'react-select';
import { createUsers } from '../../redux/actions/user_action';
import { getRoles } from '../../redux/actions/role_action';
import { filesTypes, uploadFiles, uploadFilesInitialized } from '../../redux/actions/files_action';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Api from '../../api/api';

function CreateUser(props) {

  const createUserState = useSelector(state => state.createUserReducer);
  const roleState = useSelector(state => state.roleReducer);
  const uploadFilesState = useSelector(state => state.uploadFilesReducer);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const history = useHistory();
  const inputFile = useRef(null);
  const api = new Api();
  const [selectedRoleOption, setSelectedRoleOption] = useState(0);
  const [roleOptions, setRoleOptions] = useState([]);

  // State form
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [file, setFile] = useState();
  const [fileId, setFileId] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    dispatch(getRoles(1, 15));
    dispatch(uploadFilesInitialized());
  }, [getRoles]);

  useEffect(() => {
    if (createUserState.type === "CREATE_USERS_SUCCESS") {
      history.goBack();
    }
  }, [createUserState]);

  useEffect(() => {
    if (file !== undefined) {
      dispatch(uploadFiles(file));
    }
  }, [file]);

  useEffect(() => {
    try {
      console.log("createFiles.type", uploadFilesState.type)
      if (uploadFilesState.type === filesTypes.CREATE_FILES_SUCCESS) {
        MySwal.close();
        setStatus("Uploaded");
        setFileId(uploadFilesState.data?.data);
      } else if (uploadFilesState.type === filesTypes.CREATE_FILES_UPLOADING){
        MySwal.fire({
          title: "Uploading File",
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          willOpen: () => {
            MySwal.showLoading();
          }
        });
        setStatus("Uploading");
      }
    } catch (e) { 
      console.log(e);
    }
  }, [uploadFilesState]);

  useEffect(() => {
    try {
      if (roleState.type === 'GET_ROLES_SUCCESS') {
        let options = [];
        roleState.roles && roleState.roles.map((item, index) => {
          options.push({
            value: item.id,
            label: item.name
          })
        });
        setRoleOptions(options);
      }
    } catch (e) {
      console.log(e);
    }
  }, [roleState]);

  useEffect(() => {}, [roleOptions])

  const handleChangeRole = selectedOption => {
    setSelectedRoleOption(selectedOption);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      fullName: fullName,
      username: username,
      email: email,
      phoneNumber: phoneNumber,
      roleId: selectedRoleOption.value,
      password: password,
      filesId: fileId?.id
    }

    console.log(forms)

    dispatch(createUsers(forms));
  }

  const handleBrowseFileClick = (e) => {
    e.preventDefault();
    inputFile.current.click();
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Create User'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">Upload Foto</label>
                      <div className="image">
                        {
                          uploadFilesState.data?.data?.filePath
                          ? <img 
                              src={`${api.baseURL}/${uploadFilesState.data?.data?.filePath}`} 
                              style={{
                                width: 160,
                                height: 160,
                                borderRadius: '100%',
                                objectFit: 'cover'
                              }}
                              className="elevation-2"
                              alt={"User Profile"}
                            />
                          : <img 
                            src="/img/user2-160x160.jpg" 
                            className="img-circle elevation-2" 
                            alt={"User Profile"}/>
                        }
                      </div>
                      <br/>
                      <div className="input-group">
                        <button   
                          className="btn btn-success"
                          onClick={handleBrowseFileClick}>Browse File</button>
                          <input 
                            type="file"
                            ref={inputFile}
                            accept={'image/*'}
                            style={{
                              display: 'none'
                            }}
                            onChange={(e) => setFile(e.target.files)}
                            />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Full Name</label>
                      <input 
                        className="form-control"
                        placeholder="Enter Full Name" 
                        name="fullName"
                        onChange={(e) => setFullName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                      <label>Username</label>
                      <input 
                        className="form-control"
                        placeholder="Enter Username" 
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input 
                        className="form-control" 
                        placeholder="Enter Email" 
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input 
                        className="form-control" 
                        placeholder="Enter Phone Number" 
                        name="phoneNumber"
                        onChange={(e) => setPhoneNumber(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input 
                        className="form-control" 
                        placeholder="Enter Password"
                        type={'password'} 
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label>Role</label>
                      <Select
                        value={selectedRoleOption}
                        onChange={handleChangeRole}
                        options={roleOptions}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputFile">Upload Foto</label>
                      <div className="input-group">
                        <button   
                          className="btn btn-success"
                          onClick={handleBrowseFileClick}>Browse File {status}</button>
                          <input 
                            type="file"
                            ref={inputFile}
                            accept={'image/*'}
                            style={{
                              display: 'none'
                            }}
                            onChange={(e) => setFile(e.target.files)}
                            />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(CreateUser);