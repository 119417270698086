import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import ContentHeader from '../../components/content_header';

function ViewProvince () {

  const location = useLocation();
  const [provinceName, setProvinceName] = useState("");

  useEffect(() => {
    try {
      setProvinceName(location.state.provinceName);
    } catch (e) {
      console.log(e);
    }
  }, [location])

  return (
    <div className="content-wrapper">
      <ContentHeader title={'View Provinsi'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Nama Provinsi</label>
                      <input 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        placeholder="Enter Company Name" 
                        value={provinceName}
                        readOnly
                        />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(ViewProvince);