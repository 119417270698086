import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, withRouter, Link, useLocation } from "react-router-dom";
import {
  getTransactions,
  saveTransactions,
  deleteTransaction,
  initializeCreateTransactions,
  initializeUpdateTransactions,
  loadLastPageTrx,
  saveLastPageTrx,
} from "../../redux/actions/transaction_action";
import ContentHeader from "../../components/content_header";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import qs from "query-string";
import ReactPaginate from "react-paginate";
import { getClients } from "../../redux/actions/client_action";
import Select from "react-select";
import { initializeCreateTransactionDetails } from "../../redux/actions/transaction_detail_action";
import DatePicker from "react-datepicker";
import {
  initializedFilters,
  uninitializedFilters,
} from "../../redux/actions/filters_action";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

// Import as a module in your JS
import "react-bootstrap-typeahead/css/Typeahead.css";

function Transaction(props) {
  const transactionState = useSelector((state) => state.transactionReducer);
  const clientState = useSelector((state) => state.clientReducer);
  const transactionDeleteState = useSelector(
    (state) => state.deleteTransactionReducer
  );
  const filterState = useSelector((state) => state.filterReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const location = useLocation();

  // Pagination
  // const [page, setPage] = useState(1);
  const [pagePerPage, setPagePerPage] = useState({
    page: +loadLastPageTrx() ?? 1,
    perPage: 50,
  });
  // const [perPage, setPerPage] = useState(15);
  const [totalPage, setTotalPage] = useState(0);
  const [filters, setFilters] = useState({
    airWayBillNumber: "",
    clientId: "",
    createdAt: "",
  });
  const [selectedClient, setSelectedClient] = useState(0);
  const [clientOptions, setClientOptions] = useState([]);
  const [createdAt, setCreatedAt] = useState();

  // Data Grid
  const [columnWidths, setColumnWidths] = useState([
    { columnName: "no", width: 180, title: "No" },
    { columnName: "airWayBill", width: 180, title: "Airway Bill" },
    { columnName: "client", width: 180, title: "Client" },
    { columnName: "consignee", width: 240, title: "Consignee" },
    { columnName: "createdAt", width: 240, title: "Tanggal Kirim" },
    { columnName: "delivered", width: 240, title: "Status" },
    { columnName: "action", width: 240, title: "Actions" },
  ]);

  useEffect(() => {
    if (location) {
      console.log("ghazi__", qs.parse(location.search));
    }
  }, [location]);

  useEffect(() => {
    let params = qs.parse(location.search);
    try {
      if (params.page !== undefined && params.perPage !== undefined) {
        setPagePerPage({
          page: params?.page,
          perPage: params?.perPage,
        });
      }

      if (params.createdAt) {
        setCreatedAt(parseInt(params.createdAt));
        setFilters({
          ...filters,
          createdAt: parseInt(params.createdAt),
        });
      }
    } catch (e) {
      console.log(e);
    }

    dispatch(getClients(1, 300));
    dispatch(initializeCreateTransactions());
    dispatch(initializeUpdateTransactions());
    dispatch(initializeCreateTransactionDetails());
  }, []);

  useEffect(() => {
    console.log("ghazi_ ", pagePerPage.page);
    console.log("ghazi_pagePerPage", pagePerPage);
    console.log("ghazi_pagePerPage", filterState?.filters);
    try {
      dispatch(
        getTransactions(
          pagePerPage.page,
          pagePerPage.perPage,
          filterState?.filters
        )
      );
    } catch (e) {
      console.log("ghazi_pagePerPage", e);
    }
  }, [pagePerPage]);

  useEffect(() => {
    dispatch(getTransactions(1, 50, filterState?.filters));

    setCreatedAt(filterState?.filters?.createdAt);
    setFilters(filterState?.filters);
    setSelectedClient({
      value: filterState?.filters?.clientId?.value,
      label: filterState?.filters?.clientId?.label,
    });
  }, [filterState]);

  useEffect(() => {
    try {
      if (clientState.type === "GET_CLIENTS_SUCCESS") {
        let options = [];
        clientState.clients.map((item, index) => {
          options.push({
            value: item.id,
            label: item.companyName,
          });
        });
        setClientOptions(options);
      }
    } catch (e) {
      console.log(e);
    }
  }, [clientState]);

  useEffect(() => {
    try {
      console.log("transactionState: ", transactionState);
      setTotalPage(transactionState.data.data.totalPage ?? 1);
    } catch (e) {
      console.log(e);
    }
  }, [transactionState]);

  useEffect(() => {
    try {
      if (transactionDeleteState.type === "DELETE_TRANSACTIONS_SUCCESS") {
        dispatch(getTransactions(1, 15, filters));
      }
    } catch (e) {
      console.log(e);
    }
  }, [transactionDeleteState]);

  const viewTransaction = (event, c) => {
    // event.preventDefault();
    dispatch(saveTransactions(c));
    history.push("transactions-view");
  };

  const editTransaction = (event, c) => {
    dispatch(saveTransactions(c));
    history.push("transaction-details-create");
  };

  const onDeleteTransaction = (event, c) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "This data will be deleted",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.value) {
        dispatch(deleteTransaction(c.id));
      }
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setPagePerPage({
      ...pagePerPage,
      page: selectedPage,
    });
    saveLastPageTrx(selectedPage);
    dispatch(
      getTransactions(selectedPage, pagePerPage.perPage, filterState?.filters)
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("filters", filters);

    dispatch(initializedFilters(filters));
    // dispatch(getTransactions(1, 15, filters));
  };

  const handleChangeClient = (selectedOptions) => {
    setSelectedClient(selectedOptions);
    setFilters({
      ...filters,
      clientId: selectedOptions,
    });
  };

  return (
    <div className="content-wrapper">
      <ContentHeader title={"Transaction"} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleSearch}>
                  <div className="card-header">
                    <h3 className="card-title">Search</h3>
                    <div className="card-tools">
                      <button
                        type="submit"
                        className="btn btn-warning"
                        onClick={(e) => {
                          e.preventDefault();
                          setFilters({});
                          setSelectedClient(0);
                          setCreatedAt(0);
                          dispatch(getTransactions(1, 50, {}));
                          dispatch(uninitializedFilters());
                        }}
                      >
                        Reset
                      </button>
                      {/* <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i></button>
                                <button type="button" className="btn btn-tool" data-card-widget="remove"><i className="fas fa-times"></i></button> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Airway Bill
                          </label>
                          <input
                            className="form-control"
                            value={
                              filters.hasOwnProperty("airWayBillNumber")
                                ? filters.airWayBillNumber
                                : ""
                            }
                            placeholder={"Enter Airway Bill"}
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                airWayBillNumber: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Client</label>
                          <Select
                            value={selectedClient}
                            onChange={handleChangeClient}
                            options={clientOptions}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Tanggal Pengiriman
                          </label>{" "}
                          <br />
                          <DatePicker
                            className={"form-control"}
                            selected={createdAt}
                            placeholderText={"Enter Tanggal Pengiriman"}
                            onChange={(date) => {
                              setCreatedAt(date);
                              setFilters({
                                ...filters,
                                createdAt: date?.getTime(),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-12">
              {/* <div className="card">
                <div className="card-body">
                <Grid rows={rows} columns={columns}>
                  <Table />
                  <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                  <TableHeaderRow />
                </Grid>
                </div>
              </div> */}
              <div className="card">
                <div className="card-header">
                  <Link
                    type={"button"}
                    className={"btn btn-sm btn-success card-title"}
                    to={"/home/transactions-create"}
                  >
                    Create Transaction
                  </Link>
                </div>

                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        {columnWidths.map((item, index) => {
                          return (
                            <th
                              style={{
                                position: "relative",
                                width: item.width,
                              }}
                            >
                              {item.title}
                              {/* <Draggable
                                  axis="x"
                                  onDrag={(e, ui) => handleDrag(e, ui)}>
                                <div style={{
                                    backgroundColor: 'blue',
                                    width: 5,
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    position: 'absolute',
                                    cursor: 'col-resize',
                                    userSelect: 'none',
                                  }}></div>
                                </Draggable> */}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {transactionState?.loading ? (
                        <tr>
                          <td>Loading</td>
                        </tr>
                      ) : (
                        transactionState?.transactions?.map((item, index) => {
                          const tDetails = item.transactionDetails.find(
                            (i) =>
                              i.status.id ===
                              "df3a2224-b276-495d-b3a6-270fd95c9753"
                          );
                          const tDetailsFailed = item?.transactionDetails?.find(
                            (i) =>
                              i?.status?.id ===
                                "3c567deb-ae3f-4fe1-83de-f209c87993a9" ||
                              i?.status?.id ===
                                "255b3384-d648-49f9-9918-0bc44c71f9d5"
                          );

                          // if (tDetailsFailed) {
                          //   const tDetailsFailedStatus = JSON.stringify(
                          //     tDetailsFailed?.status?.text ?? "{}"
                          //   );
                          //   console.log(
                          //     "zxczxc",
                          //     item.airWayBillNumber,
                          //     JSON.parse(tDetailsFailedStatus)
                          //   );
                          //   // console.log(
                          //   //   item.airWayBillNumber,
                          //   //   JSON.parse(tDetailsFailed?.status?.text ?? {})
                          //   // );
                          // }
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.airWayBillNumber}</td>
                              <td>{item.client.companyName}</td>
                              <td>{item.consignee}</td>
                              <td>
                                {new Date(
                                  parseInt(item.createdAt)
                                ).toLocaleString("default", { hour12: false })}
                              </td>
                              {/* <td>{item.delivered ? "Delivered" : "On Progress"}</td> */}
                              <td>
                                {tDetails !== undefined
                                  ? "Delivered"
                                  : tDetailsFailed === undefined
                                  ? "On Progress"
                                  : "Delivery Failed"}
                              </td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    onClick={(event) =>
                                      viewTransaction(event, item)
                                    }
                                  >
                                    View
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-warning"
                                    onClick={(event) =>
                                      editTransaction(event, item)
                                    }
                                  >
                                    Update
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={(event) =>
                                      onDeleteTransaction(event, item)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                {/* /.card-body */}

                <div className="card-footer clearfix">
                  <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabe={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    initialPage={pagePerPage.page - 1}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination pagination-sm m-0 float-right"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(Transaction);
