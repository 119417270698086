import Api from "../../api/api";

export const dashboardTypes = {
    GET_DASHBOARD: 'GET_DASHBOARD',
    GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
    GET_DASHBOARD_ERROR: 'GET_DASHBOARD_ERROR',
}

export function getDashboard() {
    return dispatch => {
        dispatch({
            type: dashboardTypes.GET_DASHBOARD,
            loading: true
        });

        var api = new Api();
        api.getDashboard()
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: dashboardTypes.GET_DASHBOARD_SUCCESS,
                        data: response.data,
                        loading: false,
                    });
                    return;
                } else {
                    dispatch({
                        type: dashboardTypes.GET_DASHBOARD_ERROR,
                        data: response.data,
                        loading: false,
                    });
                    return;
                }
            })
            .catch(error => {
                dispatch({
                    type: dashboardTypes.GET_DASHBOARD_ERROR,
                    loading: false,
                    error
                });
                return;
            })
    }
}