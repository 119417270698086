import React, { Component, useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory, withRouter} from 'react-router-dom';
import ContentHeader from '../../components/content_header';
import { getCities } from '../../redux/actions/city_action';
import Select from 'react-select';
import { createRoles } from '../../redux/actions/role_action';
import { ToastContainer } from 'react-toastify';

function CreateRole(props) {

  const createRoleState = useSelector(state => state.createRoleReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  // State form
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");

  useEffect(() => {
    if (createRoleState.type === "CREATE_ROLES_SUCCESS") {
      history.goBack();
    }
  }, [createRoleState]);

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      name: name,
      slug: slug,
    }

    console.log(forms)

    dispatch(createRoles(forms));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Create Role'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input 
                        className="form-control"
                        placeholder="Enter Name" 
                        onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Slug</label>
                      <input 
                        className="form-control" 
                        placeholder="Enter Slug" 
                        onChange={(e) => setSlug(e.target.value)}/>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(CreateRole);