import Api from '../../api/api';
import { toast } from 'react-toastify';

export const transactionDetailsTypes = {
  SAVE_TRANSACTION_DETAIL: "SAVE_TRANSACTION_DETAIL",
  REMOVE_TRANSACTION_DETAIL: "REMOVE_TRANSACTION_DETAIL",
  VIEW_TRANSACTION_DETAIL: "VIEW_TRANSACTION_DETAIL",
  CREATE_TRANSACTION_DETAILS: "CREATE_TRANSACTION_DETAILS",
  CREATE_TRANSACTION_DETAILS_SUCCESS: "CREATE_TRANSACTION_DETAILS_SUCCESS",
  CREATE_TRANSACTION_DETAILS_ERROR: "CREATE_TRANSACTION_DETAILS_ERROR",
  UPDATE_TRANSACTION_DETAILS: "UPDATE_TRANSACTION_DETAILS",
  UPDATE_TRANSACTION_DETAILS_SUCCESS: "UPDATE_TRANSACTION_DETAILS_SUCCESS",
  UPDATE_TRANSACTION_DETAILS_ERROR: "UPDATE_TRANSACTION_DETAILS_ERROR",
  DELETE_TRANSACTION_DETAILS: "DELETE_TRANSACTION_DETAILS",
  DELETE_TRANSACTION_DETAILS_SUCCESS: "DELETE_TRANSACTION_DETAILS_SUCCESS",
  DELETE_TRANSACTION_DETAILS_ERROR: "DELETE_TRANSACTION_DETAILS_ERROR",
  GET_TRANSACTION_DETAILS: "GET_TRANSACTION_DETAILS",
  GET_TRANSACTION_DETAILS_SUCCESS: "GET_TRANSACTION_DETAILS_SUCCESS",
  GET_TRANSACTION_DETAILS_ERROR: "GET_TRANSACTION_DETAILS_ERROR",
}

export function saveTransactionDetails(transactionDetail) {
  return dispatch => {
    // delete transactionDetail in localStorage
    localStorage.removeItem('transactionDetail');

    // add transactionDetail to localStorage
    localStorage.setItem('transactionDetail', JSON.stringify(transactionDetail));
    dispatch({
      type: transactionDetailsTypes.SAVE_TRANSACTION_DETAIL,
      transactionDetail: transactionDetail
    });
    return;
  }
}

export function removeTransactionDetails() {
  return dispatch => {
    localStorage.removeItem('transactionDetail');
    dispatch({
      type: transactionDetailsTypes.REMOVE_TRANSACTION_DETAIL,
      transactionDetail: null
    });
    return;
  }
}

export function viewTransactionDetails() {
  return dispatch => {
    // get transactionDetail in localStorage
    var transactionDetail = JSON.parse(localStorage.getItem('transactionDetail'));
    dispatch({
      type: transactionDetailsTypes.VIEW_TRANSACTION_DETAIL,
      transactionDetail
    }); 
    return;
  }
}

export function initializeCreateTransactionDetails() {
  return dispatch => {
    dispatch({
      type: transactionDetailsTypes.CREATE_TRANSACTION_DETAILS,
    });
  }
}

export function initializeUpdateTransactionDetails() {
  return dispatch => {
    dispatch({
      type: transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS,
    });
  }
}

export function createTransactionDetails(forms) {
  return dispatch => {
    dispatch({
      type: transactionDetailsTypes.CREATE_TRANSACTION_DETAILS,
    });

    var api = new Api();
    api.createTransactionDetails(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: transactionDetailsTypes.CREATE_TRANSACTION_DETAILS_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: transactionDetailsTypes.CREATE_TRANSACTION_DETAILS_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionDetailsTypes.CREATE_TRANSACTION_DETAILS_ERROR,
            error
        });
        return;
      });
  }
}

export function updateTransactionDetails(forms, transactionDetailId) {
  return dispatch => {
    dispatch({
      type: transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS,
    });

    var api = new Api();
    api.updateTransactionDetails(forms, transactionDetailId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS_ERROR,
            error
        });
        return;
      });
  }
}

export function deleteTransactionDetail(transactionDetailId) {
  return dispatch => {
    dispatch({
      type: transactionDetailsTypes.DELETE_TRANSACTION_DETAILS,
    });

    var api = new Api();
    api.deleteTransactionDetails(transactionDetailId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: transactionDetailsTypes.DELETE_TRANSACTION_DETAILS_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: transactionDetailsTypes.DELETE_TRANSACTION_DETAILS_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionDetailsTypes.DELETE_TRANSACTION_DETAILS_ERROR,
            error
        });
        return;
      });
  }
}

export function getTransactionDetails(page, perPage, filters) {
  return dispatch => {
    dispatch({
      type: transactionDetailsTypes.GET_TRANSACTION_DETAILS,
      data: {},
      transactionDetails: []
    });

    var api = new Api();
    api.getTransactionDetails(page, perPage, filters)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: transactionDetailsTypes.GET_TRANSACTION_DETAILS_SUCCESS,
            data: response.data,
            transactionDetails: response.data.data.data
          });
          return;
        } else {
          dispatch({
            type: transactionDetailsTypes.GET_TRANSACTION_DETAILS_ERROR,
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionDetailsTypes.GET_TRANSACTION_DETAILS_ERROR,
            error
        });
        return;
      })
  }
}