import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, withRouter, Link, useLocation } from 'react-router-dom';
import { getClients, saveClients, deleteClient, initializeCreateClients, initializeUpdateClients } from '../../redux/actions/client_action';
import ContentHeader from '../../components/content_header';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import qs from 'query-string'
import ReactPaginate from 'react-paginate';
import { uninitializedFilters } from '../../redux/actions/filters_action';

function Client(props) {

  const {
    loading,
    clients,
    data
  } = useSelector(state => state.clientReducer);
  const clientDeleteState = useSelector(state => state.deleteClientReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const location = useLocation();

  // Pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let params = qs.parse(location.search);

    try {
      setPage(params && params.page);
      setPerPage(params && params.perPage);
    } catch (e) {
      console.log(e);
    }

    dispatch(initializeCreateClients());
    dispatch(initializeUpdateClients());
    dispatch(uninitializedFilters());
  }, [page, perPage]);

  useEffect(() => {
    dispatch(getClients(page, perPage));
  }, [])

  useEffect(() => {
    try {
      console.log("totalPage: ", data.data.totalPage);
      setTotalPage(data.data.totalPage);
    } catch (e) {
      console.log(e)
    }
  }, [clients, data]);

  useEffect(() => {
    try {
      if (clientDeleteState.type === 'DELETE_CLIENTS_SUCCESS') {
        dispatch(getClients(1, 15));
      }
    } catch (e) {
      console.log(e);
    } 
  }, [clientDeleteState]);

  const viewClient = (event, c) => {
    // event.preventDefault();
    dispatch(saveClients(c));
    history.push('clients-view');
  }

  const editClient = (event, c) => {
    dispatch(saveClients(c));
    history.push('clients-edit');
  }

  const onDeleteClient = (event, c) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'This data will be deleted',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33', 
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        dispatch(deleteClient(c.id))
      }
    })
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
    dispatch(getClients(selectedPage, perPage));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Client'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <Link
                    type={'button'}
                    className={'btn btn-sm btn-success card-title'}
                    to={'/home/clients-create'}>
                    Create Client
                  </Link>
                </div>

                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Client Name</th>
                        <th>PIC Name</th>
                        <th>Address</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ? 
                        <tr>
                          <td>Loading</td>
                        </tr>
                        :
                        clients && clients.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index+1}</td>
                              <td>{item.companyName}</td>
                              <td>{item.picName}</td>
                              <td>{item.address}</td>
                              <td>
                                <div className="btn-group">
                                  <button type="button" className="btn btn-primary" onClick={(event) => viewClient(event, item)}>View</button>
                                  <button type="button" className="btn btn-warning" onClick={(event) => editClient(event, item)}>Edit</button>
                                  <button type="button" className="btn btn-danger" onClick={(event) => onDeleteClient(event, item)}>Delete</button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
                {/* /.card-body */}

                <div className="card-footer clearfix">
                  <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabe={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pagination-sm m-0 float-right"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}

export default withRouter(Client)
