import Api from '../../api/api';
import { toast } from 'react-toastify';

export function saveProvinces(province) {
  return dispatch => {
    // delete province in localStorage
    localStorage.removeItem('province');

    // add province to localStorage
    localStorage.setItem('province', JSON.stringify(province));
    dispatch({
      type: "SAVE_PROVINCE",
      province: province
    });
    return;
  }
}

export function viewProvinces() {
  return dispatch => {
    // get province in localStorage
    var province = JSON.parse(localStorage.getItem('province'));
    dispatch({
      type: "VIEW_PROVINCE",
      province
    });
    return;
  }
}

export function initializeCreateProvinces() {
  return dispatch => {
    dispatch({
      type: "CREATE_PROVINCES"
    });
  }
}

export function initializeUpdateProvinces() {
  return dispatch => {
    dispatch({
      type: "UPDATE_PROVINCES"
    });
  }
}

export function createProvinces(forms) {
  return dispatch => {
    dispatch({
      type: "CREATE_PROVINCES"
    });

    var api = new Api();
    api.createProvinces(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: "CREATE_PROVINCES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "CREATE_PROVINCES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "CREATE_PROVINCES_ERROR",
            error
        });
        return;
      });
  }
}

export function updateProvinces(forms, provinceId) {
  return dispatch => {
    dispatch({
      type: "UPDATE_PROVINCES"
    });

    var api = new Api();
    api.updateProvinces(forms, provinceId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: "UPDATE_PROVINCES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "UPDATE_PROVINCES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "UPDATE_PROVINCES_ERROR",
            error
        });
        return;
      });
  }
}

export function deleteProvince(provinceId) {
  return dispatch => {
    dispatch({
      type: "DELETE_PROVINCES"
    });

    var api = new Api();
    api.deleteProvinces(provinceId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: "DELETE_PROVINCES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "DELETE_PROVINCES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "DELETE_PROVINCES_ERROR",
            error
        });
        return;
      });
  }
}

export function getProvinces(provinceId) {
  return dispatch => {
    dispatch({
      type: "GET_PROVINCES",
    });

    var api = new Api();
    api.getProvinces(provinceId)
      .then(response => {
        let data = response.data.data;
        if (response.status === 200) {
          dispatch({
            type: "GET_PROVINCES_SUCCESS",
            data,
          });
        } else {
          dispatch({
            type: "GET_PROVINCES_ERROR",
            data,
          });
        }
      })
      .catch(error => {
        dispatch({
            type: "GET_PROVINCES_ERROR",
            error
        });
    })
  }
}