import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { viewConsignees } from '../../redux/actions/consignee_action';
import ContentHeader from '../../components/content_header';

function ViewConsignee() {

  const consignee = useSelector(state => state.viewConsigneeReducer.consignee);
  const viewConsigneeState = useSelector(state => state.viewConsigneeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewConsignees());
  }, [viewConsignees])

  useEffect(() => {
    console.log(viewConsigneeState);
  }, [consignee, viewConsigneeState])

  return (
    <div className="content-wrapper">
      <ContentHeader title={'View Consignee'} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Company Name</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={consignee && consignee.companyName}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">PIC (Person In Charge) Name</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={consignee && consignee.picName}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={consignee && consignee.email}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                    <input 
                      className="form-control" 
                      readOnly
                      value={consignee && consignee.phoneNumber}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Address</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={consignee && consignee.address}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Province</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={consignee && consignee.province.provinceName}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">City</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={consignee && consignee.city.cityName}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(ViewConsignee)
