import React, { Component, useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory, withRouter} from 'react-router-dom';
import ContentHeader from '../../components/content_header';
import { getCities } from '../../redux/actions/city_action';
import Select from 'react-select';
import { updateRates, viewRates } from '../../redux/actions/rate_action';


function EditRate(props) {

  const cityState = useSelector(state => state.cityReducer);
  const updateRateState = useSelector(state => state.updateRateReducer);
  const viewRateState = useSelector(state => state.viewRateReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedOriginCityOption, setSelectedOriginCityOption] = useState(0);
  const [selectedDestinationCityOption, setSelectedDestinationCityOption] = useState(0);
  const [originCityOptions, setOriginCityOptions] = useState([]);
  const [destinationCityOptions, setDestinationCityOptions] = useState([]);

  // State form
  const [rates, setRates] = useState("");
  const [leadTime, setLeadTime] = useState("");
  const [selectedServices, setSelectedServices] = useState("");
  const [unit, setUnit] = useState("");
  const [servicesOptions, setServicesOptions] = useState([]);

  useEffect(() => {
    dispatch(getCities(""));
    dispatch(viewRates());
  }, [getCities, viewRates]);

  useEffect(() => {
    let sOps = [
      {
        value: "REGULER",
        label: "REGULER",
      },
      {
        value: "EKSPRES",
        label: "EKSPRES",
      },
      {
        value: "EKONOMI",
        label: "EKONOMI",
      },
    ];
    setServicesOptions(sOps);
  }, []);

  useEffect(() => {
    if (updateRateState.type === "UPDATE_RATES_SUCCESS") {
      history.goBack();
    }
  }, [updateRateState]);

  useEffect(() => {
    try {
      console.log(viewRateState);
      setRates(viewRateState.rate.rates);
      setLeadTime(viewRateState.rate.leadTime);

      // selected services
      let s = {
        value: viewRateState.rate.services,
        label: viewRateState.rate.services,
      }
      setSelectedServices(s);

      // selected origin city
      let oc = {
        value: viewRateState.rate.originCity.id,
        label: viewRateState.rate.originCity.cityName,
      }

      // selected destination city
      let dc = {
        value: viewRateState.rate.destinationCity.id,
        label: viewRateState.rate.destinationCity.cityName,
      }

      setSelectedOriginCityOption(oc);
      setSelectedDestinationCityOption(dc);

      setUnit(viewRateState.rate.unit);
    } catch (e) {
      console.log(e);
    }
  }, [viewRateState])

  useEffect(() => {
    try {
      if (cityState.type === 'GET_CITIES_SUCCESS') {
        let options = [];
        cityState.data && cityState.data.map((item, index) => {
          options.push({
            value: item.id,
            label: item.cityName
          })
        });
        setOriginCityOptions(options);
        setDestinationCityOptions(options);

        console.log(originCityOptions);
      }
    } catch (e) {
      console.log(e);
    }
  }, [cityState]);

  useEffect(() => {}, [originCityOptions, destinationCityOptions])

  const handleChangeOrigin = selectedOption => {
    setSelectedOriginCityOption(selectedOption);
  };

  const handleChangeDestination = selectedOption => {
    setSelectedDestinationCityOption(selectedOption);
  };

  const handleChangeService = selectedOption => {
    setSelectedServices(selectedOption);
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      rates: rates,
      services: selectedServices.value,
      leadTime: leadTime,
      unit: unit,
      originCityId: selectedOriginCityOption.value,
      destinationCityId: selectedDestinationCityOption.value
    }

    console.log(forms)

    dispatch(updateRates(forms, viewRateState.rate.id));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Update Rate'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Origin City</label>
                      <Select
                        value={selectedOriginCityOption}
                        onChange={handleChangeOrigin}
                        options={originCityOptions}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Destination City</label>
                      <Select
                        value={selectedDestinationCityOption}
                        onChange={handleChangeDestination}
                        options={destinationCityOptions}
                      />  
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Services</label>
                      <Select
                        value={selectedServices}
                        onChange={handleChangeService}
                        options={servicesOptions}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Rates</label>
                      <input 
                        className="form-control" 
                        value={rates}
                        placeholder="Enter Rates" 
                        onChange={(e) => setRates(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Lead Time</label>
                      <input 
                        className="form-control" 
                        value={leadTime}
                        placeholder="Enter Lead Time" 
                        onChange={(e) => setLeadTime(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Unit</label>
                      <input 
                        className="form-control" 
                        value={unit}
                        placeholder="Enter Unit" 
                        onChange={(e) => setUnit(e.target.value)}/>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(EditRate);