import Api from '../../api/api';
import { toast } from 'react-toastify';

export function saveUsers(user) {
  return dispatch => {
    // delete user in localStorage
    localStorage.removeItem('user');

    // add user to localStorage
    localStorage.setItem('user', JSON.stringify(user));
    dispatch({
      type: "SAVE_USER",
      user: user
    });
    return;
  }
}

export function viewUsers() {
  return dispatch => {
    // get user in localStorage
    var user = JSON.parse(localStorage.getItem('user'));
    dispatch({
      type: "VIEW_USER",
      user
    }); 
    return;
  }
}

export function initializeCreateUsers() {
  return dispatch => {
    dispatch({
      type: "CREATE_USERS"
    });
  }
}

export function initializeUpdateUsers() {
  return dispatch => {
    dispatch({
      type: "UPDATE_USERS"
    });
  }
}

export function createUsers(forms) {
  return dispatch => {
    dispatch({
      type: "CREATE_USERS"
    });

    var api = new Api();
    api.createUsers(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: "CREATE_USERS_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "CREATE_USERS_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "CREATE_USERS_ERROR",
            error
        });
        return;
      });
  }
}

export function updateUsers(forms, userId) {
  return dispatch => {
    dispatch({
      type: "UPDATE_USERS"
    });

    var api = new Api();
    api.updateUsers(forms, userId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: "UPDATE_USERS_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "UPDATE_USERS_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "UPDATE_USERS_ERROR",
            error
        });
        return;
      });
  }
}

export function deleteUser(userId) {
  return dispatch => {
    dispatch({
      type: "DELETE_USERS"
    });

    var api = new Api();
    api.deleteUsers(userId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: "DELETE_USERS_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "DELETE_USERS_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "DELETE_USERS_ERROR",
            error
        });
        return;
      });
  }
}

export function getUsers(page, perPage) {
  return dispatch => {
    dispatch({
      type: "GET_USERS",
      data: {},
      users: []
    });

    var api = new Api();
    api.getUsers(page, perPage)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "GET_USERS_SUCCESS",
            data: response.data,
            users: response.data.data.data
          });
          return;
        } else {
          dispatch({
            type: "GET_USERS_ERROR",
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "GET_USERS_ERROR",
            error
        });
        return;
      })
  }
}