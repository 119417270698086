import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { createCities, updateCities } from "../../redux/actions/city_action";
import ContentHeader from '../../components/content_header';
import Select from 'react-select';
import { getProvinces } from '../../redux/actions/province_action';

function EditCity () {

  const updateCityState = useSelector(state => state.updateCityReducer);
  const provinceState = useSelector(state => state.provinceReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [cityName, setCityName] = useState("");
  const [provinceOption, setProvinceOption] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [postalCode, setPostalCode] = useState(0);
  const [cityId, setCityId] = useState(0);

  useEffect(() => {
    dispatch(getProvinces(""));
  }, []);

  useEffect(() => {
    try {
      if (provinceState.type === 'GET_PROVINCES_SUCCESS') {
        let options = [];
        provinceState.data.forEach((item, index) => {
          options.push({
            value: item.id,
            label: item.provinceName
          });
        });
        setProvinceOption(options);
      }
    } catch (e) {
      console.log(e);
    }
  }, [provinceState]);

  useEffect(() => {
    try {
      setCityName(location.state.cityName);
      setSelectedProvince({
        value: location.state.province.id,
        label: location.state.province.provinceName
      });
      setPostalCode(location.state.postalCode);
      setCityId(location.state.id);
    } catch (e) {
      console.log(e);
    }
  }, [location])

  useEffect(() => {
    try {
      if (updateCityState.type === 'UPDATE_CITIES_SUCCESS') {
        history.goBack();
      }
    } catch (e) {
      console.log(e);
    }
  }, [updateCityState]);

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      cityName: cityName,
      provinceId: selectedProvince?.value,
      postalCode: parseInt(postalCode),
    };

    console.log(forms);

    dispatch(updateCities(forms, cityId));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Update Kota'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Provinsi</label>
                      <Select
                        value={selectedProvince}
                        onChange={setSelectedProvince}
                        options={provinceOption}
                      />  
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Nama Kota</label>
                      <input 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        placeholder="Enter Nama Kota" 
                        value={cityName}
                        onChange={(e) => setCityName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Kode Pos</label>
                      <input 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        placeholder="0" 
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(EditCity);