import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { viewRates } from '../../redux/actions/rate_action';
import ContentHeader from '../../components/content_header';
import { thousandSeparator } from '../../helper/thousand_separator';

function ViewRate() {

  const rate = useSelector(state => state.viewRateReducer.rate);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewRates());
  }, [viewRates])

  useEffect(() => {
    console.log(rate);
  }, [rate])

  return (
    <div className="content-wrapper">
      <ContentHeader title={'View Rate'} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Origin City</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={rate?.originCity?.cityName}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Destination City</label>
                    <input 
                      className="form-control" 
                      readOnly
                      value={rate?.destinationCity?.cityName}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Services</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={rate?.services}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Rates</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={rate && "Rp " + thousandSeparator(rate.rates)}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Lead Time</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={rate?.leadTime}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Unit</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={rate?.unit}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(ViewRate)
