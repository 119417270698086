import { citiesActionTypes } from "../actions/city_action";

let initialState = {
  loading: true,
  data: [],
  type: citiesActionTypes.GET_CITIES,
  error: {},
};

let initialCreateState = {
  loading: true,
  data: {},
  type: 'CREATE_CITIES',
  error: {},
};

let initialUpdateState = {
  loading: true,
  data: {},
  type: 'UPDATE_CITIES',
  error: {},
};

let initialDeleteState = {
  loading: true,
  data: {},
  type: 'DELETE_CITIES',
  error: {},
};

let viewCityReducerState = {
  type: 'VIEW_CITY',
  city: null
};

let saveCityReducerState = {
  type: 'SAVE_CITY',
  city: null
};

export const saveCityReducer = (state = saveCityReducerState, action) => {
  switch (action.type) {
    case 'SAVE_CITY':
      return {
        type: action.type,
        city: action.city
      }
    default:
      return state;
  }
}

export const viewCityReducer = (state = viewCityReducerState, action) => {
  switch (action.type) {
    case 'VIEW_CITY':
      let city = action.city;
      return {
        type: action.type,
        city,
      }
    default:
      return state;
  }
}

export const updateCityReducer = (state = initialUpdateState, action) => {
  switch (action.type) {
    case 'UPDATE_CITIES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'UPDATE_CITIES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'UPDATE_CITIES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const deleteCityReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case 'DELETE_CITIES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'DELETE_CITIES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'DELETE_CITIES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const createCityReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case 'CREATE_CITIES':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'CREATE_CITIES_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'CREATE_CITIES_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case citiesActionTypes.GET_CITIES:
      return {
        loading: false,
        type: action.type,
      };
    case citiesActionTypes.GET_CITIES_SUCCESS:
      return {
        loading: false, 
        data: action.data,
        type: action.type
      };
    case citiesActionTypes.GET_CITIES_ERROR:
      return {
        loading: false, 
        error: action.error,
        type: action.type
      };
    default:
      return state;
  }
};