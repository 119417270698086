import { filterTypes } from "../actions/filters_action";

let initialState = {
  type: filterTypes.UNINITIALIZED_FILTERS,
  filters: {},
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case filterTypes.UNINITIALIZED_FILTERS:
      return {
        type: action.type,
        filters: action.filters
      }
    case filterTypes.INITIALIZED_FILTERS:
      return {
        type: action.type,
        filters: action.filters
      }
    default:
      return state;
  }
}