import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers";
import * as serviceWorker from "./serviceWorker";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./style/plugins/fontawesome-free/css/all.min.css";
import "./style/plugins/fontawesome-free/css/all.min.css";
import "./style/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css";
import "./style/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
import "./style/plugins/jqvmap/jqvmap.min.css";
// import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
// import 'admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
// import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
// import 'admin-lte/plugins/jqvmap/jqvmap.min.css';
import "admin-lte/dist/css/adminlte.min.css";
import "./style/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
import "./style/plugins/daterangepicker/daterangepicker.css";
import "./style/plugins/summernote/summernote-bs4.css";
// import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
// import 'admin-lte/plugins/daterangepicker/daterangepicker.css';
// import 'admin-lte/plugins/summernote/summernote-bs4.css';
import jquery from "jquery";
import { BrowserRouter as Router } from "react-router-dom";
window.$ = window.jQuery = jquery;

// require('admin-lte/plugins/jquery/jquery.min.js');
// require('admin-lte/plugins/jquery-ui/jquery-ui.min.js');
// require('admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js');
// require('./style/plugins/jquery/jquery.min.js');
// require('./style/plugins/jquery-ui/jquery-ui.min.js');
// require('./style/plugins/bootstrap/js/bootstrap.bundle.min.js');
// require('./style/plugins/adminlte.min.js');
require("admin-lte/dist/js/adminlte.js");
// import 'admin-lte/plugins/chart.js/Chart.min.js';
// import 'admin-lte/plugins/sparklines/sparkline.js';
// import 'admin-lte/plugins/jqvmap/jquery.vmap.min.js';
// import 'admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js';
// import 'admin-lte/plugins/jquery-knob/jquery.knob.min.js';
// import 'admin-lte/plugins/moment/moment.min.js';
// import 'admin-lte/plugins/daterangepicker/daterangepicker.js';
// import 'admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js';
// import 'admin-lte/plugins/summernote/summernote-bs4.min.js';
// import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js';

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
