import Api from '../../api/api';
import { toast } from 'react-toastify';
import { LAST_PAGE_TRANSACTION } from '../../helper/constants';

export const transactionTypes = {
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_ERROR: "GET_TRANSACTIONS_ERROR",
  GET_TRANSACTIONS_REPORT: "GET_TRANSACTIONS_REPORT",
  GET_TRANSACTIONS_REPORT_SUCCESS: "GET_TRANSACTIONS_REPORT_SUCCESS",
  GET_TRANSACTIONS_REPORT_ERROR: "GET_TRANSACTIONS_REPORT_ERROR",
  CREATE_TRANSACTIONS: "CREATE_TRANSACTIONS",
  CREATE_TRANSACTIONS_SUCCESS: "CREATE_TRANSACTIONS_SUCCESS",
  CREATE_TRANSACTIONS_ERROR: "CREATE_TRANSACTIONS_ERROR",
  UPDATE_TRANSACTIONS: "UPDATE_TRANSACTIONS",
  UPDATE_TRANSACTIONS_SUCCESS: "UPDATE_TRANSACTIONS_SUCCESS",
  UPDATE_TRANSACTIONS_ERROR: "UPDATE_TRANSACTIONS_ERROR",
  DELETE_TRANSACTIONS: "DELETE_TRANSACTIONS",
  DELETE_TRANSACTIONS_SUCCESS: "DELETE_TRANSACTIONS_SUCCESS",
  DELETE_TRANSACTIONS_ERROR: "DELETE_TRANSACTIONS_ERROR",
  VIEW_TRANSACTION: "VIEW_TRANSACTION",
  SAVE_TRANSACTION: "SAVE_TRANSACTION"
}

export function saveTransactions(transaction) {
  return dispatch => {
    // delete transaction in localStorage
    localStorage.removeItem('transaction');

    // add transaction to localStorage
    localStorage.setItem('transaction', JSON.stringify(transaction));
    dispatch({
      type: transactionTypes.SAVE_TRANSACTION,
      transaction: transaction
    });
    return;
  }
}

export function saveLastPageTrx(lastPage) {
  return localStorage.setItem(LAST_PAGE_TRANSACTION, lastPage);
}

export function loadLastPageTrx() {
  console.log(localStorage.getItem(LAST_PAGE_TRANSACTION));
  return localStorage.getItem(LAST_PAGE_TRANSACTION);
}

export function viewTransactions() {
  return dispatch => {
    // get transaction in localStorage
    var transaction = JSON.parse(localStorage.getItem('transaction'));
    dispatch({
      type: transactionTypes.VIEW_TRANSACTION,
      transaction
    }); 
    return;
  }
}

export function initializeCreateTransactions() {
  return dispatch => {
    dispatch({
      type: transactionTypes.CREATE_TRANSACTIONS,
      loading: false
    });
  }
}

export function initializeUpdateTransactions() {
  return dispatch => {
    dispatch({
      type: transactionTypes.UPDATE_TRANSACTIONS,
      loading: false 
    });
  }
}

export function createTransactions(forms) {
  return dispatch => {
    dispatch({
      type: transactionTypes.CREATE_TRANSACTIONS,
      loading: true,
    });

    var api = new Api();
    api.createTransactions(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: transactionTypes.CREATE_TRANSACTIONS_SUCCESS,
            loading: false,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: transactionTypes.CREATE_TRANSACTIONS_ERROR,
            loading: false,
            data,
          });

          if (response.status === 400) {
            response.data.forEach((item, index) => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionTypes.CREATE_TRANSACTIONS_ERROR,
            loading: false,
            error
        });
        return;
      });
  }
}

export function updateTransactions(forms, transactionId) {
  return dispatch => {
    dispatch({
      type: transactionTypes.UPDATE_TRANSACTIONS,
      loading: true,
    });

    var api = new Api();
    api.updateTransactions(forms, transactionId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: transactionTypes.UPDATE_TRANSACTIONS_SUCCESS,
            loading: false,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: transactionTypes.UPDATE_TRANSACTIONS_ERROR,
            loading: false,
            data,
          });

          if (response.status === 400) {
            response.data.forEach((item, index) => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionTypes.UPDATE_TRANSACTIONS_ERROR,
            loading: false,
            error
        });
        return;
      });
  }
}

export function deleteTransaction(transactionId) {
  return dispatch => {
    dispatch({
      type: transactionTypes.DELETE_TRANSACTIONS
    });

    var api = new Api();
    api.deleteTransactions(transactionId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: transactionTypes.DELETE_TRANSACTIONS_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: transactionTypes.DELETE_TRANSACTIONS_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.forEach((item, index) => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionTypes.DELETE_TRANSACTIONS_ERROR,
            error
        });
        return;
      });
  }
}

export function getTransactions(page, perPage, filters) {
  return dispatch => {
    dispatch({
      type: transactionTypes.GET_TRANSACTIONS,
      data: {},
      transactions: []
    });

    var api = new Api();
    console.log("dsadas_filter", filters);
    api.getTransactions(page, perPage, filters)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: transactionTypes.GET_TRANSACTIONS_SUCCESS,
            data: response.data,
            transactions: response.data.data.data
          });
          return;
        } else {
          dispatch({
            type: transactionTypes.GET_TRANSACTIONS_ERROR,
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionTypes.GET_TRANSACTIONS_ERROR,
            error
        });
        return;
      })
  }
}

export function getTransactionsReport(page, perPage, filters) {
  return dispatch => {
    dispatch({
      type: transactionTypes.GET_TRANSACTIONS_REPORT,
      data: {},
      transactions: [],
    });

    var api = new Api();
    api.getTransactionsReport(page, perPage, filters)
      .then(response => {
        if (response.status === 200) {

          window.location.href = `${api.baseURL}/${response.data.data}`;

          dispatch({
            type: transactionTypes.GET_TRANSACTIONS_REPORT_SUCCESS,
            data: response.data,
            transactions: response.data.data.data
          });
          return;
        } else {
          dispatch({
            type: transactionTypes.GET_TRANSACTIONS_REPORT_ERROR,
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: transactionTypes.GET_TRANSACTIONS_REPORT_ERROR,
            error
        });
        return;
      })
  }
}