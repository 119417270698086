import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import ContentHeader from "../../components/content_header";
import { thousandSeparator } from "../../helper/thousand_separator";
import {
  viewTransactions,
  saveTransactions,
} from "../../redux/actions/transaction_action";
import {
  getTransactionDetails,
  saveTransactionDetails,
  initializeUpdateTransactionDetails,
  removeTransactionDetails,
  deleteTransactionDetail,
  transactionDetailsTypes,
} from "../../redux/actions/transaction_detail_action";
import Switch from "react-switch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { loadGetMe } from "../../redux/actions/auth_action";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";

function ViewTransaction() {
  const viewTransactionState = useSelector(
    (state) => state.viewTransactionReducer
  );
  const transactionDetailState = useSelector(
    (state) => state.transactionDetailReducer
  );
  const deleteTransactionDetailState = useSelector(
    (state) => state.deleteTransactionDetailReducer
  );
  const removeTransactionDetailState = useSelector(
    (state) => state.removeTransactionDetailReducer
  );
  const loadGetMeState = useSelector((state) => state.loadGetMeReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  // Form
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedOriginProvince, setSelectedOriginProvince] = useState("");
  const [selectedOriginCity, setSelectedOriginCity] = useState("");
  const [selectedDestinationProvince, setSelectedDestinationProvince] =
    useState("");
  const [selectedDestinationCity, setSelectedDestinationCity] = useState("");

  const [airWayBill, setAirWayBill] = useState("");
  const [originAddress, setOriginAddress] = useState("");
  const [originPic, setOriginPic] = useState("");
  const [originEmail, setOriginEmail] = useState("");
  const [originPhoneNumber, setOriginPhoneNumber] = useState("");
  const [consignee, setConsignee] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [destinationPic, setDestinationPic] = useState("");
  const [destinationEmail, setDestinationEmail] = useState("");
  const [destinationPhoneNumber, setDestinationPhoneNumber] = useState("");
  const [weight, setWeight] = useState(1);
  const [rate, setRate] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [packageDescription, setPackageDescription] = useState("");
  const [transactionDetail, setTransactionDetail] = useState([]);
  const [language, setLanguage] = useState("id_Id");
  const [checked, setChecked] = useState(false);
  const [transactionDetailFilters, setTransactionDetailFilters] = useState({});
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [delivered, setDelivered] = useState(false);

  // package description
  const [isVolumetrik, setIsVolumetrik] = useState(false);
  const [isPackingPrice, setIsPackingPrice] = useState(false);
  const [isInsurance, setIsInsurance] = useState(false);
  const [volumetrik, setVolumetrik] = useState(0);
  const [length, setLength] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [packingPrice, setPackingPrice] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [insurancePrice, setInsurancePrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [colly, setColly] = useState(0);
  const [pcs, setPcs] = useState(0);
  const [layanan, setLayanan] = useState("");
  const [officer, setOfficer] = useState("");
  const [receiverOfficer, setReceiverOfficer] = useState("");
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [chosenImage, setChosenImage] = useState();

  useEffect(() => {
    dispatch(viewTransactions());
    dispatch(initializeUpdateTransactionDetails());
    dispatch(removeTransactionDetails());
    dispatch(loadGetMe());
  }, [viewTransactions]);

  useEffect(() => {
    console.log("loadGetMeState", loadGetMeState);
  }, [loadGetMeState]);

  useEffect(() => {
    try {
      setSelectedClient(viewTransactionState.transaction.client.companyName);
      setSelectedOriginProvince(
        viewTransactionState.transaction.originProvince.provinceName
      );
      setSelectedOriginCity(
        viewTransactionState.transaction.originCity.cityName
      );
      setSelectedDestinationProvince(
        viewTransactionState.transaction.destinationProvince.provinceName
      );
      setSelectedDestinationCity(
        viewTransactionState.transaction.destinationCity.cityName
      );
      setAirWayBill(viewTransactionState.transaction.airWayBillNumber);
      setOriginAddress(viewTransactionState.transaction.originAddress);
      setOriginPic(viewTransactionState.transaction.originPic);
      setOriginEmail(viewTransactionState.transaction.originEmail);
      setOriginPhoneNumber(viewTransactionState.transaction.originPhoneNumber);
      setConsignee(viewTransactionState.transaction.consignee);
      setDestinationAddress(
        viewTransactionState.transaction.destinationAddress
      );
      setDestinationPic(viewTransactionState.transaction.consigneePic);
      setDestinationEmail(viewTransactionState.transaction.consigneeEmail);
      setDestinationPhoneNumber(
        viewTransactionState.transaction.consigneePhoneNumber
      );
      setWeight(viewTransactionState.transaction.weight);
      setRate(viewTransactionState.transaction.rate.rates);
      setTotalPrice(viewTransactionState.transaction.totalPrice);
      setPackageDescription(
        viewTransactionState.transaction.packageDescription
      );
      setTransactionDetail(viewTransactionState.transaction.transactionDetails);
      setDelivered(viewTransactionState.transaction.delivered);

      setTransactionDetailFilters({
        ...transactionDetailFilters,
        transactionId: viewTransactionState.transaction.id,
      });

      dispatch(
        getTransactionDetails(1, 300, {
          transactionId: viewTransactionState.transaction.id,
        })
      );

      let attributes = viewTransactionState.transaction.attributes;
      setIsVolumetrik(attributes.isVolumetrik);
      setIsInsurance(attributes.isInsurance);
      setIsPackingPrice(attributes.isPackingPrice);
      setLength(attributes.length);
      setWidth(attributes.width);
      setHeight(attributes.height);
      setVolumetrik(attributes.volumetrik);
      setPackingPrice(attributes.packingPrice);
      setInsurancePrice(attributes.insurancePrice);
      setPaymentMethod(attributes.paymentMethod);
      setColly(attributes.colly);
      setPcs(attributes.pcs);
      setLayanan(attributes.layanan);
      setOfficer(attributes.officer);
      setReceiverOfficer(attributes.receiverOfficer);
    } catch (e) {
      console.log(e);
    }
  }, [viewTransactionState]);

  useEffect(() => {
    console.log(transactionDetailFilters);
    setTimeout(() => {
      // dispatch(getTransactionDetails(1, 300, transactionDetailFilters))
    }, 100);
  }, [transactionDetailFilters]);

  useEffect(() => {
    try {
      if (transactionDetailState.type === "GET_TRANSACTION_DETAILS_SUCCESS") {
        setTransactionDetails(transactionDetailState.transactionDetails);
      }
    } catch (e) {
      console.log(e);
    }
  }, [transactionDetailState]);

  useEffect(() => {
    try {
      if (
        deleteTransactionDetailState.type ===
        transactionDetailsTypes.DELETE_TRANSACTION_DETAILS_SUCCESS
      ) {
        dispatch(getTransactionDetails(1, 300, transactionDetailFilters));
      }
    } catch (e) {
      console.log(e);
    }
  }, [deleteTransactionDetailState]);

  const handleLanguageCheck = () => {
    if (checked) {
      setChecked(false);
      setLanguage("id_Id");
    } else {
      setChecked(true);
      setLanguage("en_EN");
    }
  };

  const printReceipt = (event) => {
    // event.preventDefault();
    dispatch(saveTransactions(viewTransactionState.transaction));
    history.push("transactions-print-receipt");
  };

  const editTransactionDetail = (event, item) => {
    if (delivered && loadGetMeState?.data?.data?.role?.slug == "admin") {
      MySwal.fire({
        title: "Tidak bisa update data jika status pengiriman sudah berhasil.",
        text: "",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
      return;
    }
    dispatch(saveTransactionDetails(item));
    history.push("transaction-details-edit");
  };

  const onDeleteTransactionDetail = (event, item) => {
    if (delivered && loadGetMeState?.data?.data?.role?.slug == "admin") {
      MySwal.fire({
        title: "Tidak bisa update data jika status pengiriman sudah berhasil.",
        text: "",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
      return;
    } else {
      MySwal.fire({
        title: "Are you sure?",
        text: "This data will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          console.log("result", result.value);
          dispatch(deleteTransactionDetail(item.id));
        }
      });
      return;
    }
  };

  const editTransaction = (event) => {
    // event.preventDefault();
    dispatch(saveTransactions(viewTransactionState.transaction));
    history.push("transactions-edit");
  };

  return (
    <div className="content-wrapper">
      <ContentHeader title={"View Transaction"} />
      <section className="content">
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(event) => printReceipt(event)}
                  >
                    Receipt
                  </button>
                </div>
                {loadGetMeState?.data?.data?.role?.slug === "superadmin" ? (
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={(event) => editTransaction(event)}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ) : (
                  !delivered && (
                    <div className="col-md-6">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={(event) => editTransaction(event)}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div style={{ margin: 20 }}></div>
            </div>
            {/* Client */}
            <div className="col-md-4">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Client</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Client</label>
                    <input
                      className="form-control"
                      value={selectedClient}
                      placeholder={"Enter Address"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Province</label>
                    <input
                      className="form-control"
                      value={selectedOriginProvince}
                      placeholder={"Enter Address"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">City</label>
                    <input
                      className="form-control"
                      value={selectedOriginCity}
                      placeholder={"Enter Address"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Address</label>
                    <input
                      className="form-control"
                      value={originAddress}
                      placeholder={"Enter Address"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Shipper</label>
                    <input
                      className="form-control"
                      value={originPic}
                      placeholder={"Enter Shipper"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input
                      className="form-control"
                      value={originEmail}
                      placeholder={"Enter Email"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                    <input
                      className="form-control"
                      value={originPhoneNumber}
                      placeholder={"Enter Phone Number"}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Consignee */}
            <div className="col-md-4">
              <div className="card card-success">
                <div className="card-header">
                  <h3 className="card-title">Consignee</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Consignee</label>
                    <input
                      className="form-control"
                      value={consignee}
                      placeholder={"Enter Consignee"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Province</label>
                    <input
                      className="form-control"
                      value={selectedDestinationProvince}
                      placeholder={"Enter Address"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">City</label>
                    <input
                      className="form-control"
                      value={selectedDestinationCity}
                      placeholder={"Enter Address"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Address</label>
                    <input
                      className="form-control"
                      value={destinationAddress}
                      placeholder={"Enter Address"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Receiver</label>
                    <input
                      className="form-control"
                      value={destinationPic}
                      placeholder={"Enter Receiver"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input
                      className="form-control"
                      value={destinationEmail}
                      placeholder={"Enter Email"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                    <input
                      className="form-control"
                      value={destinationPhoneNumber}
                      placeholder={"Enter Phone Number"}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Package */}
            <div className="col-md-4">
              <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">Package</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Airway Bill</label>
                    <input
                      className="form-control"
                      value={airWayBill}
                      placeholder={"Enter Airway Bill"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Package Description
                    </label>
                    <input
                      className="form-control"
                      value={packageDescription}
                      placeholder={"Enter Package Description"}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Weight in KG</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        value={weight}
                        placeholder={"Enter Consignee"}
                        readOnly
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">KG</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isVolumetrik}
                        onChange={() => setIsVolumetrik(!isVolumetrik)}
                      />
                      <label className="form-check-label">Volumetrik</label>
                      {isVolumetrik && (
                        <div>
                          <div
                            className="input-group"
                            style={{ marginBottom: 10 }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">Length</span>
                            </div>
                            <input
                              className="form-control"
                              value={length}
                              readOnly
                              placeholder={"Enter Length"}
                              onChange={(e) => setLength(e.target.value)}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">cm</span>
                            </div>
                          </div>
                          <div
                            className="input-group"
                            style={{ marginBottom: 10 }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">Width</span>
                            </div>
                            <input
                              className="form-control"
                              value={width}
                              readOnly
                              placeholder={"Enter Width"}
                              onChange={(e) => setWidth(e.target.value)}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">cm</span>
                            </div>
                          </div>
                          <div
                            className="input-group"
                            style={{ marginBottom: 10 }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">Height</span>
                            </div>
                            <input
                              className="form-control"
                              value={height}
                              readOnly
                              placeholder={"Enter Height"}
                              onChange={(e) => setHeight(e.target.value)}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">cm</span>
                            </div>
                          </div>
                          <div
                            className="input-group"
                            style={{ marginBottom: 10 }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                Volumetrik
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={thousandSeparator(Math.ceil(volumetrik))}
                              placeholder={"Enter Height"}
                              readOnly
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">KG</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        checked={isPackingPrice}
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => setIsPackingPrice(!isPackingPrice)}
                      />
                      <label className="form-check-label">Packing Price</label>
                      {isPackingPrice && (
                        <div>
                          <div
                            className="input-group"
                            style={{ marginBottom: 10 }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rp</span>
                            </div>
                            <input
                              className="form-control"
                              value={packingPrice}
                              readOnly
                              placeholder={"Enter Width"}
                              onChange={(e) => setPackingPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        checked={isInsurance}
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => setIsInsurance(!isInsurance)}
                      />
                      <label className="form-check-label">Insurance</label>
                      {isInsurance && (
                        <div>
                          <label className="form-check-label">Item Price</label>
                          <div
                            className="input-group"
                            style={{ marginBottom: 10 }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rp</span>
                            </div>
                            <input
                              className="form-control"
                              value={itemPrice}
                              readOnly
                              placeholder={"Enter Item Price"}
                              onChange={(e) => setItemPrice(e.target.value)}
                            />
                          </div>
                          <label className="form-check-label">
                            Insurance Price
                          </label>
                          <div
                            className="input-group"
                            style={{ marginBottom: 10 }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rp</span>
                            </div>
                            <input
                              className="form-control"
                              value={insurancePrice}
                              readOnly
                              placeholder={"Enter Insurance Price"}
                              onChange={(e) =>
                                setInsurancePrice(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Colly</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        value={colly}
                        placeholder={"Enter Colly"}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">PCS</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        value={pcs}
                        placeholder={"Enter PCS"}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Payment Method</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        value={paymentMethod}
                        placeholder={"Enter PCS"}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Layanan</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        value={layanan}
                        placeholder={"Enter Layanan"}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Petugas SUC</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        value={officer}
                        placeholder={"Enter Petugas SUC"}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Penerima</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        value={receiverOfficer}
                        placeholder={"Enter Penerima"}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Rate / KG</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Rp</span>
                      </div>
                      <input
                        className="form-control"
                        value={thousandSeparator(rate)}
                        placeholder={"Enter Rate"}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Total Price</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Rp</span>
                      </div>
                      <input
                        className="form-control"
                        value={thousandSeparator(totalPrice)}
                        placeholder={"Enter Total Price"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Transaction Detail */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Detail</h3>
                  <div className="card-tools">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {"Indonesia"}
                      <Switch
                        onChange={handleLanguageCheck}
                        checked={checked}
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                      {" English"}
                    </div>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Status</th>
                        <th>Location</th>
                        <th>Description</th>
                        <th>Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {}
                      {transactionDetails &&
                        transactionDetails.map((item, index) => {
                          let newText = JSON.parse(item.status.text);
                          let attributes = JSON.parse(item.attributes);
                          // let attributes = JSON.parse(item.attributes);
                          let createdAt = new Date(parseInt(item.createdAt));
                          let cdt = new Date(
                            parseInt(attributes.customDateTime)
                          ).toLocaleString("default", { hour12: false });
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>
                                {language == "id_Id"
                                  ? newText["id_Id"]
                                  : newText["en_EN"]}
                              </td>
                              <td>{attributes.location}</td>
                              <td>
                                {attributes.subDetail &&
                                  `[${attributes.subDetail}]`}
                                {attributes.extra ? <br /> : ""}
                                {attributes.extra != ""
                                  ? `[${attributes.extra}]`
                                  : ""}
                                {attributes.extra ? <br /> : ""}
                                {attributes.hasOwnProperty("sortingCenter") &&
                                  attributes.sortingCenter &&
                                  `${attributes.sortingCenter} : `}{" "}
                                {attributes.hasOwnProperty("sortingCenterAt") &&
                                  attributes.sortingCenterAt}
                                {attributes.hasOwnProperty("sortingCenter") &
                                (attributes.sortingCenter != "") ? (
                                  <br />
                                ) : (
                                  ""
                                )}
                                {`${item.description} `}
                                <br />
                                {item.status?.id ===
                                "df3a2224-b276-495d-b3a6-270fd95c9753" ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOpenImagePreview(true);
                                      setChosenImage(attributes.image);
                                    }}
                                  >
                                    Lihat Gambar
                                  </button>
                                ) : null}
                              </td>
                              {/* <td>{createdAt.toLocaleString('default', {hour12: false})}</td> */}
                              <td>{cdt}</td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-warning"
                                    onClick={(event) =>
                                      editTransactionDetail(event, item)
                                    }
                                  >
                                    Update
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={(event) =>
                                      onDeleteTransactionDetail(event, item)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {/* /.card-body */}
              </div>
            </div>
          </div>
        </form>
      </section>

      <Lightbox
        open={openImagePreview}
        close={() => setOpenImagePreview(false)}
        slides={[
          {
            src: `https://storage.suc.co.id/assets/${chosenImage}`,
          },
        ]}
      />
    </div>
  );
}

export default withRouter(ViewTransaction);
