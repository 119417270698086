import { transactionTypes } from "../actions/transaction_action";

let initialState = {
  loading: false,
  data: {},
  type: transactionTypes.GET_TRANSACTIONS,
  error: {},
  transactions: []
};

let initialStateReport = {
  loading: false,
  data: {},
  type: transactionTypes.GET_TRANSACTIONS_REPORT,
  error: {},
  transactions: []
};
  
let initialCreateState = {
  loading: false,
  data: {},
  type: transactionTypes.CREATE_TRANSACTIONS,
  error: {},
};

let initialUpdateState = {
  loading: false,
  data: {},
  type: transactionTypes.UPDATE_TRANSACTIONS,
  error: {},
};

let initialDeleteState = {
  loading: false,
  data: {},
  type: transactionTypes.DELETE_TRANSACTIONS,
  error: {},
};

let viewTransactionReducerState = {
  type: transactionTypes.VIEW_TRANSACTION,
  transaction: null
};

let saveTransactionReducerState = {
  type: transactionTypes.SAVE_TRANSACTION,
  transaction: null
};
  
export const saveTransactionReducer = (state = saveTransactionReducerState, action) => {
  switch (action.type) {
    case transactionTypes.SAVE_TRANSACTION:
      return {
        type: action.type,
        transaction: action.transaction
      }
    default:
      return state;
  }
}
  
export const viewTransactionReducer = (state = viewTransactionReducerState, action) => {
  switch (action.type) {
    case transactionTypes.VIEW_TRANSACTION:
      let transaction = action.transaction;
      return {
        type: action.type,
        transaction,
      }
    default:
      return state;
  }
}
  
export const updateTransactionReducer = (state = initialUpdateState, action) => {
  switch (action.type) {
    case transactionTypes.UPDATE_TRANSACTIONS:
      return {
        loading: action.loading,
        type: action.type,
      }
    case transactionTypes.UPDATE_TRANSACTIONS_SUCCESS:
      return {
        type: action.type,
        loading: action.loading,
        data: action.data
      }
    case transactionTypes.UPDATE_TRANSACTIONS_ERROR:
      return {
        type: action.type,
        loading: action.loading,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const deleteTransactionReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case transactionTypes.DELETE_TRANSACTIONS:
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case transactionTypes.DELETE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case transactionTypes.DELETE_TRANSACTIONS_ERROR:
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const createTransactionReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case transactionTypes.CREATE_TRANSACTIONS:
      return {
        loading: action.loading,
        type: action.type,
      }
    case transactionTypes.CREATE_TRANSACTIONS_SUCCESS:
      return {
        type: action.type,
        loading: action.loading,
        data: action.data
      }
    case transactionTypes.CREATE_TRANSACTIONS_ERROR:
      return {
        type: action.type,
        loading: action.loading,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case transactionTypes.GET_TRANSACTIONS:
      return {
        type: action.type,
        loading: action.loading,
      };
    case transactionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        type: action.type,
        loading: action.loading, 
        data: action.data,
        transactions: action.transactions
      };
    case transactionTypes.GET_TRANSACTIONS_ERROR:
      return {
        type: action.type,
        loading: action.loading, 
        error: action.error
      };
    default:
      return state;
  }
};

export const transactionReportReducer = (state = initialStateReport, action) => {
  switch (action.type) {
    case transactionTypes.GET_TRANSACTIONS_REPORT:
      return {
        type: action.type,
        loading: action.loading,
      };
    case transactionTypes.GET_TRANSACTIONS_REPORT_SUCCESS:
      return {
        type: action.type,
        loading: action.loading, 
        data: action.data,
        transactions: action.transactions
      };
    case transactionTypes.GET_TRANSACTIONS_REPORT_ERROR:
      return {
        type: action.type,
        loading: action.loading, 
        error: action.error
      };
    default:
      return state;
  }
};