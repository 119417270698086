import Api from '../../api/api';
import { toast } from 'react-toastify';

export const filesTypes = {
  CREATE_FILES_INITIALIZED: "CREATE_FILES_INITIALIZED",
  CREATE_FILES: "CREATE_FILES",
  CREATE_FILES_SUCCESS: "CREATE_FILES_SUCCESS",
  CREATE_FILES_ERROR: "CREATE_FILES_ERROR",
  CREATE_FILES_UPLOADING: "CREATE_FILES_UPLOADING"
};

export function uploadFilesInitialized() {
  return dispatch => {
    dispatch({
      type: filesTypes.CREATE_FILES_INITIALIZED,
      loading: false,
    });
  }
}

export function uploadFiles(files) {
  return dispatch => {
    dispatch({
      type: filesTypes.CREATE_FILES,
      loading: true,
    });

    dispatch({
      type: filesTypes.CREATE_FILES_UPLOADING,
      loading: true,
    });

    var api = new Api();
    api.uploadFiles(files)
      .then(response => {
        let data = response.data;
        console.log(data);
        if (response.status === 201) {
          dispatch({
            type: filesTypes.CREATE_FILES_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: filesTypes.CREATE_FILES_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.forEach(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            });
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
          type: filesTypes.CREATE_FILES_ERROR,
          error
        });
        return;
      });
  }
}