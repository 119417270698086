import { transactionDetailsTypes } from "../actions/transaction_detail_action";

let initialState = {
    loading: true,
    data: {},
    type: transactionDetailsTypes.GET_TRANSACTION_DETAILS,
    error: {},
    transactionDetails: []
  };
  
  let initialCreateState = {
    loading: true,
    data: {},
    type: transactionDetailsTypes.CREATE_TRANSACTION_DETAILS,
    error: {},
  };
  
  let initialUpdateState = {
    loading: true,
    data: {},
    type: transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS,
    error: {},
  };
  
  let initialDeleteState = {
    loading: true,
    data: {},
    type: transactionDetailsTypes.DELETE_TRANSACTION_DETAILS,
    error: {},
  };
  
  let viewtransactionDetailReducerState = {
    type: transactionDetailsTypes.VIEW_TRANSACTION_DETAIL,
    transactionDetail: null
  };
  
  let savetransactionDetailReducerState = {
    type: transactionDetailsTypes.SAVE_TRANSACTION_DETAIL,
    transactionDetail: null
  };

  let removeTransactionDetailReducerState = {
    type: transactionDetailsTypes.REMOVE_TRANSACTION_DETAIL,
    transactionDetail: null
  }

  export const removeTransactionDetailReducer = (state = removeTransactionDetailReducerState, action) => {
    switch (action.type) {
      case transactionDetailsTypes.REMOVE_TRANSACTION_DETAIL:
        return {
          type: action.type,
          transactionDetail: action.transactionDetail
        };
      default:
        return state;
    }
  }
  
  export const saveTransactionDetailReducer = (state = savetransactionDetailReducerState, action) => {
    switch (action.type) {
      case transactionDetailsTypes.SAVE_TRANSACTION_DETAIL:
        return {
          type: action.type,
          transactionDetail: action.transactionDetail
        }
      default:
        return state;
    }
  }
  
  export const viewTransactionDetailReducer = (state = viewtransactionDetailReducerState, action) => {
    switch (action.type) {
      case transactionDetailsTypes.VIEW_TRANSACTION_DETAIL:
        let transactionDetail = action.transactionDetail;
        return {
          type: action.type,
          transactionDetail,
        }
      default:
        return state;
    }
  }
  
  export const updateTransactionDetailReducer = (state = initialUpdateState, action) => {
    switch (action.type) {
      case transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS:
        return {
          ...state,
          type: action.type,
          loading: true
        }
      case transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS_SUCCESS:
        return {
          ...state,
          type: action.type,
          loading: false,
          data: action.data
        }
      case transactionDetailsTypes.UPDATE_TRANSACTION_DETAILS_ERROR:
        return {
          ...state,
          type: action.type,
          loading: false,
          error: action.error,
          data: action.data,
        }
      default:
        return state;
    }
  }
  
  export const deleteTransactionDetailReducer = (state = initialDeleteState, action) => {
    switch (action.type) {
      case transactionDetailsTypes.DELETE_TRANSACTION_DETAILS:
        return {
          ...state,
          type: action.type,
          loading: true
        }
      case transactionDetailsTypes.DELETE_TRANSACTION_DETAILS_SUCCESS:
        return {
          ...state,
          type: action.type,
          loading: false,
          data: action.data
        }
      case transactionDetailsTypes.DELETE_TRANSACTION_DETAILS_ERROR:
        return {
          ...state,
          type: action.type,
          loading: false,
          error: action.error,
          data: action.data,
        }
      default:
        return state;
    }
  }
  
  export const createTransactionDetailReducer = (state = initialCreateState, action) => {
    switch (action.type) {
      case transactionDetailsTypes.CREATE_TRANSACTION_DETAILS:
        return {
          ...state,
          type: action.type,
          loading: true
        }
      case transactionDetailsTypes.CREATE_TRANSACTION_DETAILS_SUCCESS:
        return {
          ...state,
          type: action.type,
          loading: false,
          data: action.data
        }
      case transactionDetailsTypes.CREATE_TRANSACTION_DETAILS_ERROR:
        return {
          ...state,
          type: action.type,
          loading: false,
          error: action.error,
          data: action.data,
        }
      default:
        return state;
    }
  }
  
  export const transactionDetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case transactionDetailsTypes.GET_TRANSACTION_DETAILS:   
        return {
          type: action.type,
          loading: true,
        };
      case transactionDetailsTypes.GET_TRANSACTION_DETAILS_SUCCESS:
        return {
          type: action.type,
          loading: false, 
          data: action.data,
          transactionDetails: action.transactionDetails
        };
      case transactionDetailsTypes.GET_TRANSACTION_DETAILS_ERROR:
        return {
          type: action.type,
          loading: false, 
          error: action.error
        };
      default:
        return state;
    }
  };