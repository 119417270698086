import Api from '../../api/api';
import { toast } from 'react-toastify';

export const citiesActionTypes = {
  GET_CITIES: 'GET_CITIES',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
  GET_CITIES_ERROR: 'GET_CITIES_ERROR',
}

export function saveCities(city) {
  return dispatch => {
    // delete city in localStorage
    localStorage.removeItem('city');

    // add city to localStorage
    localStorage.setItem('city', JSON.stringify(city));
    dispatch({
      type: "SAVE_CITY",
      city: city
    });
    return;
  }
}

export function viewCities() {
  return dispatch => {
    // get city in localStorage
    var city = JSON.parse(localStorage.getItem('city'));
    dispatch({
      type: "VIEW_CITY",
      city
    });
    return;
  }
}

export function initializeCreateCities() {
  return dispatch => {
    dispatch({
      type: "CREATE_CITIES"
    });
  }
}

export function initializeUpdateCities() {
  return dispatch => {
    dispatch({
      type: "UPDATE_CITIES"
    });
  }
}

export function createCities(forms) {
  return dispatch => {
    dispatch({
      type: "CREATE_CITIES"
    });

    var api = new Api();
    api.createCities(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: "CREATE_CITIES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "CREATE_CITIES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "CREATE_CITIES_ERROR",
            error
        });
        return;
      });
  }
}

export function updateCities(forms, cityId) {
  return dispatch => {
    dispatch({
      type: "UPDATE_CITIES"
    });

    var api = new Api();
    api.updateCities(forms, cityId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: "UPDATE_CITIES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "UPDATE_CITIES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "UPDATE_CITIES_ERROR",
            error
        });
        return;
      });
  }
}

export function deleteCity(cityId) {
  return dispatch => {
    dispatch({
      type: "DELETE_CITIES"
    });

    var api = new Api();
    api.deleteCities(cityId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: "DELETE_CITIES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "DELETE_CITIES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "DELETE_CITIES_ERROR",
            error
        });
        return;
      });
  }
}

export function getCities(cityId) {
  return dispatch => {
    dispatch({
      type: "GET_CITIES",
    });

    var api = new Api();
    api.getCities(cityId)
      .then(response => {
        let data = response.data.data;
        if (response.status === 200) {
          dispatch({
            type: "GET_CITIES_SUCCESS",
            data,
          });
        } else {
          dispatch({
            type: "GET_CITIES_ERROR",
            data,
          });
        }
      })
      .catch(error => {
        dispatch({
            type: "GET_CITIES_ERROR",
            error
        });
    })
  }
}

export function getCitiesAll() {
  return dispatch => {
    dispatch({
      type: citiesActionTypes.GET_CITIES,
    });

    var api = new Api();
    api.getCitiesAll()
      .then(response => {
        let data = response.data.data;
        if (response.status === 200) {
          dispatch({
            type: citiesActionTypes.GET_CITIES_SUCCESS,
            data,
          });
          return;
        } else {
          dispatch({
            type: citiesActionTypes.GET_CITIES_ERROR,
            data,
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: citiesActionTypes.GET_CITIES_ERROR,
            error
        });
        return;
    })
  }
}