import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { viewClients } from "../../redux/actions/client_action";
import ContentHeader from "../../components/content_header";

function ViewClient() {
  const client = useSelector((state) => state.viewClientReducer.client);
  const viewClientState = useSelector((state) => state.viewClientReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewClients());
  }, [viewClients]);

  useEffect(() => {
    console.log(viewClientState);
  }, [client, viewClientState]);

  return (
    <div className="content-wrapper">
      <ContentHeader title={"View Client"} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Company Name</label>
                    <input
                      className="form-control"
                      readOnly
                      value={client && client.companyName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      PIC (Person In Charge) Name
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      value={client && client.picName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input
                      className="form-control"
                      readOnly
                      value={client && client.email}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                    <input
                      className="form-control"
                      readOnly
                      value={client && client.phoneNumber}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Address</label>
                    <input
                      className="form-control"
                      readOnly
                      value={client && client.address}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">API KEY</label>
                    <input
                      className="form-control"
                      readOnly
                      value={client && client.apiKey}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Province</label>
                    <input
                      className="form-control"
                      readOnly
                      value={client && client.province.provinceName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">City</label>
                    <input
                      className="form-control"
                      readOnly
                      value={client && client.city.cityName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(ViewClient);
