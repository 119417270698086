import Api from '../../api/api';
import { toast } from 'react-toastify';
import consignee from '../../pages/consignee/consignee';

export const consigneeTypes = {
  SAVE_CONSIGNEE             : "SAVE_CONSIGNEE",
  VIEW_CONSIGNEE             : "VIEW_CONSIGNEE",
  CREATE_CONSIGNEES          : "CREATE_CONSIGNEES",
  UPDATE_CONSIGNEES          : "UPDATE_CONSIGNEES",
  CREATE_CONSIGNEES_SUCCESS  : "CREATE_CONSIGNEES_SUCCESS",
  CREATE_CONSIGNEES_ERROR    : "CREATE_CONSIGNEES_ERROR",
  UPDATE_CONSIGNEES_SUCCESS  : "UPDATE_CONSIGNEES_SUCCESS",
  UPDATE_CONSIGNEES_ERROR    : "UPDATE_CONSIGNEES_ERROR",
  DELETE_CONSIGNEES          : "DELETE_CONSIGNEES",
  DELETE_CONSIGNEES_SUCCESS  : "DELETE_CONSIGNEES_SUCCESS",
  DELETE_CONSIGNEES_ERROR    : "DELETE_CONSIGNEES_ERROR",
  GET_CONSIGNEES             : "GET_CONSIGNEES",
  GET_CONSIGNEES_SUCCESS     : "GET_CONSIGNEES_SUCCESS",
  GET_CONSIGNEES_ERROR       : "GET_CONSIGNEES_ERROR",
}

export function saveConsignees(consignee) {
  return dispatch => {
    // delete consignee in localStorage
    localStorage.removeItem('consignee');

    // add consignee to localStorage
    localStorage.setItem('consignee', JSON.stringify(consignee));
    dispatch({
      type: consigneeTypes.SAVE_CONSIGNEE,
      consignee: consignee
    });
    return;
  }
}

export function viewConsignees() {
  return dispatch => {
    // get consignee in localStorage
    var consignee = JSON.parse(localStorage.getItem('consignee'));
    dispatch({
      type: consigneeTypes.VIEW_CONSIGNEE,
      consignee
    });
    return;
  }
}

export function initializeCreateConsignees() {
  return dispatch => {
    dispatch({
      type: consigneeTypes.CREATE_CONSIGNEES
    });
  }
}

export function initializeUpdateConsignees() {
  return dispatch => {
    dispatch({
      type: consigneeTypes.UPDATE_CONSIGNEES
    });
  }
}

export function createConsignees(forms) {
  return dispatch => {
    dispatch({
      type: consigneeTypes.CREATE_CONSIGNEES
    });

    var api = new Api();
    api.createConsignees(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: consigneeTypes.CREATE_CONSIGNEES_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: consigneeTypes.CREATE_CONSIGNEES_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.forEach((item, index) => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: consigneeTypes.CREATE_CONSIGNEES_ERROR,
            error
        });
        return;
      });
  }
}

export function updateConsignees(forms, consigneeId) {
  return dispatch => {
    dispatch({
      type: consigneeTypes.UPDATE_CONSIGNEES
    });

    var api = new Api();
    api.updateConsignees(forms, consigneeId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: consigneeTypes.UPDATE_CONSIGNEES_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: consigneeTypes.UPDATE_CONSIGNEES_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.forEach((item, index) => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: consigneeTypes.UPDATE_CONSIGNEES_ERROR,
            error
        });
        return;
      });
  }
}

export function deleteConsignee(consigneeId) {
  return dispatch => {
    dispatch({
      type: consigneeTypes.DELETE_CONSIGNEES
    });

    var api = new Api();
    api.deleteConsignees(consigneeId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: consigneeTypes.DELETE_CONSIGNEES_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: consigneeTypes.DELETE_CONSIGNEES_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.forEach((item, index) => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: consigneeTypes.DELETE_CONSIGNEES_ERROR,
            error
        });
        return;
      });
  }
}

export function getConsignees(page, perPage, filters) {
  return dispatch => {
    dispatch({
      type: consigneeTypes.GET_CONSIGNEES
    });

    var api = new Api();
    api.getConsignees(page, perPage, filters)
      .then(response => {
        if (response.status === 200) {

          let consigneeTransform = []
          response.data.data.data.forEach((item, index) => {
            consigneeTransform.push({
              id: item.id,
              name: item.companyName
            })
          });

          dispatch({
            type: consigneeTypes.GET_CONSIGNEES_SUCCESS,
            data: response.data,
            consignees: response.data.data.data,
            consigneeTransform: consigneeTransform
          });
          return;
        } else {
          dispatch({
            type: consigneeTypes.GET_CONSIGNEES_ERROR,
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: consigneeTypes.GET_CONSIGNEES_ERROR,
            error
        });
        return;
      })
  }
}