import React, { useState, useEffect} from 'react';
import { connect, useSelector, useDispatch } from 'react-redux'
import { withRouter, useHistory } from "react-router-dom";
import ContentHeader from '../../components/content_header';
import { getClients } from '../../redux/actions/client_action';
import { getProvinces } from '../../redux/actions/province_action';
import Select from 'react-select';
import { checkRates } from '../../redux/actions/rate_action';
import NumberFormat from 'react-number-format';
import { thousandSeparator } from '../../helper/thousand_separator';
import { createTransactions, saveTransactions } from '../../redux/actions/transaction_action';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { calculateVolumetrik } from '../../helper/volumetrik';
import { getConsignees, saveConsignees, deleteConsignee, initializeCreateConsignees, initializeUpdateConsignees, consigneeTypes } from '../../redux/actions/consignee_action';
import { Typeahead, withAsync, useAsync } from 'react-bootstrap-typeahead';
import Api from '../../api/api';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function CreateTransaction() {

    const consigneeState = useSelector(state => state.consigneeReducer);
    const clientState = useSelector(state => state.clientReducer);
    const provinceState = useSelector(state => state.provinceReducer);
    const checkRateState = useSelector(state => state.checkRateReducer);
    const createTransactionState = useSelector(state => state.createTransactionReducer);
    const dispatch = useDispatch();
    const history = useHistory()
    const MySwal = withReactContent(Swal);
    const AsyncTypeahead = withAsync(Typeahead);
    
    // Origin
    const [selectedClient, setSelectedClient] = useState(0);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedOriginProvince, setSelectedOriginProvince] = useState(0);
    const [originProvinceOptions, setOriginProvinceOptions] = useState([]);
    const [selectedOriginCity, setSelectedOriginCity] = useState(0);
    const [originCityOptions, setOriginCityOptions] = useState([]);

    // Destination
    const [selectedDestinationProvince, setSelectedDestinationProvince] = useState(0);
    const [destinationProvinceOptions, setDestinationProvinceOptions] = useState([]);
    const [selectedDestinationCity, setSelectedDestinationCity] = useState(0);
    const [destinationCityOptions, setDestinationCityOptions] = useState([]);

    // Form
    const [airWayBill, setAirWayBill] = useState("");
    const [originAddress, setOriginAddress] = useState("");
    const [originPic, setOriginPic] = useState("");
    const [originEmail, setOriginEmail] = useState("");
    const [originPhoneNumber, setOriginPhoneNumber] = useState("");
    const [consignee, setConsignee] = useState("");
    const [destinationAddress, setDestinationAddress] = useState("");
    const [destinationPic, setDestinationPic] = useState("");
    const [destinationEmail, setDestinationEmail] = useState("");
    const [destinationPhoneNumber, setDestinationPhoneNumber] = useState("");
    const [weight, setWeight] = useState(1);
    const [rate, setRate] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [packageDescription, setPackageDescription] = useState("");

    // package description
    const [isVolumetrik, setIsVolumetrik] = useState(false);
    const [isPackingPrice, setIsPackingPrice] = useState(false);
    const [isInsurance, setIsInsurance] = useState(false);
    const [volumetrik, setVolumetrik] = useState(0);
    const [length, setLength] = useState(0);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [packingPrice, setPackingPrice] = useState(0);
    const [itemPrice, setItemPrice] = useState(0);
    const [insurancePrice, setInsurancePrice] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState({
        value: "TUNAI",
        label: "TUNAI"
    });
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([
        {
            value: "TUNAI",
            label: "TUNAI" 
        },
        {
            value: "KREDIT",
            label: "KREDIT" 
        }
    ]);
    const [colly, setColly] = useState(0);
    const [pcs, setPcs] = useState(0);
    const [layanan, setLayanan] = useState({
        value: "UDARA",
        label: "UDARA"
    });
    const [layananOptions, setLayananOptions] = useState([
        {
            value: "UDARA",
            label: "UDARA" 
        },
        {
            value: "DARAT",
            label: "DARAT" 
        },
        {
            value: "LAUT",
            label: "LAUT" 
        }
    ]);
    const [officer, setOfficer] = useState("");
    const [receiverOfficer, setReceiverOfficer] = useState("");
    const [servicesOptions, setServicesOptions] = useState([]);
    const [selectedServices, setSelectedServices] = useState("");
    const [autoOrManual, setAutoOrManual] = useState(true);
    const [dateAutoOrManual, setDateAutoOrManual] = useState(true);
    const [customDateTime, setCustomDateTime] = useState(new Date());

    // Consignee Transform
    const [consigneeTransform, setConsigneeTransform] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      try {
        if (consigneeState.type === consigneeTypes.GET_CONSIGNEES_SUCCESS) {
          console.log("dsadsa_", consigneeState.consigneeTransform);
          setConsigneeTransform(consigneeState.consignees);
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e)
      }
    }, [consigneeState])

    useEffect(() => {
      if (dateAutoOrManual) {
        setCustomDateTime(new Date());
      }
    }, [dateAutoOrManual]);

    useEffect(() => {
        let sOps = [
          {
            value: "REGULER",
            label: "REGULER",
          },
          {
            value: "EKSPRES",
            label: "EKSPRES",
          },
          {
            value: "EKONOMI",
            label: "EKONOMI",
          },
        ];
        setServicesOptions(sOps);

        setSelectedServices({
            value: "REGULER",
            label: "REGULER",
        });
      }, []);

    useEffect(() => {
        if (!autoOrManual) {
            setAirWayBill("");
        } else {
            // Generate AirwayBill
            let dateTime = Math.floor(Date.now() / 1000);
            let awb = `${selectedOriginCity?.label?.substring(0,3)}-${dateTime?.toString().substring(2,10)}`;
            setAirWayBill(awb);
        }
    }, [autoOrManual])

    useEffect(() => {
        if (!isVolumetrik) {
            setVolumetrik(0); 
            setLength(0); 
            setWidth(0); 
            setHeight(0);
        }
        if (!isPackingPrice) {
            setPackingPrice(0);
        }
        if (!isInsurance) {
            setInsurancePrice(0); 
            setItemPrice(0);
        }
    }, [isVolumetrik, isPackingPrice, isInsurance]);

    useEffect(() => {
      dispatch(getConsignees(1, 300, {companyName: ""}));
        dispatch(getClients(1, 300));
    }, [getClients, getProvinces])

    useEffect(() => {
        dispatch(getProvinces(""));
    }, [getProvinces])

    useEffect(() => {
        try {
            if (clientState.type === 'GET_CLIENTS_SUCCESS') {
                let options = [];
                clientState.clients.map((item, index) => {
                    options.push({
                        value: item.id,
                        label: item.companyName
                    });
                });
                setClientOptions(options);
            }
        } catch (e) {
            console.log(e);
        }
    }, [clientState]);

    useEffect(() => {
        try {
            if (provinceState.type === 'GET_PROVINCES_SUCCESS') {
              let options = [];
              provinceState.data.map((item, index) => {
                options.push({
                  value: item.id,
                  label: item.provinceName
                })
              });
              setOriginProvinceOptions(options);
              setDestinationProvinceOptions(options);
            }
        } catch (e) {
            console.log(e);
        }
    }, [provinceState]);

    useEffect(() => {
        // if (autoOrManual) {
            // if (selectedOriginCity !== 0 && selectedDestinationCity !== 0) {
            //     let forms = {
            //         originCity: selectedOriginCity.value,
            //         destinationCity: selectedDestinationCity.value,
            //         services: selectedServices.value
            //     }
            //     dispatch(checkRates(forms));
    
            //     // Generate AirwayBill
            //     let dateTime = Math.floor(Date.now() / 1000);
            //     let awb = `${selectedOriginCity.label.substring(0,3)}-${dateTime.toString().substring(2,10)}`;
            //     setAirWayBill(awb);
            // } else {
            //     setAirWayBill("");
            //     setTotalPrice(0);
            //     setRate(0);
            // }
        // } else {
            // setAirWayBill("");
        // }

        if (selectedOriginCity !== 0 && selectedDestinationCity !== 0) {
            let forms = {
                originCity: selectedOriginCity.value,
                destinationCity: selectedDestinationCity.value,
                services: selectedServices.value
            }
            dispatch(checkRates(forms));

            // Generate AirwayBill
            if (autoOrManual) {
                let dateTime = Math.floor(Date.now() / 1000);
                let awb = `${selectedOriginCity.label.substring(0,3)}-${dateTime.toString().substring(2,10)}`;
                setAirWayBill(awb);    
            }
        } else {
            setAirWayBill("");
            setTotalPrice(0);
            setRate(0);
        }
    }, [selectedOriginCity, selectedDestinationCity, selectedServices])

    useEffect(() => {

        let v = calculateVolumetrik(length, width, height);

        setVolumetrik(v);

        try {
            if (checkRateState.loading) {
                setRate("Loading");
                setTotalPrice("Loading");
            } else {
                if (checkRateState.type === "CHECK_RATES_SUCCESS") {
                    // set total price
                    let volumeOrWeight;
                    if (volumetrik < weight) {
                        volumeOrWeight = weight;
                    } else if (volumetrik > weight) {
                        volumeOrWeight = volumetrik;
                    } else {
                        volumeOrWeight = weight;
                    }

                    let tp = (volumeOrWeight * checkRateState.data.data.rates) + ((packingPrice * 1) + (insurancePrice * 1));
                    console.log(typeof tp);
                    setTotalPrice(tp);
                    setRate(checkRateState.data.data.rates);    
                } else {
                    setTotalPrice("Error: Rate not found");
                    setRate("Error: Rate not found");
                }
            }
        } catch (e) {
            // console.log(e);
        }
    }, [weight, checkRateState, length, width, height, packingPrice, insurancePrice, volumetrik]);

    useEffect(() => {
        try {
            console.log("createTransactionState", createTransactionState);
            if (createTransactionState.type === 'CREATE_TRANSACTIONS_SUCCESS') {
                MySwal.close();
                dispatch(saveTransactions(createTransactionState.data.data.trx));
                history.push('transactions-print-receipt');
            } else if (createTransactionState.type === 'CREATE_TRANSACTIONS') {
                if (createTransactionState.loading) {
                    MySwal.fire({
                        title: 'Please Wait !',
                        allowOutsideClick: false,
                        onBeforeOpen: () => {
                          MySwal.showLoading()
                        },
                    }); 
                }
            } else {
                MySwal.close();
            }
        } catch (e) {
            console.log(e);
        }
    }, [createTransactionState])

    const handleChangeClient = selectedOption => {
        setSelectedClient(selectedOption);
        var index = clientState.clients.findIndex(x => x.id === selectedOption.value);
        var province = clientState.clients[index].province;
        var city = clientState.clients[index].city;

        // set selected province
        handleChangeOriginProvince({
            value: province.id,
            label: province.provinceName
        });

        // set selected city
        handleChangeOriginCity({
            value: city.id,
            label: `${city.cityName} - ${city.postalCode}`
        });

        // set address
        setOriginAddress(clientState.clients[index].address);
        setOriginPic(clientState.clients[index].picName);
        setOriginEmail(clientState.clients[index].email);
        setOriginPhoneNumber(clientState.clients[index].phoneNumber)
    };

    const getCity = (selectedOption, origin) => {
        try {
            var index = provinceState.data.findIndex(x => x.id === selectedOption.value);
            var cities = provinceState.data[index].cities;
            let cOptions = [];
            cities.map((item, index) => {
                cOptions.push({
                    value: item.id,
                    label: `${item.cityName} - ${item.postalCode}`
                });
            });
            if (origin) {
                setOriginCityOptions(cOptions);    
            } else {
                setDestinationCityOptions(cOptions);
            }
        } catch (e) {
            console.log(e);
        }
    }
    
    const handleChangeOriginProvince = selectedOption => {
        setSelectedOriginProvince(selectedOption);
        setSelectedOriginCity(0);
        getCity(selectedOption, true);
    }

    const handleChangeServices = selectedOption => {
        setSelectedServices(selectedOption);
    }

    const handleChangeOriginCity = selectedOption => {
        setSelectedOriginCity(selectedOption);
    };

    const handleChangeDestinationProvince = selectedOption => {
        setSelectedDestinationProvince(selectedOption);
        setSelectedDestinationCity(0);
        getCity(selectedOption, false);
    }

    const handleChangeDestinationCity = selectedOption => {
        setSelectedDestinationCity(selectedOption);
    }

    const handleChangePaymentMethod = selectedOption => {
        setPaymentMethod(selectedOption);
    }

    const handleChangeLayanan = selectedOption => {
        setLayanan(selectedOption);
    }

    const handleOnSubmit = (event) => {
      event.preventDefault();

      let attributes = {
          isVolumetrik: isVolumetrik,
          isPackingPrice: isPackingPrice,
          isInsurance: isInsurance,
          volumetrik: volumetrik,
          length: length,
          height: height,
          width: width,
          packingPrice: packingPrice,
          itemPrice: itemPrice,
          insurancePrice: insurancePrice,
          paymentMethod: paymentMethod.value,
          colly: colly,
          pcs: pcs,
          layanan: layanan.value,
          officer: officer,
          receiverOfficer: receiverOfficer,
      }
      
      let forms = {
          clientId: selectedClient.value,
          originProvinceId: selectedOriginProvince.value,
          originCityId: selectedOriginCity.value,
          destinationProvinceId: selectedDestinationProvince.value,
          destinationCityId: selectedDestinationCity.value,
          airWayBillNumber: airWayBill,
          originAddress: originAddress,
          originPic: originPic,
          originEmail: originEmail,
          originPhoneNumber: originPhoneNumber,
          consignee: consignee,
          destinationAddress: destinationAddress,
          consigneePic: destinationPic,
          consigneeEmail: destinationEmail,
          consigneePhoneNumber: destinationPhoneNumber,
          weight: weight,
          rateId: checkRateState.type === "CHECK_RATES_SUCCESS" ? checkRateState.data.data.id : "",
          totalPrice: totalPrice,
          packageDescription: packageDescription,
          attributes: attributes,
          createdAt: customDateTime.getTime()
      }
  
      console.log(forms)
  
      dispatch(createTransactions(forms));
    }

    const handleOnChangeConsignee = (selected) => {
      if (selected !== undefined || selected !== null) {
        if (selected.length > 0) {
          setConsignee(selected[0].companyName);
          setDestinationEmail(selected[0].email)
          setDestinationPhoneNumber(selected[0].phoneNumber)
          setDestinationAddress(selected[0].address)
          setDestinationPic(selected[0].picName)
          setSelectedDestinationProvince({
            label: selected[0].province.provinceName,
            value: selected[0].province.id,
          });
          setSelectedDestinationCity({
            label: selected[0].city.cityName,
            value: selected[0].city.id,
          });
        }
      }
    }

    return(
        <div className="content-wrapper">
            <ContentHeader
                title={'Create Transaction'}
            />
            <section className="content">
                <form onSubmit={handleOnSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        marginBottom: 10
                      }}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                          <label htmlFor="exampleInputEmail1">Waktu</label>
                          <div style={{ marginRight: 50}}></div>
                          {/* <div className="form-group"> */}
                            <div className="form-check">
                              <input 
                                className="form-check-input" 
                                type="radio" 
                                name="dateAutoOrManual" 
                                value={true} 
                                onChange={(e) => setDateAutoOrManual(e.target.value == "true" && true)}
                                // onSelect={(e) => console.log(e)}
                                checked={dateAutoOrManual}
                              />
                              <label className="form-check-label">Automatic</label>
                            </div>
                            <div style={{ marginRight: 20}}></div>
                            <div className="form-check">
                              <input 
                                className="form-check-input" 
                                type="radio" 
                                name="dateAutoOrManual" 
                                value={false} 
                                onChange={(e) => setDateAutoOrManual(e.target.value == "false" && false)}
                              />
                              <label className="form-check-label">Manual</label>
                            </div>
                          {/* </div> */}
                        </div>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                            <DatePicker 
                              className={"form-control"}
                              selected={customDateTime} 
                              onChange={date => setCustomDateTime(date)} 
                              readOnly={dateAutoOrManual}
                              />
                            <div style={{ margin: 5 }}></div>
                            <DatePicker 
                              className={"form-control"}
                              selected={customDateTime} 
                              onChange={date => setCustomDateTime(date)} 
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                              readOnly={dateAutoOrManual}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                <div className="row">
                    {/* Client */}
                    <div className="col-md-4">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Client</h3>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Client</label>
                                    <Select
                                        value={selectedClient}
                                        onChange={handleChangeClient}
                                        options={clientOptions}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Province</label>
                                    <Select
                                        value={selectedOriginProvince}
                                        onChange={handleChangeOriginProvince}
                                        options={originProvinceOptions}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">City</label>
                                    <Select
                                        value={selectedOriginCity}
                                        onChange={handleChangeOriginCity}
                                        options={originCityOptions}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Address</label>
                                    <input
                                        className="form-control"
                                        value={originAddress}
                                        placeholder={'Enter Address'}
                                        onChange={(e) => setOriginAddress(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Shipper</label>
                                    <input
                                        className="form-control"
                                        value={originPic}
                                        placeholder={'Enter Shipper'}
                                        onChange={(e) => setOriginPic(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email</label>
                                    <input
                                        className="form-control"
                                        value={originEmail}
                                        placeholder={'Enter Email'}
                                        onChange={(e) => setOriginEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                                    <input
                                        className="form-control"
                                        value={originPhoneNumber}
                                        placeholder={'Enter Phone Number'}
                                        onChange={(e) => setOriginPhoneNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Consignee */}
                    <div className="col-md-4">
                        <div className="card card-success">
                            <div className="card-header">
                                <h3 className="card-title">Consignee</h3>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Consignee</label>
                                    <Typeahead
                                      id="consigneeId"
                                      labelKey={(option) => `${option.companyName}`}
                                      options={consigneeTransform}
                                      placeholder={"Enter Consignee"}
                                      onChange={handleOnChangeConsignee}
                                      onInputChange={(value) => {
                                        setConsignee(value);
                                      }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Province</label>
                                    <Select
                                        value={selectedDestinationProvince}
                                        onChange={handleChangeDestinationProvince}
                                        options={destinationProvinceOptions}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">City</label>
                                    <Select
                                        value={selectedDestinationCity}
                                        onChange={handleChangeDestinationCity}
                                        options={destinationCityOptions}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Address</label>
                                    <input
                                        className="form-control"
                                        value={destinationAddress}
                                        placeholder={'Enter Address'}
                                        onChange={(e) => setDestinationAddress(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Receiver</label>
                                    <input
                                        className="form-control"
                                        value={destinationPic}
                                        placeholder={'Enter Receiver'}
                                        onChange={(e) => setDestinationPic(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email</label>
                                    <input
                                        className="form-control"
                                        value={destinationEmail}
                                        placeholder={'Enter Email'}
                                        onChange={(e) => setDestinationEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                                    <input
                                        className="form-control"
                                        value={destinationPhoneNumber}
                                        placeholder={'Enter Phone Number'}
                                        onChange={(e) => setDestinationPhoneNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Package */}
                    <div className="col-md-4">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">Package</h3>
                            </div>
                            <div className="card-body">
                                <div style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                    <div style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <label htmlFor="exampleInputEmail1">Airway Bill</label>
                                        <div style={{ marginRight: 50}}></div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="autoOrManual"
                                                value={true}
                                                onChange={(e) => setAutoOrManual(e.target.value == "true" && true)}
                                                checked={autoOrManual}
                                            />
                                            <label className="form-check-label">Otomatis</label>
                                        </div>
                                        <div style={{ marginRight: 20}}></div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="autoOrManual"
                                                value={false}
                                                onChange={(e) => setAutoOrManual(e.target.value == "false" && false)}
                                            />
                                            <label className="form-check-label">Manual</label>
                                        </div>
                                        <div style={{ marginRight: 20}}></div>
                                    </div>
                                    <input
                                        className="form-control"
                                        value={airWayBill}
                                        placeholder={'Enter Airway Bill'}
                                        readOnly={autoOrManual}
                                        onChange={(e) => setAirWayBill(e.target.value)}
                                    />
                                    <div style={{ marginBottom: 15}}></div>
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Airway Bill</label>
                                    <input
                                        className="form-control"
                                        value={airWayBill}
                                        placeholder={'Enter Airway Bill'}
                                        readOnly
                                        onChange={(e) => setAirWayBill(e.target.value)}
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Isi Kiriman</label>
                                    <input
                                        className="form-control"
                                        value={packageDescription}
                                        placeholder={'Enter Package Description'}
                                        onChange={(e) => setPackageDescription(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Berat</label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            // value={weight}
                                            placeholder={'0'}
                                            onChange={(e) => setWeight(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">KG</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={() => setIsVolumetrik(!isVolumetrik)}/>
                                        <label className="form-check-label">Volumetrik</label>
                                        {
                                            isVolumetrik &&
                                            <div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row'
                                                }}>
                                                    <div className="input-group" style={{ marginBottom: 10}}>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">P</span>
                                                        </div>
                                                        <input
                                                            className="form-control"
                                                            // value={length}
                                                            placeholder={'0'}
                                                            onChange={(e) => setLength(e.target.value)}
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: 5, marginRight: 5}}></div>
                                                    <div className="input-group" style={{ marginBottom: 10}}>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">L</span>
                                                        </div>
                                                        <input
                                                            className="form-control"
                                                            // value={width}
                                                            placeholder={'0'}
                                                            onChange={(e) => setWidth(e.target.value)}
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: 5, marginRight: 5}}></div>
                                                    <div className="input-group" style={{ marginBottom: 10}}>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">T</span>
                                                        </div>
                                                        <input
                                                            className="form-control"
                                                            // value={height}
                                                            placeholder={'0'}
                                                            onChange={(e) => setHeight(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="input-group" style={{ marginBottom: 10}}>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">Volumetrik</span>
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        value={thousandSeparator(Math.ceil(volumetrik))}
                                                        placeholder={'Enter Height'}
                                                        readOnly
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">KG</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={() => setIsPackingPrice(!isPackingPrice)} />
                                        <label className="form-check-label">Biaya Packing</label>
                                        {
                                            isPackingPrice &&
                                            <div>
                                                <div className="input-group" style={{ marginBottom: 10}}>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">Rp</span>
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        // value={packingPrice}
                                                        placeholder={'0'}
                                                        onChange={(e) => setPackingPrice(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={() => setIsInsurance(!isInsurance)}/>
                                        <label className="form-check-label">Asuransi</label>
                                        {
                                            isInsurance &&
                                            <div>
                                                {/* <label className="form-check-label">Harga Barang</label>
                                                <div className="input-group" style={{ marginBottom: 10}}>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">Rp</span>
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        value={itemPrice}
                                                        placeholder={'Enter Item Price'}
                                                        onChange={(e) => setItemPrice(e.target.value)}
                                                    />
                                                </div> */}
                                                {/* <label className="form-check-label">Harga Asuransi</label> */}
                                                <div className="input-group" style={{ marginBottom: 10}}>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">Rp</span>
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        // value={insurancePrice}
                                                        placeholder={'0'}
                                                        onChange={(e) => setInsurancePrice(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                    }}>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Jumlah Barang</label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                // value={colly}
                                                placeholder={'0'}
                                                onChange={(e) => setColly(e.target.value)}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">Colly</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 5}}></div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">PCS</label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                // value={pcs}
                                                placeholder={'0'}
                                                onChange={(e) => setPcs(e.target.value)}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">PCS</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Services</label>
                                    <Select
                                        options={servicesOptions}
                                        value={selectedServices}
                                        onChange={handleChangeServices}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Layanan</label>
                                    <Select
                                        options={layananOptions}
                                        value={layanan}
                                        onChange={handleChangeLayanan}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Jenis Pembayaran</label>
                                    <Select
                                        options={paymentMethodOptions}
                                        value={paymentMethod}
                                        onChange={handleChangePaymentMethod}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Petugas SUC</label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            value={officer}
                                            placeholder={'Enter Petugas SUC'}
                                            onChange={(e) => setOfficer(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Penerima</label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            value={receiverOfficer}
                                            placeholder={'Enter Penerima'}
                                            onChange={(e) => setReceiverOfficer(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Harga / KG</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Rp</span>
                                        </div>
                                        <input
                                            className="form-control"
                                            value={thousandSeparator(rate)}
                                            placeholder={'Enter Rate'}
                                            readOnly
                                            onChange={(e) => setRate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Total Biaya</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Rp</span>
                                        </div>
                                        <input
                                            className="form-control"
                                            value={thousandSeparator(Math.round(totalPrice))}
                                            placeholder={'Enter Total Price'}
                                            readOnly
                                            onChange={(e) => setTotalPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </section>
        </div>
    );

}

export default withRouter(CreateTransaction);