import { combineReducers } from "redux";
import {
  authReducer,
  authLogoutReducer,
  authCheckLoggedInReducer,
  getMeReducer,
  loadGetMeReducer,
} from "./auth_reducer";
import {
  clientReducer,
  viewClientReducer,
  createClientReducer,
  saveClientReducer,
  updateClientReducer,
  deleteClientReducer,
} from "./client_reducer";
import {
  consigneeReducer,
  viewConsigneeReducer,
  createConsigneeReducer,
  saveConsigneeReducer,
  updateConsigneeReducer,
  deleteConsigneeReducer,
} from "./consignee_reducer";
import {
  rateReducer,
  viewRateReducer,
  createRateReducer,
  saveRateReducer,
  updateRateReducer,
  deleteRateReducer,
  checkRateReducer,
} from "./rate_reducer";
import {
  provinceReducer,
  viewProvinceReducer,
  createProvinceReducer,
  saveProvinceReducer,
  updateProvinceReducer,
  deleteProvinceReducer,
} from "./province_reducer";
import {
  cityReducer,
  viewCityReducer,
  createCityReducer,
  saveCityReducer,
  updateCityReducer,
  deleteCityReducer,
} from "./city_reducer";
import {
  userReducer,
  viewUserReducer,
  createUserReducer,
  saveUserReducer,
  updateUserReducer,
  deleteUserReducer,
} from "./user_reducer";
import {
  roleReducer,
  viewRoleReducer,
  createRoleReducer,
  saveRoleReducer,
  updateRoleReducer,
  deleteRoleReducer,
} from "./role_reducer";
import {
  transactionReducer,
  viewTransactionReducer,
  createTransactionReducer,
  saveTransactionReducer,
  updateTransactionReducer,
  deleteTransactionReducer,
  transactionReportReducer,
} from "./transaction_reducer";
import {
  transactionDetailReducer,
  viewTransactionDetailReducer,
  createTransactionDetailReducer,
  saveTransactionDetailReducer,
  updateTransactionDetailReducer,
  deleteTransactionDetailReducer,
  removeTransactionDetailReducer,
} from "./transaction_detail_reducer";
import {
  statusReducer,
  viewStatusReducer,
  createStatusReducer,
  saveStatusReducer,
  updateStatusReducer,
  deleteStatusReducer,
} from "./status_reducer";
import { dashboardReducer } from "./dashboard_reducer";
import { filterReducer } from "./filters_reducer";
import {
  uploadFilesReducer,
  initializedUploadFilesReducer,
} from "./files_reducer";
import {
  uploadFileReducer,
  initializedUploadFileReducer,
} from "./upload_file_reducer";

const rootReducer = combineReducers({
  authReducer,
  authLogoutReducer,
  authCheckLoggedInReducer,
  getMeReducer,
  loadGetMeReducer,
  clientReducer,
  provinceReducer,
  cityReducer,
  viewClientReducer,
  createClientReducer,
  saveClientReducer,
  updateClientReducer,
  deleteClientReducer,
  rateReducer,
  viewRateReducer,
  createRateReducer,
  saveRateReducer,
  updateRateReducer,
  deleteRateReducer,
  userReducer,
  viewUserReducer,
  createUserReducer,
  saveUserReducer,
  updateUserReducer,
  deleteUserReducer,
  roleReducer,
  viewRoleReducer,
  createRoleReducer,
  saveRoleReducer,
  updateRoleReducer,
  deleteRoleReducer,
  transactionReducer,
  viewTransactionReducer,
  createTransactionReducer,
  saveTransactionReducer,
  updateTransactionReducer,
  deleteTransactionReducer,
  checkRateReducer,
  transactionDetailReducer,
  viewTransactionDetailReducer,
  createTransactionDetailReducer,
  saveTransactionDetailReducer,
  updateTransactionDetailReducer,
  deleteTransactionDetailReducer,
  removeTransactionDetailReducer,
  statusReducer,
  viewStatusReducer,
  createStatusReducer,
  saveStatusReducer,
  updateStatusReducer,
  deleteStatusReducer,
  transactionReportReducer,
  dashboardReducer,
  filterReducer,
  consigneeReducer,
  viewConsigneeReducer,
  createConsigneeReducer,
  saveConsigneeReducer,
  updateConsigneeReducer,
  deleteConsigneeReducer,
  uploadFilesReducer,
  initializedUploadFilesReducer,
  viewProvinceReducer,
  createProvinceReducer,
  saveProvinceReducer,
  updateProvinceReducer,
  deleteProvinceReducer,
  viewCityReducer,
  createCityReducer,
  saveCityReducer,
  updateCityReducer,
  deleteCityReducer,
  uploadFileReducer,
  initializedUploadFileReducer,
});

export default rootReducer;
