import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, withRouter, Link, useLocation } from 'react-router-dom';
import { getCities, saveCities, deleteCity, initializeCreateCities, initializeUpdateCities } from '../../redux/actions/city_action';
import ContentHeader from '../../components/content_header';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import qs from 'query-string'
import ReactPaginate from 'react-paginate';
import { uninitializedFilters } from '../../redux/actions/filters_action';

function City(props) {

  const cityState = useSelector(state => state.cityReducer);
  const cityDeleteState = useSelector(state => state.deleteCityReducer);
  const filterState = useSelector(state => state.filterReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const location = useLocation();
  
  // Pagination
  // const [page, setPage] = useState(1);
  const [pagePerPage, setPagePerPage] = useState({
    page: 1,
    perPage: 15
  });
  const [totalPage, setTotalPage] = useState(0);
  const [filters, setFilters] = useState({
    airWayBillNumber: "",
    clientId: "",
    createdAt: ""
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(initializeCreateCities());
    dispatch(initializeUpdateCities());
    dispatch(uninitializedFilters());
    dispatch(getCities());
  }, [])

  useEffect(() => {
    try {
      console.log("cityState", cityState);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [cityState]);

  useEffect(() => {
    console.log("cityDeleteState", cityDeleteState)
    setLoading(true);
    try {
      if (cityDeleteState.type === 'DELETE_CITIES_SUCCESS') {
        dispatch(getCities());
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [cityDeleteState]);

  const viewCity = (event, c) => {
    event.preventDefault();
    history.push({
      pathname: '/home/cities-view',
      state: c
    });
  }

  const editCity = (event, c) => {
    event.preventDefault();
    // dispatch(saveCities(c));
    history.push({
      pathname: '/home/cities-edit',
      state: c
    });
  }

  const onDeleteCity = (event, c) => {
    event.preventDefault();
    MySwal.fire({
      title: 'Are you sure?',
      text: 'This data will be deleted',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33', 
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        dispatch(deleteCity(c.id))
      }
    })
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title="City"/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <Link
                    type={'button'}
                    className={'btn btn-sm btn-success card-title'}
                    to={'/home/cities-create'}>
                    Create City
                  </Link>
                </div>

                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Provinsi</th>
                        <th>Nama Kota</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ? 
                        <tr>
                          <td>Loading</td>
                        </tr>
                        :
                        cityState?.data?.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index+1}</td>
                              <td>{item.province.provinceName}</td>
                              <td>{item.cityName}</td>
                              <td>
                                <div className="btn-group">
                                  <button type="button" className="btn btn-primary" onClick={(event) => viewCity(event, item)}>View</button>
                                  <button type="button" className="btn btn-warning" onClick={(event) => editCity(event, item)}>Edit</button>
                                  <button type="button" className="btn btn-danger" onClick={(event) => onDeleteCity(event, item)}>Delete</button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
                {/* /.card-body */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

}

export default withRouter(City);