import Api from '../../api/api';
import { toast } from 'react-toastify';

export const loginTypes = {
    AUTH_LOGIN: "AUTH_LOGIN",
    AUTH_LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
    AUTH_LOGIN_ERROR: "AUTH_LOGIN_ERROR",
    GET_ME: "GET_ME",
    GET_ME_SUCCESS: "GET_ME_SUCCESS",
    GET_ME_ERROR: "GET_ME_ERROR",
    GET_LOAD_GET_ME: "GET_LOAD_GET_ME",
    LOAD_GET_ME: "LOAD_GET_ME"
}

export function checkIsLoggedIn() {
    return dispatch => {
        dispatch({ 
            type: "AUTH_CHECK",
            authorized: false,
            loading: true
        });

        var token = localStorage.getItem("token");
        if (token !== null && token !== "") {
            dispatch({
                type: "AUTHORIZED",
                authorized: true,
                loading: false
            });
        } else {
            dispatch({
                type: "UNAUTHORIZED",
                authorized: false,
                loading: false
            });
        }
    }
}

export function login(username, password, ownProps) {
    return dispatch => {
        dispatch({ type: loginTypes.AUTH_LOGIN, loading: true });
        
        setTimeout(() => {
            var api = new Api();
            api.doLogin(username, password)
                .then(response => {
                    if (response.status === 200) {

                        // add token to local storage
                        localStorage.setItem("token", response.data.data);
                        dispatch({
                            type: loginTypes.AUTH_LOGIN_SUCCESS,
                            data: response.data,
                            loading: false,
                            success: true,
                        });

                        ownProps.history.push('/');
                    } else {
                        toast.error("Username atau password salah.");
                        dispatch({
                            type: loginTypes.AUTH_LOGIN_ERROR,
                            loading: false,
                            success: false,
                            error: response.originalError
                        });
                    }
                })
                .catch(error => {
                    dispatch({
                        type: loginTypes.AUTH_LOGIN_ERROR,
                        loading: false,
                        success: false,
                        error
                    });
                })
        }, 0);
    }
}

export function logout(props) {
    return dispatch => {
        dispatch({ 
            type: "AUTH_LOGOUT",
            loading: true
        });
        
        // remove token when logout
        localStorage.removeItem("token");
        localStorage.clear();

        dispatch({ 
            type: "AUTH_LOGOUT_SUCCESS",
            loading: false
        });

        props.history.push('/login');
    }
}

export function loadGetMe() {
  return dispatch => {
    dispatch({
      type: loginTypes.GET_LOAD_GET_ME,
    });

    // get client in localStorage
    var getMe = JSON.parse(localStorage.getItem('getMe'));
    dispatch({
      type: loginTypes.LOAD_GET_ME,
      data: getMe
    });
    return;
  }
}

export function getMe() {
    return dispatch => {
        dispatch({
            type: loginTypes.GET_ME,
            loading: true,
        })

        var api = new Api();
        api.getMe()
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status) {
                      // add token to local storage
                      localStorage.setItem("getMe", JSON.stringify(response.data));

                      dispatch({
                        type: loginTypes.GET_ME_SUCCESS,
                        loading: false,
                        data: response.data
                      });
                      return;
                    } else {
                      // remove getMe
                      localStorage.removeItem("getMe");

                      dispatch({
                          type: loginTypes.GET_ME_ERROR,
                          loading: false,
                          error: response.data
                      });
                      return;
                    }
                } else {
                  // remove getMe
                  localStorage.removeItem("getMe");
                  dispatch({
                      type: loginTypes.GET_ME_ERROR,
                      loading: false,
                      error: response.data
                  });
                  return;
                }
            })
            .catch(error => {
              // remove getMe
              localStorage.removeItem("getMe");
              dispatch({
                  type: loginTypes.GET_ME_ERROR,
                  loading: false,
                  error: error
              });
              return;
            });
    }
}