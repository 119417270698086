import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, withRouter, Link, useLocation } from 'react-router-dom';
import { getTransactions, viewTransactions, saveTransactions, deleteTransaction, initializeCreateTransactions, initializeUpdateTransactions, getTransactionsReport } from '../../redux/actions/transaction_action';
import ContentHeader from '../../components/content_header';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import qs from 'query-string'
import ReactPaginate from 'react-paginate';
import { getClients } from '../../redux/actions/client_action';
import Select from 'react-select';
import { initializeCreateTransactionDetails } from '../../redux/actions/transaction_detail_action';
import DatePicker from "react-datepicker";
import Api from '../../api/api';
import { initializedFilters, uninitializedFilters } from '../../redux/actions/filters_action';

function ReportTransaction(props) {

    const {
        loading,
        transactions,
        data
    } = useSelector(state => state.transactionReducer);
    const transactionReportState = useSelector(state => state.transactionReportReducer);
    const clientState = useSelector(state => state.clientReducer);
    const transaction = useSelector(state => state.saveTransactionReducer.transaction);
    const transactionDeleteState = useSelector(state => state.deleteTransactionReducer);
    const dispatch = useDispatch();
    const history = useHistory();
    const MySwal = withReactContent(Swal)
    const location = useLocation();

    // Pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [totalPage, setTotalPage] = useState(0);
    const [filters, setFilters] = useState({
        startDate: new Date().getTime(),
        endDate: new Date().getTime()
    });
    const [selectedClient, setSelectedClient] = useState(0);
    const [clientOptions, setClientOptions] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const filename = 'Csv-file';
    const fields = {
        "index": "Index",
        "guid": "GUID"
    };
    const style = {
        padding: "5px"
    };
    const dataa = [
        { index: 0, guid: 'asdf231234'},
        { index: 1, guid: 'wetr2343af'}
    ];
    const text = "Convert Json to Csv";

    useEffect(() => {
        let params = qs.parse(location.search);
    
        try {
          setPage(params && params.page);
          setPerPage(params && params.perPage);
        } catch (e) {
          console.log(e);
        }
    
        dispatch(initializeCreateTransactions());
        dispatch(initializeUpdateTransactions());
        dispatch(initializeCreateTransactionDetails());
        dispatch(uninitializedFilters());
      }, [page, perPage]);
    
      useEffect(() => {
        dispatch(getClients(1, 300));
        dispatch(getTransactions(page, perPage, filters));
    }, [])

    useEffect(() => {
    try {
        if (clientState.type === 'GET_CLIENTS_SUCCESS') {
            let options = [];
            clientState.clients.map((item, index) => {
                options.push({
                    value: item.id,
                    label: item.companyName
                });
            });
            setClientOptions(options);
        }
    } catch (e) {
        console.log(e);
    }
}, [clientState]);

    useEffect(() => {
    try {
        console.log("totalPage: ", data.data.totalPage);
        setTotalPage(data.data.totalPage);
    } catch (e) {
        console.log(e)
    }
    }, [transactions, data]);

    useEffect(() => {
    try {
        if (transactionDeleteState.type === 'DELETE_TRANSACTIONS_SUCCESS') {
        dispatch(getTransactions(1, 15, filters));
        }
    } catch (e) {
        console.log(e);
    } 
    }, [transactionDeleteState]);

    const viewTransaction = (event, c) => {
    // event.preventDefault();
    dispatch(saveTransactions(c));
    history.push('transactions-view');
    }

    const editTransaction = (event, c) => {
    dispatch(saveTransactions(c));
    history.push('transaction-details-create');
    }

    const onDeleteTransaction = (event, c) => {
    MySwal.fire({
        title: 'Are you sure?',
        text: 'This data will be deleted',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', 
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.value) {
        dispatch(deleteTransaction(c.id))
        }
    })
    }

    const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
    dispatch(getTransactions(selectedPage, perPage, filters));
    }

    const handleSearch = (e) => {
    e.preventDefault();
    console.log(filters);

    dispatch(getTransactions(1, 15, filters));
    }

    const handleChangeClient = (selectedOptions) => {
        setSelectedClient(selectedOptions);
        setFilters({
            ...filters,
            clientId: selectedOptions.value
        });
    }

    return(
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <form onSubmit={handleSearch}>
                        <div className="card-header">
                            <h3 className="card-title">Search</h3>
                            <div className="card-tools">
                                <button 
                                    type="submit" 
                                    className="btn btn-warning"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(getTransactions(1, 15, {}));
                                    }}>
                                        Reset
                                </button>
                                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i></button>
                                <button type="button" className="btn btn-tool" data-card-widget="remove"><i className="fas fa-times"></i></button> */}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Airway Bill</label>
                                        <input
                                            className="form-control"
                                            placeholder={'Enter Airway Bill'}
                                            onChange={(e) => {
                                                setFilters({
                                                    ...filters,
                                                    airWayBillNumber: e.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Client</label>
                                        <Select
                                            value={selectedClient}
                                            onChange={handleChangeClient}
                                            options={clientOptions}
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Start Date</label> <br/>
                                        <DatePicker 
                                            className={"form-control"}
                                            selected={startDate} 
                                            onChange={date => {
                                                setStartDate(date)
                                                setFilters({
                                                    ...filters,
                                                    startDate: date.getTime()
                                                })
                                            }
                                            } 
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">End Date</label> <br/>    
                                        <DatePicker 
                                            className={"form-control"}
                                            selected={endDate} 
                                            onChange={date => {
                                                setEndDate(date);
                                                setFilters({
                                                    ...filters,
                                                    endDate: date.getTime()
                                                })
                                            }} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary">Search</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-12">
                <div className="card">

                <div className="card-header">
                    <button 
                        className='btn btn-sm btn-success card-title'
                        onClick={(event) => {
                            event.preventDefault()
                            dispatch(getTransactionsReport(1, 15, filters));
                        }}>
                        Download Report
                    </button>
                  {/* <Link
                    type={'button'}
                    className={'btn btn-sm btn-success card-title'}
                    to={'/home/transactions-create'}>
                    Create Transaction
                  </Link> */}
                </div>

                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>
                        <th>No</th>
                        <th>Airway Bill</th>
                        <th>Pengirim</th>
                        <th>Consignee</th>
                        <th>Asal</th>
                        <th>Tujuan</th>
                        <th>Paket</th>
                        <th>Waktu Dibuat</th>
                        {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                        loading ? 
                        <tr>
                            <td>Loading</td>
                        </tr>
                        :
                        transactions && transactions.map((item, index) => {
                            return (
                            <tr key={item.id}>
                                <td>{index+1}</td>
                                <td>{item.airWayBillNumber}</td>
                                <td>{item.client.companyName}</td>
                                <td>{item.consignee}</td>
                                <td>{item.originCity.cityName}</td>
                                <td>{item.destinationCity.cityName}</td>
                                <td>{item.packageDescription}</td>
                                <td>{new Date(parseInt(item.createdAt)).toLocaleString('default', { hour12: false })}</td>
                                {/* <td>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-primary" onClick={(event) => viewTransaction(event, item)}>View</button>
                                    <button type="button" className="btn btn-warning" onClick={(event) => editTransaction(event, item)}>Update Tracking</button>
                                    <button type="button" className="btn btn-danger" onClick={(event) => onDeleteTransaction(event, item)}>Delete</button>
                                </div>
                                </td> */}
                            </tr>
                            );
                        })
                        }
                    </tbody>
                    </table>
                </div>
                {/* /.card-body */}

                <div className="card-footer clearfix">
                    <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabe={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pagination-sm m-0 float-right"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    />
                </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ReportTransaction)