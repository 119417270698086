import React, { useEffect, useState } from 'react'
import { useHistory, withRouter, Link, useLocation } from 'react-router-dom';

function ReportUser(props) {

    return(
        <div>
            <h1>Mantap</h1>
        </div>
    )
}

export default withRouter(ReportUser)