import React, { useEffect } from 'react';
import './App.css';
import {
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import Login from './pages/login/login';
import Homes from './pages/homes/homes';
import { useDispatch, useSelector } from "react-redux";
import { checkIsLoggedIn } from './redux/actions/auth_action'
import PrivateRoute from './router/private_route';

export default function App() {

  const { loading, authorized, type } = useSelector(state => ({
    loading: state.authCheckLoggedInReducer.loading,
    authorized: state.authCheckLoggedInReducer.authorized,
    type: state.authCheckLoggedInReducer.type,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(checkIsLoggedIn());
  }, [checkIsLoggedIn]);

  useEffect(() => {
  }, [authorized])

  return(
    <div>
      {/* <CheckAuth type={type} /> */}
      <Switch>
        <Route exact path="/login" component={Login}/>
        <PrivateRoute exact path="/">
          <Homes/>
        </PrivateRoute>
        <PrivateRoute path="/home" component={Homes}/>
      </Switch>
      {/* <Route component={NotFound}/> */}
    </div>
  );
}
