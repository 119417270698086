const { uploadFileTypes } = require("../actions/upload_file_action");

let initialState = {
  loading: false,
  data: {},
  type: uploadFileTypes.CREATE_UPLOAD_FILES,
  error: {},
};

let initializedUploadFilesState = {
  loading: false,
  type: uploadFileTypes.CREATE_UPLOAD_FILES,
};

export const initializedUploadFileReducer = (
  state = initializedUploadFilesState,
  action
) => {
  switch (action.type) {
    case uploadFileTypes.CREATE_UPLOAD_FILES_INITIALIZED:
      return {
        type: action.type,
        loading: action.loading,
        data: action.data,
      };
    default:
      return state;
  }
};

export const uploadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadFileTypes.CREATE_UPLOAD_FILES_INITIALIZED:
      return {
        type: action.type,
        loading: action.loading,
        data: action.data,
      };
    case uploadFileTypes.CREATE_UPLOAD_FILES:
      return {
        type: action.type,
        loading: action.loading,
      };
    case uploadFileTypes.CREATE_UPLOAD_FILES_UPLOADING:
      return {
        type: action.type,
        loading: action.loading,
      };
    case uploadFileTypes.CREATE_UPLOAD_FILES_SUCCESS:
      return {
        type: action.type,
        loading: action.loading,
        data: action.data,
      };
    case uploadFileTypes.CREATE_UPLOAD_FILES_ERROR:
      return {
        type: action.type,
        loading: action.loading,
        data: action.data,
        error: action.error,
      };
    default:
      return state;
  }
};
