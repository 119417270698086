import Api from '../../api/api';
import { toast } from 'react-toastify';

export function saveRoles(role) {
  return dispatch => {
    // delete role in localStorage
    localStorage.removeItem('role');

    // add role to localStorage
    localStorage.setItem('role', JSON.stringify(role));
    dispatch({
      type: "SAVE_ROLE",
      role: role
    });
    return;
  }
}

export function viewRoles() {
  return dispatch => {
    // get role in localStorage
    var role = JSON.parse(localStorage.getItem('role'));
    dispatch({
      type: "VIEW_ROLE",
      role
    }); 
    return;
  }
}

export function initializeCreateRoles() {
  return dispatch => {
    dispatch({
      type: "CREATE_ROLES"
    });
  }
}

export function initializeUpdateRoles() {
  return dispatch => {
    dispatch({
      type: "UPDATE_ROLES"
    });
  }
}

export function createRoles(forms) {
  return dispatch => {
    dispatch({
      type: "CREATE_ROLES"
    });

    var api = new Api();
    api.createRoles(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: "CREATE_ROLES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "CREATE_ROLES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "CREATE_ROLES_ERROR",
            error
        });
        return;
      });
  }
}

export function updateRoles(forms, roleId) {
  return dispatch => {
    dispatch({
      type: "UPDATE_ROLES"
    });

    var api = new Api();
    api.updateRoles(forms, roleId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: "UPDATE_ROLES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "UPDATE_ROLES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "UPDATE_ROLES_ERROR",
            error
        });
        return;
      });
  }
}

export function deleteRole(roleId) {
  return dispatch => {
    dispatch({
      type: "DELETE_ROLES"
    });

    var api = new Api();
    api.deleteRoles(roleId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: "DELETE_ROLES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "DELETE_ROLES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "DELETE_ROLES_ERROR",
            error
        });
        return;
      });
  }
}

export function getRoles(page, perPage) {
  return dispatch => {
    dispatch({
      type: "GET_ROLES",
      data: {},
      roles: []
    });

    var api = new Api();
    api.getRoles(page, perPage)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "GET_ROLES_SUCCESS",
            data: response.data,
            roles: response.data.data.data
          });
          return;
        } else {
          dispatch({
            type: "GET_ROLES_ERROR",
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "GET_ROLES_ERROR",
            error
        });
        return;
      })
  }
}