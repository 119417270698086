import React, { Component, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import ContentHeader from "../../components/content_header";
import { getProvinces } from "../../redux/actions/province_action";
import Select from "react-select";
import { createClients } from "../../redux/actions/client_action";
import { ToastContainer } from "react-toastify";

function CreateClient(props) {
  const data = useSelector((state) => state.provinceReducer.data);
  const type = useSelector((state) => state.provinceReducer.type);
  const createClientState = useSelector((state) => state.createClientReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedCityOption, setSelectedCityOption] = useState(0);
  const [options, setOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  // State form
  const [companyName, setCompanyName] = useState("");
  const [picName, setPicName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    dispatch(getProvinces(""));
  }, [getProvinces]);

  useEffect(() => {
    if (createClientState.type === "CREATE_CLIENTS_SUCCESS") {
      history.goBack();
    }
  }, [createClientState]);

  useEffect(() => {
    if (type === "GET_PROVINCES_SUCCESS") {
      let options = [];
      data &&
        data.map((item, index) => {
          options.push({
            value: item.id,
            label: item.provinceName,
          });
        });
      setOptions(options);
    }
  }, [data]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    var index = data.findIndex((x) => x.id === selectedOption.value);
    var cities = data[index].cities;
    let cOptions = [];
    cities.map((item, index) => {
      cOptions.push({
        value: item.id,
        label: item.cityName,
      });
    });
    setCitiesOptions(cOptions);
  };

  const handleChangeCities = (selectedOption) => {
    setSelectedCityOption(selectedOption);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      companyName: companyName,
      picName: picName,
      email: email,
      address: address,
      phoneNumber: phoneNumber,
      provinceId: selectedOption.value,
      cityId: selectedCityOption.value,
      apiKey: apiKey,
    };

    console.log(forms);

    dispatch(createClients(forms));
  };

  return (
    <div className="content-wrapper">
      <ContentHeader title={"Create Client"} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Company Name</label>
                      <input
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Company Name"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        PIC (Person In Charge) Name
                      </label>
                      <input
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter PIC (Person In Charge) Name"
                        onChange={(e) => setPicName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <input
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Phone Number</label>
                      <input
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Phone Number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Address</label>
                      <input
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Address"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">API KEY</label>
                      <input
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Api Key"
                        onChange={(e) => setApiKey(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Province</label>
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">City</label>
                      <Select
                        value={selectedCityOption}
                        onChange={handleChangeCities}
                        options={citiesOptions}
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(CreateClient);
