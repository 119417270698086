import Api from "../../api/api";
import { toast } from "react-toastify";

export const uploadFileTypes = {
  CREATE_UPLOAD_FILES_INITIALIZED: "CREATE_UPLOAD_FILES_INITIALIZED",
  CREATE_UPLOAD_FILES: "CREATE_UPLOAD_FILES",
  CREATE_UPLOAD_FILES_SUCCESS: "CREATE_UPLOAD_FILES_SUCCESS",
  CREATE_UPLOAD_FILES_ERROR: "CREATE_UPLOAD_FILES_ERROR",
  CREATE_UPLOAD_FILES_UPLOADING: "CREATE_UPLOAD_FILES_UPLOADING",
};

export function uploadFileInitialized() {
  return (dispatch) => {
    dispatch({
      type: uploadFileTypes.CREATE_UPLOAD_FILES_INITIALIZED,
      loading: false,
      data: undefined,
    });
    return;
  };
}

export function uploadFile(files) {
  return (dispatch) => {
    dispatch({
      type: uploadFileTypes.CREATE_UPLOAD_FILES,
      loading: true,
    });

    dispatch({
      type: uploadFileTypes.CREATE_UPLOAD_FILES_UPLOADING,
      loading: true,
    });

    var api = new Api();
    api
      .uploadFilesV2(files)
      .then((response) => {
        let data = response.data;
        console.log(data);
        if (response.status === 200) {
          dispatch({
            type: uploadFileTypes.CREATE_UPLOAD_FILES_SUCCESS,
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: uploadFileTypes.CREATE_UPLOAD_FILES_ERROR,
            data,
          });

          if (response.status === 400) {
            response.data.forEach((item) => {
              toast.error(
                item.constraints.isNotEmpty || item.constraints.length
              );
            });
          }
          return;
        }
      })
      .catch((error) => {
        dispatch({
          type: uploadFileTypes.CREATE_UPLOAD_FILES_ERROR,
          error,
        });
        return;
      });
  };
}
