import Api from '../../api/api';
import { toast } from 'react-toastify';

export function saveClients(client) {
  return dispatch => {
    // delete client in localStorage
    localStorage.removeItem('client');

    // add client to localStorage
    localStorage.setItem('client', JSON.stringify(client));
    dispatch({
      type: "SAVE_CLIENT",
      client: client
    });
    return;
  }
}

export function viewClients() {
  return dispatch => {
    // get client in localStorage
    var client = JSON.parse(localStorage.getItem('client'));
    dispatch({
      type: "VIEW_CLIENT",
      client
    });
    return;
  }
}

export function initializeCreateClients() {
  return dispatch => {
    dispatch({
      type: "CREATE_CLIENTS"
    });
  }
}

export function initializeUpdateClients() {
  return dispatch => {
    dispatch({
      type: "UPDATE_CLIENTS"
    });
  }
}

export function createClients(forms) {
  return dispatch => {
    dispatch({
      type: "CREATE_CLIENTS"
    });

    var api = new Api();
    api.createClients(forms)
      .then(response => {
        let data = response.data;
        console.log(data)
        if (response.status === 201) {
          dispatch({
            type: "CREATE_CLIENTS_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "CREATE_CLIENTS_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "CREATE_CLIENTS_ERROR",
            error
        });
        return;
      });
  }
}

export function updateClients(forms, clientId) {
  return dispatch => {
    dispatch({
      type: "UPDATE_CLIENTS"
    });

    var api = new Api();
    api.updateClients(forms, clientId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: "UPDATE_CLIENTS_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "UPDATE_CLIENTS_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "UPDATE_CLIENTS_ERROR",
            error
        });
        return;
      });
  }
}

export function deleteClient(clientId) {
  return dispatch => {
    dispatch({
      type: "DELETE_CLIENTS"
    });

    var api = new Api();
    api.deleteClients(clientId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: "DELETE_CLIENTS_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "DELETE_CLIENTS_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "DELETE_CLIENTS_ERROR",
            error
        });
        return;
      });
  }
}

export function getClients(page, perPage) {
  return dispatch => {
    dispatch({
      type: "GET_CLIENTS"
    });

    var api = new Api();
    api.getClients(page, perPage)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "GET_CLIENTS_SUCCESS",
            data: response.data,
            clients: response.data.data.data
          });
          return;
        } else {
          dispatch({
            type: "GET_CLIENTS_ERROR",
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "GET_CLIENTS_ERROR",
            error
        });
        return;
      })
  }
}