import React, { useEffect } from 'react'
import { 
  Link, 
  useHistory,
  withRouter,
  useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getMe, logout } from '../../redux/actions/auth_action';
import Api from '../../api/api';
import { saveLastPageTrx } from '../../redux/actions/transaction_action';

function SideBar(props) {

  const {loading, type} = useSelector(state => state.authLogoutReducer);
  const getMeState = useSelector(state => state.getMeReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  let { path, url } = useRouteMatch();
  let api = new Api();

  useEffect(() => {
    dispatch(getMe());
  }, [getMe])

  const handleClick = (event, link) => {
    event.preventDefault();
    history.push(link);
  }

  useEffect(() => {
    console.log('url: ', url);
  }, []);

  useEffect(() => {
    try {

    } catch (e) {
      console.log(e);
    }
  }, [getMeState])

  return (
    <div>
      {/* <!-- Main Sidebar Container --> */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* <!-- Brand Logo --> */}
        <a href="index3.html" className="brand-link">
          <img src="/img/logo_suc.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3"/>
          <span className="brand-text font-weight-light">PT SUC</span>
        </a>

        {/* <!-- Sidebar --> */}
        <div className="sidebar">
          {/* <!-- Sidebar user panel (optional) --> */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              {
                getMeState.loading 
                  ? <img 
                      src="/img/user2-160x160.jpg" 
                      className="img-circle elevation-2" 
                      alt={"User Profile"}/>
                  : <img 
                      src={`${api.baseURL}/${getMeState.data?.data?.files?.filePath}`} 
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: '100%',
                        objectFit: 'cover'
                      }}
                      className="elevation-2" 
                      alt={"User Profile"}/>
              }
            </div>
            <div className="info">
              <a href="/" className="d-block">{
                getMeState.loading ? "Loading" : getMeState.data?.data?.fullName
              }</a>
            </div>
          </div>

          {/* <!-- Sidebar Menu --> */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {/* <!-- Add icons to the links using the .nav-icon class
                  with font-awesome or any other icon font library --> */}
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/dashboard`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Dashboard
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link 
                  type="a" 
                  className="nav-link" 
                  to={`/home/transactions`}
                  onClick={e => {
                    e.preventDefault();
                    saveLastPageTrx(1);
                    history.push(`/home/transactions`);
                  }}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Transaction
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/clients`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Client
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/provinces`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Provinsi
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/cities`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Kota
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/consignees`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Consignee
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/rates`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Rate
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/users`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    User
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/roles`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Role
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="a" className="nav-link" to={`/home/reports`}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Report
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => dispatch(logout(props))}>
                  <i className="nav-icon fa fa-tachometer-alt"></i>
                  <p>
                    Logout
                  </p>
                </a>
              </li>
            </ul>
          </nav>
          {/* <!-- /.sidebar-menu --> */}
        </div>
        {/* <!-- /.sidebar --> */}
      </aside>
    </div>
  )
}

export default withRouter(SideBar);