import React, { Component, useEffect, useState, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import ContentHeader from "../../components/content_header";
import Select from "react-select";
import { createTransactionDetails } from "../../redux/actions/transaction_detail_action";
import { getStatuses } from "../../redux/actions/status_action";
import { viewTransactions } from "../../redux/actions/transaction_action";
import {
  getCitiesAll,
  citiesActionTypes,
} from "../../redux/actions/city_action";
import DatePicker from "react-datepicker";
import {
  uploadFile,
  uploadFileInitialized,
  uploadFileTypes,
} from "../../redux/actions/upload_file_action";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function CreateTransactionDetail(props) {
  const MySwal = withReactContent(Swal);
  const createTransactionDetailState = useSelector(
    (state) => state.createTransactionDetailReducer
  );
  const viewTransactionState = useSelector(
    (state) => state.viewTransactionReducer
  );
  const statusState = useSelector((state) => state.statusReducer);
  const citiesState = useSelector((state) => state.cityReducer);
  const uploadFileReducerState = useSelector(
    (state) => state.uploadFileReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const inputFile = useRef(null);

  // State form
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [autoOrManual, setAutoOrManual] = useState(true);
  const [file, setFile] = useState();
  const [fileId, setFileId] = useState();
  const [status, setStatus] = useState();
  const [imageId, setImageId] = useState();

  const successDeliver = [
    "Yang Bersangkutan",
    "Resepsionis / Sekretaris",
    "Rekan Kerja",
    "Satpam",
    "Keluarga",
    "Lainnya",
  ];

  const failedDeliver = [
    "Penerima Tidak Dikenal",
    "Pindah Alamat",
    "Alamat Tidak Lengkap",
    "Rumah Kosong",
    "Penerima Tidak Bisa Dihubungi",
    "Lainnya",
  ];

  // sub detail
  const [subDetail, setSubDetail] = useState([]);
  const [uploadImageEnable, setUploadImageEnable] = useState(false);

  const [isSubDetail, setIsSubDetail] = useState(false);
  const [isAdditional, setIsAdditional] = useState(false);
  const [selectedSubDetail, setSelectedSubDetail] = useState(0);
  const [other, setOther] = useState("");
  const [customDateTime, setCustomDateTime] = useState(new Date());
  const [selectedAdditional, setSelectedAdditional] = useState("");
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [selectedSortingCenter, setSelectedSortingCenter] = useState(0);
  const [sortingCenterOptions, setSortingCenterOptions] = useState([]);

  useEffect(() => {
    let ao = [];
    ao.push({
      value: "KANTOR ASAL",
      label: "KANTOR ASAL",
    });
    ao.push({
      value: "KANTOR TUJUAN",
      label: "KANTOR TUJUAN",
    });

    setAdditionalOptions(ao);
  }, []);

  useEffect(() => {
    dispatch(getCitiesAll());
  }, [getCitiesAll]);

  useEffect(() => {
    dispatch(getStatuses(1, 300, {}));
  }, [getStatuses]);

  useEffect(() => {
    dispatch(viewTransactions());
    dispatch(uploadFileInitialized());
  }, [viewTransactions]);

  useEffect(() => {
    console.log(uploadFileReducerState.type);
  }, [uploadFileReducerState]);

  useEffect(() => {
    if (autoOrManual) {
      setCustomDateTime(new Date());
    }
  }, [autoOrManual]);

  useEffect(() => {
    try {
      console.log("createFiles.type", uploadFileReducerState.type);
      if (
        uploadFileReducerState.type ===
        uploadFileTypes.CREATE_UPLOAD_FILES_SUCCESS
      ) {
        MySwal.close();
        setStatus("Uploaded");
        setFileId(uploadFileReducerState.data?.data);
        setImageId(uploadFileReducerState.data?.data?.id);
      } else if (
        uploadFileReducerState.type ===
        uploadFileTypes.CREATE_UPLOAD_FILES_UPLOADING
      ) {
        MySwal.fire({
          title: "Uploading File",
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          willOpen: () => {
            MySwal.showLoading();
          },
        });
        setStatus("Uploading");
      }
    } catch (e) {
      console.log(e);
    }
  }, [uploadFileReducerState]);

  useEffect(() => {
    if (file !== undefined) {
      dispatch(uploadFile(file));
    }
  }, [file]);

  useEffect(() => {
    try {
      if (citiesState.type === citiesActionTypes.GET_CITIES_SUCCESS) {
        let options = [];
        options.push({
          value: "SORTING CENTER",
          label: "SORTING CENTER",
        });
        options.push({
          value: "PT SINAR USAHA CEMERLANG",
          label: "PT SINAR USAHA CEMERLANG",
        });
        citiesState.data.map((item, index) => {
          options.push({
            value: item.cityName,
            label: item.cityName,
          });
        });

        let sc = [];
        sc.push({
          value: "SORTING CENTER",
          label: "SORTING CENTER",
        });
        sc.push({
          value: "PT SINAR USAHA CEMERLANG",
          label: "PT SINAR USAHA CEMERLANG",
        });
        citiesState.data.map((item, index) => {
          sc.push({
            value: item.cityName,
            label: item.cityName,
          });
        });

        setLocationOptions(options);
        setSortingCenterOptions(sc);
      }
    } catch (e) {
      console.log(e);
    }
  }, [citiesState]);

  useEffect(() => {
    try {
      if (statusState.type === "GET_STATUSES_SUCCESS") {
        let options = [];
        statusState.statuses.forEach((item, index) => {
          options.push({
            value: item.id,
            label: item.text.id_Id,
          });
        });
        setStatusOptions(options);
      }
    } catch (e) {}
  }, [statusState]);

  useEffect(() => {
    if (
      createTransactionDetailState.type === "CREATE_TRANSACTION_DETAILS_SUCCESS"
    ) {
      dispatch(uploadFileInitialized());
      history.goBack();
    }
  }, [createTransactionDetailState]);

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let attributes = {
      isSubDetail: false,
      subDetail: "",
      extra: "",
      location: location.label,
      isAdditional: isAdditional,
      customDateTime: customDateTime.getTime(),
      sortingCenter: selectedAdditional.value,
      sortingCenterAt: selectedSortingCenter.value,
      image: "",
    };
    if (isSubDetail) {
      attributes = {
        isSubDetail: isSubDetail,
        subDetail: selectedSubDetail,
        extra: other,
        location: location.label,
        isAdditional: isAdditional,
        customDateTime: customDateTime.getTime(),
        sortingCenter: selectedAdditional.value,
        sortingCenterAt: selectedSortingCenter.value,
        image: "",
      };
    }
    if (uploadImageEnable) {
      attributes = {
        ...attributes,
        image: imageId,
      };
    }

    let forms = {
      statusId: selectedStatus.value,
      transactionId: viewTransactionState.transaction.id,
      description: description,
      attributes: attributes,
    };

    console.log(forms);

    dispatch(createTransactionDetails(forms));
  };

  useEffect(() => {
    if (selectedSubDetail !== "Lainnya") setOther("");
  }, [selectedSubDetail]);

  const handleChangeStatus = (selectedOption) => {
    setSelectedStatus(selectedOption);

    if (selectedOption.value === "df3a2224-b276-495d-b3a6-270fd95c9753") {
      setIsSubDetail(true);
      setSubDetail(successDeliver);
      setUploadImageEnable(true);
      setIsAdditional(false);
    } else if (
      selectedOption.value === "102633ff-b561-433a-973f-8931c5576ec1"
    ) {
      setIsSubDetail(false);
      setIsAdditional(true);
      setUploadImageEnable(false);
    } else if (
      selectedOption.value === "1bd5269c-1b32-4df4-a342-01dfafde8c36"
    ) {
      setIsSubDetail(false);
      setIsAdditional(true);
      setUploadImageEnable(false);
    } else if (
      selectedOption.value === "3c567deb-ae3f-4fe1-83de-f209c87993a9"
    ) {
      setIsSubDetail(true);
      setSubDetail(failedDeliver);
      setIsAdditional(false);
      setUploadImageEnable(false);
    } else if (
      selectedOption.value === "255b3384-d648-49f9-9918-0bc44c71f9d5"
    ) {
      setIsSubDetail(true);
      setSubDetail(failedDeliver);
      setIsAdditional(false);
      setUploadImageEnable(false);
    } else {
      setIsSubDetail(false);
      setIsAdditional(false);
      setUploadImageEnable(false);
    }
  };

  const handleChangeLocation = (selectedOption) => {
    setLocation(selectedOption);
  };

  const handleChangeAdditional = (selectedOption) => {
    setSelectedAdditional(selectedOption);
  };

  const handleChangeSortingCenter = (selectedOption) => {
    setSelectedSortingCenter(selectedOption);
  };

  const handleBrowseFileClick = (e) => {
    e.preventDefault();
    inputFile.current.click();
  };

  return (
    <div className="content-wrapper">
      <ContentHeader title={"Create TransactionDetail"} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Location</label>
                      <Select
                        value={location}
                        onChange={handleChangeLocation}
                        options={locationOptions}
                        isClearable={true}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Status</label>
                          <Select
                            value={selectedStatus}
                            onChange={handleChangeStatus}
                            options={statusOptions}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <label htmlFor="exampleInputEmail1">Waktu</label>
                            <div style={{ marginRight: 50 }}></div>
                            {/* <div className="form-group"> */}
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="autoOrManual"
                                value={true}
                                onChange={(e) =>
                                  setAutoOrManual(
                                    e.target.value == "true" && true
                                  )
                                }
                                // onSelect={(e) => console.log(e)}
                                checked={autoOrManual}
                              />
                              <label className="form-check-label">
                                Automatic
                              </label>
                            </div>
                            <div style={{ marginRight: 20 }}></div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="autoOrManual"
                                value={false}
                                onChange={(e) =>
                                  setAutoOrManual(
                                    e.target.value == "false" && false
                                  )
                                }
                              />
                              <label className="form-check-label">Manual</label>
                            </div>
                            {/* </div> */}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <DatePicker
                              className={"form-control"}
                              selected={customDateTime}
                              onChange={(date) => setCustomDateTime(date)}
                              readOnly={autoOrManual}
                            />
                            <div style={{ margin: 5 }}></div>
                            <DatePicker
                              className={"form-control"}
                              selected={customDateTime}
                              onChange={(date) => setCustomDateTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                              readOnly={autoOrManual}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1"></label> <br/>
                          <DatePicker 
                            className={"form-control"}
                            selected={startDate} 
                            onChange={date => setCustomDateTime(date)} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1"></label> <br/>
                          <DatePicker 
                            className={"form-control"}
                            selected={startDate} 
                            onChange={date => setCustomDateTime(date)} 
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                          />
                        </div>
                      </div> */}
                    </div>
                    {isAdditional && (
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="exampleInputEmail1">Dekripsi</label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Select
                              value={selectedAdditional}
                              onChange={handleChangeAdditional}
                              options={additionalOptions}
                              isClearable={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Select
                              value={selectedSortingCenter}
                              onChange={handleChangeSortingCenter}
                              options={sortingCenterOptions}
                              isClearable={true}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Deskripsi Tambahan
                      </label>
                      <input
                        className="form-control"
                        placeholder="Enter Description"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    {isSubDetail && (
                      <div>
                        <div className="form-group">
                          {subDetail.map((item, index) => {
                            return (
                              <div key={index} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="subDetail"
                                  value={item}
                                  onChange={(e) => {
                                    setSelectedSubDetail(e.target.value);
                                  }}
                                />
                                <label className="form-check-label">
                                  {item}
                                </label>
                              </div>
                            );
                          })}
                          {selectedSubDetail === "Lainnya" && (
                            <div
                              className="input-group"
                              style={{ marginBottom: 10 }}
                            >
                              <input
                                className="form-control"
                                value={other}
                                placeholder={`Enter ${selectedSubDetail}`}
                                onChange={(e) => setOther(e.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {uploadImageEnable ? (
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">Upload Foto</label>
                        <div className="image">
                          <img
                            src={`https://storage.suc.co.id/assets/${uploadFileReducerState.data?.data?.id}`}
                            style={{
                              width: 160,
                              height: 160,
                              objectFit: "contain",
                            }}
                            className="elevation-2"
                            alt={"Belum ada gambar"}
                          />
                        </div>
                        <br />
                        <div className="input-group">
                          <button
                            className="btn btn-success"
                            onClick={handleBrowseFileClick}
                          >
                            Browse File
                          </button>
                          <input
                            type="file"
                            ref={inputFile}
                            accept={"image/*"}
                            style={{
                              display: "none",
                            }}
                            onChange={(e) => setFile(e.target.files)}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(CreateTransactionDetail);
