import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, withRouter, Link, useLocation } from 'react-router-dom';
import { getUsers, viewUsers, saveUsers, deleteUser, initializeCreateUsers, initializeUpdateUsers } from '../../redux/actions/user_action';
import ContentHeader from '../../components/content_header';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import qs from 'query-string'
import ReactPaginate from 'react-paginate';
import { initializedFilters, uninitializedFilters } from '../../redux/actions/filters_action';

function User(props) {

  const {
    loading,
    users,
    data,
    type
  } = useSelector(state => state.userReducer);
  const userState = useSelector(state => state.userReducer);
  const user = useSelector(state => state.saveUserReducer.user);
  const userDeleteState = useSelector(state => state.deleteUserReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const location = useLocation();

  // Pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let params = qs.parse(location.search);

    try {
      setPage(params && params.page);
      setPerPage(params && params.perPage);
    } catch (e) {
      console.log(e);
    }

    dispatch(initializeCreateUsers());
    dispatch(initializeUpdateUsers());
    dispatch(uninitializedFilters());
  }, [page, perPage]);

  useEffect(() => {
    dispatch(getUsers(page, perPage));
  }, [])

  useEffect(() => {
    try {
      console.log("totalPage: ", data.data.totalPage);
      console.log("users: ", data);
      console.log("type: ", type);
      console.log("userState: ", userState);
      setTotalPage(data.data.totalPage);
    } catch (e) {
      console.log(e)
    }
  }, [users, data, type, userState]);

  useEffect(() => {
    try {
      if (userDeleteState.type === 'DELETE_USERS_SUCCESS') {
        dispatch(getUsers(1, 15));
      }
    } catch (e) {
      console.log(e);
    } 
  }, [userDeleteState]);

  const viewUser = (event, c) => {
    // event.preventDefault();
    dispatch(saveUsers(c));
    history.push('users-view');
  }

  const editUser = (event, c) => {
    dispatch(saveUsers(c));
    history.push('users-edit');
  }

  const onDeleteUser = (event, c) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'This data will be deleted',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33', 
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        dispatch(deleteUser(c.id))
      }
    })
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
    dispatch(getUsers(selectedPage, perPage));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'User'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <Link
                    type={'button'}
                    className={'btn btn-sm btn-success card-title'}
                    to={'/home/users-create'}>
                    Create User
                  </Link>
                </div>

                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Fullname</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ? 
                        <tr>
                          <td>Loading</td>
                        </tr>
                        :
                        users && users.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index+1}</td>
                              <td>{item.fullName}</td>
                              <td>{item.username}</td>
                              <td>{item.email}</td>
                              <td>{item.role.name}</td>
                              <td>
                                <div className="btn-group">
                                  <button type="button" className="btn btn-primary" onClick={(event) => viewUser(event, item)}>View</button>
                                  <button type="button" className="btn btn-warning" onClick={(event) => editUser(event, item)}>Edit</button>
                                  {
                                    item.role.slug !== "superadmin" 
                                      && <button type="button" className="btn btn-danger" onClick={(event) => onDeleteUser(event, item)}>Delete</button> 
                                  }
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
                {/* /.card-body */}

                <div className="card-footer clearfix">
                  <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabe={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pagination-sm m-0 float-right"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}

export default withRouter(User)
