import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, withRouter, Link, useLocation } from 'react-router-dom';
import ContentHeader from '../../components/content_header';
import ReportTransaction from './report_transaction';
import ReportUser from './report_user';

function Report(props) {


    const [activePage, setActivePage] = useState(<ReportTransaction/>);

    const reportMenuTypes = {
        TRANSACTION: 'TRANSACTION',
        USER: 'USER'
    }

    const handleReportMenu = (event, menu) => {
        event.preventDefault();

        switch (menu) {
            case reportMenuTypes.TRANSACTION:
                setActivePage(<ReportTransaction/>);
                return;
            case reportMenuTypes.USER:
                setActivePage(<ReportUser/>);
                return;
            default:
                setActivePage(<ReportTransaction/>);
                return;
        }
    }

    return(
        <div className="content-wrapper">
            <ContentHeader title={"Report"} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Report</h3>
                                    
                                </div>
                                <div className="card-body p-0">
                                    <ul className="nav nav-pills flex-column">
                                        <li className="nav-item active">
                                            <a className="nav-link" onClick={(event) => handleReportMenu(event, reportMenuTypes.TRANSACTION)}>
                                            <i className="fas fa-inbox" /> Transaction
                                            {/* <span className="badge bg-primary float-right">12</span> */}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>

                        <div className="col-md-9">
                            {activePage}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default withRouter(Report)