import React, { useEffect } from 'react';
import { login, loginTypes } from '../../redux/actions/auth_action';
import { ToastContainer } from 'react-toastify';
import { 
  useDispatch, useSelector, 
  // useSelector 
} from 'react-redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { initializedFilters, uninitializedFilters } from '../../redux/actions/filters_action';

function Login(props) {
  
  const loginState = useSelector(state => state.authReducer);
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const MySwal = withReactContent(Swal);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(login(username, password, props));
  }

  useEffect(() => {
    dispatch(uninitializedFilters());
  }, [])

  useEffect(() => {
    try {
      console.log("loginState", loginState);
      if (loginState.type === loginTypes.AUTH_LOGIN) {
        if (loginState.isLoading) {
          MySwal.fire({
              title: 'Please Wait !',
              allowOutsideClick: false,
              onBeforeOpen: () => {
                MySwal.showLoading()
              },
          });
        }
      } else {
        MySwal.close();
      }
    } catch (e) {
      console.log(e);
    }
  }, [loginState])

  return(
    <div className="login-page" style={{
      backgroundImage: `url('/img/background.jpg')`
    }}>
      <div
        style={{
          position: 'absolute',
          top: 20,
          right: 20
        }}>
          <img
            src="/img/suc_logo.png"
            style={{
              width: 200
            }}
          />
      </div>
      <div className="login-box">
        <div className="login-logo">
          <a href="/"><b>PT </b>Sinar Usaha Cemerlang</a>
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>

            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input className="form-control" placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)}/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fa fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)}/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fa fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  )
}

export default Login;
// export default withRouter(Login);