import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { viewUsers } from '../../redux/actions/user_action';
import ContentHeader from '../../components/content_header';
import Api from '../../api/api';
import { uploadFilesInitialized } from '../../redux/actions/files_action';

function ViewUser() {

  const user = useSelector(state => state.viewUserReducer.user);
  const viewUserState = useSelector(state => state.viewUserReducer);
  const dispatch = useDispatch();
  const api = new Api();

  useEffect(() => {
    dispatch(viewUsers());
    dispatch(uploadFilesInitialized());
  }, [viewUsers])

  useEffect(() => {
    console.log(viewUserState);
  }, [user, viewUserState])

  return (
    <div className="content-wrapper">
      <ContentHeader title={'View User'} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Foto Profile</label>
                    <div className="image">
                      <img 
                        src={`${api.baseURL}/${user?.files?.filePath}`} 
                        style={{
                          width: 160,
                          height: 160,
                          borderRadius: '100%',
                          objectFit: 'cover'
                        }}
                        className="elevation-2"
                        alt={"User Profile"}
                        />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Full Name</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={user && user.fullName}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Username</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={user && user.username}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={user && user.email}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                    <input 
                      className="form-control" 
                      readOnly
                      value={user && user.phoneNumber}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Role</label>
                    <input 
                      className="form-control"
                      readOnly
                      value={user && user.role.name}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(ViewUser)
