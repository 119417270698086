let initialState = {
  loading: true,
  data: {},
  type: 'GET_CLIENTS',
  error: {},
  clients: []
};

let initialCreateState = {
  loading: true,
  data: {},
  type: 'CREATE_CLIENTS',
  error: {},
};

let initialUpdateState = {
  loading: true,
  data: {},
  type: 'UPDATE_CLIENTS',
  error: {},
};

let initialDeleteState = {
  loading: true,
  data: {},
  type: 'DELETE_CLIENTS',
  error: {},
};

let viewClientReducerState = {
  type: 'VIEW_CLIENT',
  client: null
};

let saveClientReducerState = {
  type: 'SAVE_CLIENT',
  client: null
};

export const saveClientReducer = (state = saveClientReducerState, action) => {
  switch (action.type) {
    case 'SAVE_CLIENT':
      return {
        type: action.type,
        client: action.client
      }
    default:
      return state;
  }
}

export const viewClientReducer = (state = viewClientReducerState, action) => {
  switch (action.type) {
    case 'VIEW_CLIENT':
      let client = action.client;
      return {
        type: action.type,
        client,
      }
    default:
      return state;
  }
}

export const updateClientReducer = (state = initialUpdateState, action) => {
  switch (action.type) {
    case 'UPDATE_CLIENTS':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'UPDATE_CLIENTS_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'UPDATE_CLIENTS_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const deleteClientReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case 'DELETE_CLIENTS':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'DELETE_CLIENTS_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'DELETE_CLIENTS_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const createClientReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case 'CREATE_CLIENTS':
      return {
        ...state,
        type: action.type,
        loading: true
      }
    case 'CREATE_CLIENTS_SUCCESS':
      return {
        ...state,
        type: action.type,
        loading: false,
        data: action.data
      }
    case 'CREATE_CLIENTS_ERROR':
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.error,
        data: action.data,
      }
    default:
      return state;
  }
}

export const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENTS':
      return {
        type: action.type,
        loading: true,
      };
    case 'GET_CLIENTS_SUCCESS':
      return {
        type: action.type,
        loading: false, 
        data: action.data,
        clients: action.clients
      };
    case 'GET_CLIENTS_ERROR':
      return {
        type: action.type,
        loading: false, 
        error: action.error
      };
    default:
      return state;
  }
};