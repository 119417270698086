import React, { Component, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/auth_action';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  withRouter,
  useRouteMatch
} from "react-router-dom" ;
import Header from './header';
import SideBar from './sidebar';
import Client from '../client/client';
import CreateClient from '../client/create_client';
import ViewClient from '../client/view_client';
import EditClient from '../client/edit_client';
import Consignee from '../consignee/consignee';
import CreateConsignee from '../consignee/create_consignee';
import ViewConsignee from '../consignee/view_consignee';
import EditConsignee from '../consignee/edit_consignee';
import PrivateRoute from '../../router/private_route';
import ContentHeader from '../../components/content_header';
import { ToastContainer } from 'react-toastify';
import Rate from '../rate/rate';
import CreateRate from '../rate/create_rate';
import ViewRate from '../rate/view_rate';
import EditRate from '../rate/edit_rate';
import User from '../user/user';
import ViewUser from '../user/view_user';
import CreateUser from '../user/create_user';
import EditUser from '../user/edit_user';
import Role from '../role/role';
import ViewRole from '../role/view_role';
import CreateRole from '../role/create_role';
import EditRole from '../role/edit_role';
import Transaction from '../transaction/transaction';
import CreateTransaction from '../transaction/create_transaction';
import ViewTransaction from '../transaction/view_transaction';
import EditTransaction from '../transaction/edit_transaction';
import PrintReceiptTransaction from '../transaction/print_receipt_transaction';
import CreateTransactionDetail from '../transaction_detail/create_transaction_detail';
import EditTransactionDetail from '../transaction_detail/edit_transaction_detail';
import Report from '../report/report';
import Dashboard from '../dashboard/dashboard';
import { initializedFilters, uninitializedFilters } from '../../redux/actions/filters_action';
import Province from '../province/province';
import CreateProvince from '../province/create_province';
import EditProvince from '../province/edit_province';
import ViewProvince from '../province/view_province';
import City from '../city/city';
import CreateCity from '../city/create_city';
import EditCity from '../city/edit_city';
import ViewCity from '../city/view_city';

function Homes(props) {

  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(path);
    dispatch(uninitializedFilters());
  }, [])

  return(
    <div className="hold-transition sidebar-mini layout-fixed">
      <div className="wrapper">
        <Header/>
        <SideBar/>
        {/* <Content/> */}

        <Fragment>
          <PrivateRoute 
            exact={true}
            path='/'>
            <Dashboard/>
          </PrivateRoute>
          <PrivateRoute 
            exact={true}
            path='/home/dashboard'>
            <Dashboard/>
          </PrivateRoute>
          <PrivateRoute 
            path={`/home/clients`} >
            <Client/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/clients-create`}>
            <CreateClient/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/clients-view`}>
            <ViewClient/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/clients-edit`}>
            <EditClient/>
          </PrivateRoute>
          <PrivateRoute 
            path={`/home/consignees`} >
            <Consignee/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/consignees-create`}>
            <CreateConsignee/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/consignees-view`}>
            <ViewConsignee/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/consignees-edit`}>
            <EditConsignee/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/rates`}>
            <Rate/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/rates-create`}>
            <CreateRate/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/rates-view`}>
            <ViewRate/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/rates-edit`}>
            <EditRate/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/users`}>
            <User/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/users-view`}>
            <ViewUser/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/users-create`}>
            <CreateUser/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/users-edit`}>
            <EditUser/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/roles`}>
            <Role/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/roles-view`}>
            <ViewRole/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/roles-create`}>
            <CreateRole/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/roles-edit`}>
            <EditRole/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/transactions`}>
            <Transaction/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/transactions-create`}>
            <CreateTransaction/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/transactions-view`}>
            <ViewTransaction/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/transactions-edit`}>
            <EditTransaction/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/transactions-print-receipt`}>
            <PrintReceiptTransaction/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/transaction-details-create`}>
            <CreateTransactionDetail/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/transaction-details-edit`}>
            <EditTransactionDetail/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/reports`}>
            <Report/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/provinces`}>
            <Province/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/provinces-edit`}>
            <EditProvince/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/provinces-view`}>
            <ViewProvince/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/cities`}>
            <City/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/cities-create`}>
            <CreateCity/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/cities-edit`}>
            <EditCity/>
          </PrivateRoute>
          <PrivateRoute
            exact={true}
            path={`/home/cities-view`}>
            <ViewCity/>
          </PrivateRoute>
        </Fragment>

        <ToastContainer />
      </div>
    </div>
  )

}

export default withRouter(Homes);