import React, { useState, useEffect } from 'react';
import ContentHeader from '../../components/content_header';
import { useHistory, withRouter, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from '../../redux/actions/dashboard_action';
import { thousandSeparator } from '../../helper/thousand_separator';
import moment from 'moment';
import { initializedFilters, uninitializedFilters } from '../../redux/actions/filters_action';

function Dashboard() {

    const dashboardState = useSelector(state => state.dashboardReducer);
    const dispatch = useDispatch();

    const [transactions, setTransactions] = useState({
        grandTotal: 0,
        grandCount: 0
    });
    const [transactionsDays, setTransactionsDays] = useState(0);
    const [transactionsWeeks, setTransactionsWeeks] = useState(0);
    const [transactionsMonths, setTransactionsMonths] = useState(0);
    const [totalTrx, setTotalTrx] = useState({
        totalTrxDays: 0,
        totalTrxWeeks: 0,
        totalTrxMonths: 0
    });
    const [users, setUsers] = useState(0);
    const [clients, setClients] = useState(0);
    const [rates, setRates] = useState(0);

    useEffect(() => {
        dispatch(getDashboard());
        dispatch(uninitializedFilters());
        // let nowDate = new Date();
        // let dateOnly = moment(nowDate).format("YYYY-MM-DD");
        // let parseStartDate = dateOnly.split("-");
        // let newDayStartDate = new Date(parseInt(parseStartDate[0]), parseInt(parseStartDate[1]) - 1, parseInt(parseStartDate[2]));
        // let newDayEndDate = new Date(newDayStartDate.getTime() + 86399000);
        // let test = new Date(parseInt(parseStartDate[0]), parseInt(parseStartDate[1]), 0);
        // let tests = (test.getTime() + 86399000) - (test.getDate() * 86400000);

        // console.log(`tests: ${tests} test: ${test.getTime() + 86399000} test: ${test.getDate()} nowDate: ${nowDate} dateOnly: ${dateOnly} parseStartDate: ${parseStartDate} newDayStartDate: ${newDayStartDate} ${newDayEndDate}`);
    }, [getDashboard])

    useEffect(() => {
        console.log(dashboardState);
        try {
            setTransactions({
                grandCount: dashboardState.data.data.transactions.grandCount,
                grandTotal: dashboardState.data.data.transactions.grandTotal
            });
            setUsers(dashboardState.data.data.users);
            setClients(dashboardState.data.data.clients);
            setRates(dashboardState.data.data.rates);
            setTransactionsDays(dashboardState.data.data.transactions_per_days);
            setTransactionsWeeks(dashboardState.data.data.transactions_per_weeks);
            setTransactionsMonths(dashboardState.data.data.transactions_per_months);

            setTotalTrx({
                totalTrxDays: dashboardState.data.data.total_transactions_per_days,
                totalTrxWeeks: dashboardState.data.data.total_transactions_per_weeks,
                totalTrxMonths: dashboardState.data.data.total_transactions_per_months
            });
        } catch (e) {
            console.log(e);
        }
    }, [dashboardState])

    return(
        <div className="content-wrapper">
            <ContentHeader title="Home" />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{thousandSeparator(transactionsDays)}</h3>
                                    <p>Transaksi Hari Ini</p>
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 35,
                                        left: 10
                                    }}>
                                        <b>Rp {thousandSeparator(totalTrx.totalTrxDays)}</b>
                                    </div>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <Link type="a" className="small-box-footer" to={`/home/transactions`}>
                                More info <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{thousandSeparator(transactionsWeeks)}</h3>
                                    <p>Transaksi Minggu Ini</p>
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 35,
                                        left: 10
                                    }}>
                                        <b>Rp {thousandSeparator(totalTrx.totalTrxWeeks)}</b>
                                    </div>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <Link type="a" className="small-box-footer" to={`/home/transactions`}>
                                More info <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{thousandSeparator(transactionsMonths)}</h3>
                                    <p>Transaksi Bulan Ini</p>
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 35,
                                        left: 10
                                    }}>
                                        <b>Rp {thousandSeparator(totalTrx.totalTrxMonths)}</b>
                                    </div>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <Link type="a" className="small-box-footer" to={`/home/transactions`}>
                                More info <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{thousandSeparator(transactions.grandCount)}</h3>
                                    <p>Total Transaksi</p>
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 35,
                                        left: 10
                                    }}>
                                        <b>Rp {thousandSeparator(transactions.grandTotal)}</b>
                                    </div>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <Link type="a" className="small-box-footer" to={`/home/transactions`}>
                                More info <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>{thousandSeparator(rates)}</h3>
                                    <p>Jangkauan</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-stats-bars" />
                                </div>
                                <Link type="a" className="small-box-footer" to={`/home/rates`}>
                                More info <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{thousandSeparator(clients)}</h3>
                                    <p>Client</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add" />
                                </div>
                                <Link type="a" className="small-box-footer" to={`/home/clients`}>
                                More info <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-6">
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3>{thousandSeparator(clients)}</h3>
                                    <p>User</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph" />
                                </div>
                                <Link type="a" className="small-box-footer" to={`/home/users`}>
                                More info <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default withRouter(Dashboard);