import Api from '../../api/api';
import { toast } from 'react-toastify';

export function saveRates(rate) {
  return dispatch => {
    // delete rate in localStorage
    localStorage.removeItem('rate');

    // add rate to localStorage
    localStorage.setItem('rate', JSON.stringify(rate));
    dispatch({
      type: "SAVE_RATE",
      rate: rate
    });
    return;
  }
}

export function viewRates() {
  return dispatch => {
    // get rate in localStorage
    var rate = JSON.parse(localStorage.getItem('rate'));
    dispatch({
      type: "VIEW_RATE",
      rate
    }); 
    return;
  }
}

export function initializeCreateRates() {
  return dispatch => {
    dispatch({
      type: "CREATE_RATES"
    });
  }
}

export function initializeUpdateRates() {
  return dispatch => {
    dispatch({
      type: "UPDATE_RATES"
    });
  }
}

export function createRates(forms) {
  return dispatch => {
    dispatch({
      type: "CREATE_RATES"
    });

    var api = new Api();
    api.createRates(forms)
      .then(response => {
        console.log(response.data)
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: "CREATE_RATES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "CREATE_RATES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length || item.constraints.isInt)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "CREATE_RATES_ERROR",
            error
        });
        return;
      });
  }
}

export function updateRates(forms, rateId) {
  return dispatch => {
    dispatch({
      type: "UPDATE_RATES"
    });

    var api = new Api();
    api.updateRates(forms, rateId)
      .then(response => {
        let data = response.data;
        if (response.status === 201) {
          dispatch({
            type: "UPDATE_RATES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "UPDATE_RATES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length || item.constraints.isInt)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "UPDATE_RATES_ERROR",
            error
        });
        return;
      });
  }
}

export function deleteRate(rateId) {
  return dispatch => {
    dispatch({
      type: "DELETE_RATES"
    });

    var api = new Api();
    api.deleteRates(rateId)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          dispatch({
            type: "DELETE_RATES_SUCCESS",
            data,
          });

          toast.success(data.message);

          return;
        } else {
          dispatch({
            type: "DELETE_RATES_ERROR",
            data,
          });

          if (response.status === 400) {
            response.data.data.map(item => {
              toast.error(item.constraints.isNotEmpty || item.constraints.length)
            })
          }
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "DELETE_RATES_ERROR",
            error
        });
        return;
      });
  }
}

export function getRates(page, perPage, filters) {
  return dispatch => {
    dispatch({
      type: "GET_RATES"
    });

    var api = new Api();
    api.getRates(page, perPage, filters)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "GET_RATES_SUCCESS",
            data: response.data,
            rates: response.data.data.data
          });
          return;
        } else {
          dispatch({
            type: "GET_RATES_ERROR",
            data: response.data
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "GET_RATES_ERROR",
            error
        });
        return;
      })
  }
}

export function checkRates(forms) {
  return dispatch => {
    dispatch({
      type: "CHECK_RATES",
      loading: true,
    });

    var api = new Api();
    api.checkRates(forms)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "CHECK_RATES_SUCCESS",
            data: response.data,
            loading: false,
          });
          return;
        } else {
          dispatch({
            type: "CHECK_RATES_ERROR",
            data: response.data,
            loading: false,
          });
          return;
        }
      })
      .catch(error => {
        dispatch({
            type: "CHECK_RATES_ERROR",
            loading: false,
            error,
        });
        return;
      })
  }
}