const { dashboardTypes } = require("../actions/dashboard_action");

let initialState = {
    loading: false,
    error: {},
    data: {},
    type: dashboardTypes.GET_DASHBOARD
};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case dashboardTypes.GET_DASHBOARD:
            return {
                type: action.type,
                loading: action.loading
            }
        case dashboardTypes.GET_DASHBOARD_SUCCESS:
            return {
                type: action.type,
                loading: action.loading,
                data: action.data
            }
        case dashboardTypes.GET_DASHBOARD_ERROR:
            return {
                type: action.type,
                loading: action.loading,
                error: action.error
            }
        default:
            return state;
    }
}