import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, withRouter} from 'react-router-dom';
import ContentHeader from '../../components/content_header';
import { getProvinces } from '../../redux/actions/province_action';
import Select from 'react-select';
import { consigneeTypes, updateConsignees } from '../../redux/actions/consignee_action';
import { viewConsignees } from '../../redux/actions/consignee_action';

function EditConsignee(props) {

  const viewConsigneeState = useSelector(state => state.viewConsigneeReducer);
  const provinceState = useSelector(state => state.provinceReducer);
  const updateConsigneeState = useSelector(state => state.updateConsigneeReducer);

  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedCityOption, setSelectedCityOption] = useState(0);
  const [options, setOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  // State form
  const [companyName, setCompanyName] = useState("");
  const [picName, setPicName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    dispatch(getProvinces(""));
    dispatch(viewConsignees());
  }, [getProvinces, viewConsignees]);

  useEffect(() => {
    if (updateConsigneeState.type === consigneeTypes.UPDATE_CONSIGNEES_SUCCESS) {
      history.goBack();
    }
  }, [updateConsigneeState]);

  useEffect(() => {
    if (provinceState.type === 'GET_PROVINCES_SUCCESS') {
      let options = [];
      provinceState && provinceState.data.map((item, index) => {
        options.push({
          value: item.id,
          label: item.provinceName
        })
      });
      setOptions(options);
    }
  }, [provinceState]);

  useEffect(() => {
    if (viewConsigneeState) {
      try {
        setCompanyName(viewConsigneeState.consignee.companyName);
        setPicName(viewConsigneeState.consignee.picName);
        setEmail(viewConsigneeState.consignee.email);
        setPhoneNumber(viewConsigneeState.consignee.phoneNumber);
        setAddress(viewConsigneeState.consignee.address);

        // set selected province
        let p = {
          value: viewConsigneeState.consignee.province.id,
          label: viewConsigneeState.consignee.province.provinceName,
        }
        setSelectedOption(p);
        

        setTimeout(() => {
          // get city by province
          getCity(p);

          // set selected city
          let c = {
            value: viewConsigneeState.consignee.city.id,
            label: viewConsigneeState.consignee.city.cityName,
          }
          setSelectedCityOption(c);
        }, 500);
      } catch (e) {
        console.log(e);
      }
    }
  }, [viewConsigneeState])

  const getCity = (selectedOption) => {
    try {
      var index = provinceState.data.findIndex(x => x.id === selectedOption.value);
      var cities = provinceState.data[index].cities;
      let cOptions = [];
      cities.map((item, index) => {
        cOptions.push({
          value: item.id,
          label: item.cityName
        });
      });
      setCitiesOptions(cOptions);
    } catch (e) {
      console.log(e);
    }
  }

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    getCity(selectedOption);
  };

  const handleChangeCities = selectedOption => {
    setSelectedCityOption(selectedOption);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    let forms = {
      companyName: companyName,
      picName: picName,
      email: email,
      address: address,
      phoneNumber: phoneNumber,
      provinceId: selectedOption.value,
      cityId: selectedCityOption.value
    }

    dispatch(updateConsignees(forms, viewConsigneeState.consignee.id));
  }

  return (
    <div className="content-wrapper">
      <ContentHeader title={'Edit Consignee'}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={handleOnSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Company Name</label>
                      <input 
                        className="form-control"
                        placeholder="Enter Company Name" 
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">PIC (Person In Charge) Name</label>
                      <input 
                        className="form-control" 
                        value={picName}
                        placeholder="Enter PIC (Person In Charge) Name" 
                        onChange={(e) => setPicName(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <input 
                        className="form-control" 
                        value={email}
                        placeholder="Enter Email" 
                        onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Phone Number</label>
                      <input 
                        className="form-control" 
                        value={phoneNumber}
                        placeholder="Enter Phone Number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Address</label>
                      <input 
                        className="form-control" 
                        value={address}
                        placeholder="Enter Address" 
                        onChange={(e) => setAddress(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Province</label>
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                      />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">City</label>
                        <Select
                          value={selectedCityOption}
                          onChange={handleChangeCities}
                          options={citiesOptions}
                        />  
                      </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(EditConsignee);